<template>
  <!-- 13 電源 -->
  <div>
    <div class="table-area">
      <h3>ユニット型電源</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-RWS50B</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-RWS50B.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="4">
            <a
              href="../description/FL-RWS-50B-600B_manual.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-RWS100B</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-RWS100B.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-RWS150B</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-RWS150B.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-RWS300B</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>防水タイプ電源</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-HLG120H</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-HLG150H</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-HLG150H.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-HLG240H</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-HLG240H.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-HLG320H</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-HLG320H.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>アダプター電源</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-AKA-12020</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AKA-12020.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-AKA-12030</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AKA-12030.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-AKA-12050</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AKA-12050.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-AKA-24016</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AKA-24016.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-AKA-24271</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AKA-24271.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
