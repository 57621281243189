<template>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <!-- <div style="background: #00000004;"> -->
  <div>
    <!-- <router-view name="header"></router-view> -->
    <!-- <Header></Header> -->
    <router-view name="header"></router-view>
    <div style="position:relative">
      <transition name="fade" mode="out-in" @before-enter="beforeEnter">
        <router-view></router-view>
      </transition>
      <img
        @click="scrollTop"
        class="scroll-top"
        :class="`phase-${scrollPhaseNum}`"
        src="./assets/img/mk-12.svg"
        alt=""
      />
      <div ref="footer"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// import Home from './views/Home.vue';
// import Header from './views/Header.vue';
import Footer from "./views/Footer.vue";

export default {
  data: () => {
    return {
      scrollPhaseNum: 1,
    };
  },
  components: {
    // Home
    // Header,
    Footer,
  },
  methods: {
    beforeEnter() {
      this.$root.$emit("triggerScroll");
    },
    handleScroll() {
      const scrollY = window.scrollY;
      if (scrollY < window.innerHeight * 0.4) {
        this.scrollPhaseNum = 1;
      } else if (
        this.$refs.footer.getBoundingClientRect().top < window.innerHeight
      ) {
        this.scrollPhaseNum = 3;
      } else {
        this.scrollPhaseNum = 2;
      }
    },
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted: function() {
    this.$store.state.count = true;
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/sass/global.scss";

.fade-enter,
.fade-leave-to {
  opacity: 0.1;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.2s;
}
.scroll-top {
  position: fixed;
  opacity: 0.7;
  width: calc(20px + 2vw);
  height: calc(20px + 2vw);
  bottom: calc(20px + 2vw);
  right: calc(20px + 2vw);
  @include md {
    width: calc(32px + 3.2vw);
    height: calc(32px + 3.2vw);
    bottom: calc(20px + 2vw);
    right: calc(20px + 2vw);
  }
  cursor: pointer;
  transition: 0.2s;
  &.phase-1 {
    opacity: 0;
  }
  &.phase-3 {
    filter: invert(100%);
  }
  &:hover {
    opacity: 0.4;
  }
}
</style>
