import { render, staticRenderFns } from "./ContactFinish.vue?vue&type=template&id=0cd23692&scoped=true"
import script from "./ContactFinish.vue?vue&type=script&lang=js"
export * from "./ContactFinish.vue?vue&type=script&lang=js"
import style0 from "./ContactFinish.vue?vue&type=style&index=0&id=0cd23692&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd23692",
  null
  
)

export default component.exports