<template>
    <!-- <Header /> -->
    <Header :class="{active: isChangeBg, hide: isHide, isVisible:isVisible}"
        v-observe-visibility="visibilityChanged"
    />
</template>

<script>
import Header from '../views/Header.vue';

export default {
  components: {
        Header
    },
    created() {
        if(window.innerWidth < 899) {
            this.threshold = window.innerHeight * 0.12;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    data() {
        return {
            scrollY: 0,
            threshold: window.innerHeight,
            posBf: 0,
            posAf: 0,
            isHide: false,
            isVisible: false
        }
    },
    computed:{
        isChangeBg(){
        if(this.scrollY > this.threshold) {
            return true
        } else {
            return false
        }
        }
    },
    methods: {
        onScroll () {
            this.posAf = this.scrollY = window.pageYOffset;
            if( this.posAf < this.posBf){
                this.isHide = false;
            }else if(this.scrollY > window.innerHeight * 0.16){
                this.isHide = true;
            }else {
                this.isHide = false;
            }
            this.posBf = this.posAf;
        },
        visibilityChanged(isVisible, entry) {
            if(isVisible == true) {
                this.isVisible = isVisible;
            }
            console.log(entry);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

@include lg {
    header /deep/ {
        opacity: 0;
        // top: 8px;
        nav {
            background: unset;
            box-shadow: unset;
            transition: 0.2s;
            .link-list {
                .link {
                    color: white;
                    transition: 0.2s;
                    &:hover {
                        color: $color-green;
                        filter: brightness(1.4) saturate(1.05);
                        transition: 0.2s;
                    }
                }
                .link--active {
                    color: $color-green;
                }
            }
            .form-link {
                &:hover {
                    filter: brightness(1.4) saturate(1);
                }
            }
        }
    }

    header.active /deep/ {
        nav {
            background: #f2f2f2e7;
            transition: 0.2s;
            .link-list .link {
                color: black;
                transition: 0s;
                &:hover {
                    color: $color-green;
                    filter: brightness(1.15) saturate(1);
                    transition: 0.2s;
                }
            }
            .form-link {
                &:hover {
                    filter: brightness(1.15) saturate(1);
                }
            }
        }
    }

    header.isVisible /deep/ {
        opacity: 1;
        top: 0;
        transition: all 0.2s, opacity 1.2s 1.6s, top 1.2s 1.6s;
    }
}

@include md {
    header /deep/ {
        .header-md-bg {
            // background: #ffffffbb;
            background: unset;
            // width: 166px; height: 48px;
            transition: 0.2s;
        }
        .hamburger_btn {
            background: unset;
            transition: 0.2s;
        }
        .logo {
            margin-top: 10px;
            margin-left: 10px;
            width: 100px;
            transition: 0.2s;
        }
    }
    header.active /deep/ {
        .header-md-bg {
            background: #f2f2f2f2;
            width: 100vw; height: 64px;
            transition: all 0.2s, width 0s 0.2s;
        }
        .hamburger_btn {
            background: $color-green;
            transition: all 0.2s, background 0s 0.2s, transform 0.2s;
        }
        .logo {
            margin-top: 0px;
            margin-left: 0px;
            width: 100px;
            transition: 0s 0.2s;
        }
    }
}

</style>
