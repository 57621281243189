<template>
  <!-- 15 アルミバー -->
  <div>
    <div class="table-area">
      <h3>5050タイプ専用アルミバー</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-AC32</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AC32.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>3528タイプ専用アルミバー</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-AC38</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AC38.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>角設置専用アルミバー</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-AC037</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AC037.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>ドットレス専用アルミバー</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-AC-1715</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AC-1715.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
