<template>
    <div class="spec-table">
        <div class="table-area">
            <h3>5050タイプ 60チップ版 【12V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>色温度</th>
                    <th>発光色</th>
                    <th>全光束</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN5050WW25</td>
                    <td>2500K</td>
                    <td>電球色</td>
                    <td>950lm</td>
                    <td rowspan="6">60pcs</td>
                    <td rowspan="6">DC12V</td>
                    <td rowspan="6">1.2A</td>
                    <td rowspan="6">14.4W</td>
                    <td rowspan="6">IP68準拠</td>
                </tr>
                <tr>
                    <td>YN5050WW28</td>
                    <td>2800K</td>
                    <td>電球色</td>
                    <td>1050lm</td>
                </tr>
                <tr>
                    <td>YN5050WW30</td>
                    <td>3000K</td>
                    <td>電球色</td>
                    <td>1050lm</td>
                </tr>
                <tr>
                    <td>YN5050NW40</td>
                    <td>4000K</td>
                    <td>温白色</td>
                    <td>1080lm</td>
                </tr>
                <tr>
                    <td>YN5050CW60</td>
                    <td>6000K</td>
                    <td>昼白色</td>
                    <td>1080lm</td>
                </tr>
                <tr>
                    <td>YN5050CW75</td>
                    <td>7500K</td>
                    <td>昼白色</td>
                    <td>1080lm</td>
                </tr>

            </table>
        </div>

        <div class="table-area">
            <h3>5050タイプ 60チップ版 【24V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>発光色</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN5050WW-60-24</td>
                    <td>電球色</td>
                    <td rowspan="2">60pcs</td>
                    <td rowspan="2">DC24V</td>
                    <td rowspan="2">0.6A</td>
                    <td rowspan="2">14.4W</td>
                    <td rowspan="2">IP68準拠</td>
                </tr>
                <tr>
                    <td>YN5050CW-60-24</td>
                    <td>昼白色</td>
                </tr>

            </table>
        </div>

        <div class="table-area">
            <h3>3528タイプ 60チップ版 【12V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>色温度</th>
                    <th>発光色</th>
                    <th>全光束</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN3528WW25</td>
                    <td>2500K</td>
                    <td>電球色</td>
                    <td>300lm</td>
                    <td rowspan="6">60pcs</td>
                    <td rowspan="6">DC12V</td>
                    <td rowspan="6">0.4A</td>
                    <td rowspan="6">4.8W</td>
                    <td rowspan="6">IP68準拠</td>
                </tr>
                <tr>
                    <td>YN3528WW28</td>
                    <td>2800K</td>
                    <td>電球色</td>
                    <td>320lm</td>
                </tr>
                <tr>
                    <td>YN3528WW30</td>
                    <td>3000K</td>
                    <td>電球色</td>
                    <td>320lm</td>
                </tr>
                <tr>
                    <td>YN3528NW40</td>
                    <td>4000K</td>
                    <td>温白色</td>
                    <td>340lm</td>
                </tr>
                <tr>
                    <td>YN3528CW60</td>
                    <td>6000K</td>
                    <td>昼白色</td>
                    <td>340lm</td>
                </tr>
                <tr>
                    <td>YN3528CW75</td>
                    <td>7500K</td>
                    <td>昼白色</td>
                    <td>340lm</td>
                </tr>

            </table>
        </div>

        <div class="table-area">
            <h3>3528タイプ 60チップ版 【24V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>発光色</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN3528WW-60-24</td>
                    <td>電球色</td>
                    <td rowspan="2">60pcs</td>
                    <td rowspan="2">DC24V</td>
                    <td rowspan="2">0.2A</td>
                    <td rowspan="2">4.8W</td>
                    <td rowspan="2">IP68準拠</td>
                </tr>
                <tr>
                    <td>YN3528CW-60-24</td>
                    <td>昼白色</td>
                </tr>

            </table>
        </div>

        <div class="table-area">
            <h3>3528タイプ 120チップ版 【12V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>色温度</th>
                    <th>発光色</th>
                    <th>全光束</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN3528WW25120</td>
                    <td>2500K</td>
                    <td>電球色</td>
                    <td>600lm</td>
                    <td rowspan="6">120pcs</td>
                    <td rowspan="6">DC12V</td>
                    <td rowspan="6">0.8A</td>
                    <td rowspan="6">9.6W</td>
                    <td rowspan="6">IP68準拠</td>
                </tr>
                <tr>
                    <td>YN3528WW28120</td>
                    <td>2800K</td>
                    <td>電球色</td>
                    <td>640lm</td>
                </tr>
                <tr>
                    <td>YN3528WW30120</td>
                    <td>3000K</td>
                    <td>電球色</td>
                    <td>640lm</td>
                </tr>
                <tr>
                    <td>YN3528NW40120</td>
                    <td>4000K</td>
                    <td>温白色</td>
                    <td>680lm</td>
                </tr>
                <tr>
                    <td>YN3528CW60120</td>
                    <td>6000K</td>
                    <td>昼白色</td>
                    <td>680lm</td>
                </tr>
                <tr>
                    <td>YN3528CW75120</td>
                    <td>7500K</td>
                    <td>昼白色</td>
                    <td>680lm</td>
                </tr>

            </table>
        </div>

        <div class="table-area">
            <h3>3528タイプ 120チップ版 【24V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>発光色</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN3528WW-120-24</td>
                    <td>電球色</td>
                    <td rowspan="2">120pcs</td>
                    <td rowspan="2">DC24V</td>
                    <td rowspan="2">0.4A</td>
                    <td rowspan="2">9.6W</td>
                    <td rowspan="2">IP68準拠</td>
                </tr>
                <tr>
                    <td>YN3528CW-120-24</td>
                    <td>昼白色</td>
                </tr>

            </table>
        </div>
    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
    &:nth-of-type(1),
    &:nth-of-type(3),
    &:nth-of-type(5) {
        th {
            &:nth-of-type(1) {
                width: 24%;
            }
            &:nth-of-type(2) {
                width: 8%;
            }
            &:nth-of-type(3) {
                width: 8%;
            }
            &:nth-of-type(4) {
                width: 8%;
            }
            &:nth-of-type(5) {
                width: 12%;
            }
            &:nth-of-type(6) {
                width: 8%;
            }
            &:nth-of-type(7) {
                width: 10%;
            }
            &:nth-of-type(8) {
                width: 12%;
            }
            &:nth-of-type(9) {
                width: 10%;
            }
        }
    }
    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(6) {
        th {
            &:nth-of-type(1) {
                width: 24%;
            }
            &:nth-of-type(2) {
                width: 24%;
            }
            &:nth-of-type(3) {
                width: 12%;
            }
            &:nth-of-type(4) {
                width: 8%;
            }
            &:nth-of-type(5) {
                width: 10%;
            }
            &:nth-of-type(6) {
                width: 12%;
            }
            &:nth-of-type(7) {
                width: 10%;
            }
        }
    }
}

</style>
