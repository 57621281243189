<template>
    <div class="spec-table">
        <div class="table-area">
            <table>
                <tr>
                    <th>品番</th>
                    <th>色温度</th>
                    <th>発光色</th>
                    <th>全光束</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-VN2835WW25-24V182</td>
                    <td>2500K</td>
                    <td>電球色</td>
                    <td>1500lm</td>
                    <td rowspan="3">182pcs</td>
                    <td rowspan="3">DC24V</td>
                    <td rowspan="3">0.625A</td>
                    <td rowspan="3">15W</td>
                    <td rowspan="3">IP20</td>
                </tr>
                <tr>
                    <td>FL-VN2835WW28-24V182</td>
                    <td>2800K</td>
                    <td>電球色</td>
                    <td>1500lm</td>
                </tr>
                <tr>
                    <td>FL-VN2835CW60-24V182</td>
                    <td>6000K</td>
                    <td>昼白色</td>
                    <td>1500lm</td>
                </tr>


            </table>
        </div>

    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
        th {
            &:nth-of-type(1) {
                width: 24%;
            }
            &:nth-of-type(2) {
                width: 8%;
            }
            &:nth-of-type(3) {
                width: 8%;
            }
            &:nth-of-type(4) {
                width: 8%;
            }
            &:nth-of-type(5) {
                width: 12%;
            }
            &:nth-of-type(6) {
                width: 8%;
            }
            &:nth-of-type(7) {
                width: 10%;
            }
            &:nth-of-type(8) {
                width: 12%;
            }
            &:nth-of-type(9) {
                width: 10%;
            }
        }
}

</style>
