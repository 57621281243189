<template>
  <div class="slide-content-top">
    <div class="text-area">
      <div class="text-inner-wrap">
        <p class="series">{{ products[id].series }}</p>
        <h2>
          {{ products[id].name
          }}<span v-if="products[id].name_type != ''">
            ({{ products[id].name_type }})</span
          >
        </h2>
        <ul class="tag">
          <li v-for="type in products[id].types" :key="type.id">
            {{ typeName[Number(type)] }}
          </li>
        </ul>
        <p class="description">{{ products[id].description_top }}</p>
        <router-link
          :to="{ name: 'products-detail', params: { id: id } }"
          class="detail-link"
        >
          <p>詳細はこちら</p>
        </router-link>
      </div>
    </div>
    <div
      class="img-area"
      :style="{
        'background-image':
          'url(' +
          require('@/assets/img/ppt-' + products[id].id + '.jpg') +
          ')',
      }"
    ></div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";
import typeName from "../assets/json/typeName.json";
export default {
  data: () => {
    return {
      products: products,
      typeName: typeName,
    };
  },
  props: ["id"],
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.slide-content-top {
  position: absolute;
  top: 0;
  left: 0;
  @include lg {
    display: flex;
  }
  width: 100%;
  height: 100%;
  .text-area {
    position: relative;
    width: 60%;
    text-align: left;
    @include md {
      background: white;
      position: absolute;
      top: 40vw;
      width: 100%;
      height: calc(100% - 40vw);
    }
    // padding: 3.2vw 6.4vw;
    .text-inner-wrap {
      @include lg {
        position: absolute;
        top: 50%;
        left: calc(28px + 2.8vw);
        width: calc(100% - 56px - 5.6vw);
        @include translate(0, -50%);
      }
      @include md {
        padding: 4vw;
      }
    }
    .series {
      margin-top: 0.8vw;
      font-size: calc(6.4px + 0.64vw);
      // height: calc(6px + 0.6vw);
      margin-bottom: 0.1em;
      opacity: 0.8;
      @include md {
        font-size: calc(9px + 0.9vw);
      }
    }
    h2 {
      font-weight: 500;
      font-size: calc(8.4px + 0.84vw);
      letter-spacing: 0em;
      padding-bottom: 0.64vw;
      @include md {
        padding-bottom: 2.4vw;
        font-size: calc(11px + 1.1vw);
      }
    }
    .tag {
      display: flex;
      padding-left: 0;
      justify-content: flex-start;
      li {
        list-style: none;
        display: inline-block;
        width: calc(40px + 4vw);
        height: unset;
        padding: 0.4vw 0vw;
        border-radius: 0.32vw;
        border: #828282 1px solid;
        color: #828282;
        margin-right: 0.48vw;
        text-align: center;
        line-height: 1.4;
        font-size: calc(6.4px + 0.64vw);
        @include md {
          width: calc(48px + 4.8vw);
          margin-right: 0.96vw;
          padding: 1.44vw 0vw;
          font-size: calc(8px + 0.8vw);
        }
      }
    }
    .description {
      margin: 1.8vw 0 2.1vw;
      text-align: left;
      line-height: 1.88;
      letter-spacing: 0.024em;
      font-size: calc(5.4px + 0.7vw);
      @include md {
        padding: 2vw 0 0.8vw;
        font-size: calc(8px + 0.8vw);
        line-height: 1.9;
      }
    }
    .detail-link {
      display: inline-block;
      width: 96%;
      padding: 0.96vw;
      font-weight: bold;
      border: black 1px solid;
      transition: 0.2s;
      border-radius: 4px;
      @include md {
        width: 100%;
        margin-left: -4vw;
        padding: 1.6vw;
        position: absolute;
        bottom: 0vw;
        border: none;
        background: #{$color-green}cc;
        // background: #aaa;
        filter: brightness(1.05);
        p {
          color: #f2f2f2;
        }
      }
      @include lg {
        &:hover {
          background: #{$color-green}af;
          border: #{$color-green}af 1px solid;
          filter: brightness(1.1) saturate(1.05);
          box-shadow: 0px 2px 16px rgba(33, 150, 83, 0.2);
          transition: all 0.3s, box-shadow 0.4s;
          p {
            color: white;
            transition: 0.2s;
          }
        }
      }
      p {
        width: 100%;
        text-align: center;
        font-size: calc(6.8px + 0.68vw);
        transition: 0.2s;
        font-weight: normal;
        @include md {
          padding: 2vw 0;
          font-size: calc(9.4px + 0.94vw);
        }
      }
    }
  }
  .img-area {
    width: 42%;
    @include md {
      width: 100%;
      height: 40vw;
    }
    // background: url('../assets/img/ppt-04.jpg') no-repeat  center center/cover;
    // background-image: var(--bgImage);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
</style>
