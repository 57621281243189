<template>
  <!-- 10 STORM ハイパワー投光器（3パネル制御タイプ） -->
  <div>
    <div class="table-area">
      <!-- <h3></h3> -->
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-STORM 3Panel108</td>
          <td rowspan="1">
            <a
              href="../catalog/FL-STORM3Panel108_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
