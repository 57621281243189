<template>
    <div class="spec-table">
        <div class="table-area">
            <table>
                <tr>
                    <th>品番</th>
                    <th>光源色</th>
                    <th>光束</th>
                    <th>配光角</th>
                    <th>電圧</th>
                    <th>消費電力</th>
                    <th>制御方法</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-STORM 3Panel108</td>
                    <td>RGBW</td>
                    <td>約15,700 lm</td>
                    <td>25度</td>
                    <td>AV100~240V</td>
                    <td>350W</td>
                    <td>DMX</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>
        <p>寸法（mm）：W600*H330*D180</p>
    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
        th {
            // &:nth-of-type(1) {
            //     width: 24%;
            // }
            // &:nth-of-type(2) {
            //     width: 24%;
            // }
            // &:nth-of-type(3) {
            //     width: 12%;
            // }
            // &:nth-of-type(4) {
            //     width: 8%;
            // }
            // &:nth-of-type(5) {
            //     width: 10%;
            // }
            // &:nth-of-type(6) {
            //     width: 12%;
            // }
            // &:nth-of-type(7) {
            //     width: 10%;
            // }
        }
}

</style>
