import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from 'axios'
import VueAxios from 'vue-axios'
import VModal from 'vue-js-modal'
import VScrollLock from 'v-scroll-lock'
import VuePrlx from 'vue-prlx'
import Inview from 'vueinview'
import VueObserveVisibility from 'vue-observe-visibility'
import IntersectionObserver from 'intersection-observer'
import VueYoutube from 'vue-youtube'



var history = require('connect-history-api-fallback');
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, history())


// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
// var VueAwesomeSwiper = require('vue-awesome-swiper')


Vue.config.productionTip = false;
Vue.use(VueAxios, axios)
Vue.use(VModal);
Vue.use(VScrollLock)
Vue.use(VuePrlx);
Vue.use(Inview);
Vue.use(VueObserveVisibility);
Vue.use(IntersectionObserver);
Vue.use(VueYoutube);

// Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// router.beforeEach((to, from, next) => {
//   if (to.path === '/contact-form') {
//     next('/contact')
//   } else {
//     next();
//   }
// })

router.beforeEach((to, from, next) => {
  console.log('beforeEach', to.path);
  const path = to.path.replace(/\/{2,}/g, '/');
  if (path !== to.path) {
    console.log('redirect', path);
    next({ path, query: to.query, hash: to.hash });
  } else {
    next();
  }
});

// window.checkreload = true;

// window.addEventListener('popstate', function(e) {
// alert('ブラウザバックを検知しました。');
// });

require('@/assets/sass/main.scss');
