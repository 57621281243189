<template>
  <div class="spec-table">
    <div class="table-area">
      <h3>ユニット型電源</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>入力電圧</th>
          <th>出力電圧</th>
          <th>最大出力電圧</th>
        </tr>
        <tr>
          <td>FL-RWS50B</td>
          <td rowspan="4">AC100~240V</td>
          <td rowspan="4">5V/12V/24V</td>
          <td>50W</td>
        </tr>
        <tr>
          <td>FL-RWS100B</td>
          <td>100W</td>
        </tr>
        <tr>
          <td>FL-RWS150B</td>
          <td>150W</td>
        </tr>
        <tr>
          <td>FL-RWS300B</td>
          <td>300W</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>アダプター電源</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>入力電圧</th>
          <th>出力電圧</th>
          <th>最大出力電圧</th>
          <th>最大出力電流値</th>
        </tr>
        <tr>
          <td>FL-AKA-12020</td>
          <td rowspan="5">AC100~240V</td>
          <td rowspan="3">12V</td>
          <td>24W</td>
          <td>2A</td>
        </tr>
        <tr>
          <td>FL-AKA-12030</td>
          <td>36W</td>
          <td>3A</td>
        </tr>
        <tr>
          <td>FL-AKA-12050</td>
          <td>60W</td>
          <td>5A</td>
        </tr>
        <tr>
          <td>FL-AKA-24016</td>
          <td rowspan="2">24V</td>
          <td>40W</td>
          <td>1.67A</td>
        </tr>
        <tr>
          <td>FL-AKA-24271</td>
          <td>65W</td>
          <td>2.71A</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>防水タイプ電源</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>入力電圧</th>
          <th>出力電圧</th>
          <th>最大出力電圧</th>
          <th>保護等級</th>
        </tr>
        <tr>
          <td>FL-HLG120H</td>
          <td rowspan="4">AC100~240V</td>
          <td rowspan="4">12V/24V</td>
          <td>120W</td>
          <td rowspan="4">IP65</td>
        </tr>
        <tr>
          <td>FL-HLG150H</td>
          <td>150W</td>
        </tr>
        <tr>
          <td>FL-HLG240H</td>
          <td>240W</td>
        </tr>
        <tr>
          <td>FL-HLG320H</td>
          <td>320W</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";
export default {
  data: () => {
    return {
      products: products,
    };
  },
  props: {
    id: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
  th {
    &:nth-of-type(1) {
      width: 30%;
    }
    &:nth-of-type(2) {
      width: 16%;
    }
    &:nth-of-type(3) {
      width: 16%;
    }
  }
  &:nth-of-type(1) {
    th {
      &:nth-of-type(4) {
        width: 38%;
      }
    }
  }
  &:nth-of-type(2),
  &:nth-of-type(3) {
    th {
      &:nth-of-type(4) {
        width: 19%;
      }
      &:nth-of-type(5) {
        width: 19%;
      }
    }
  }
}
</style>
