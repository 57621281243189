var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-detail-content"},[_c('h2',[_vm._v("単色タイプ")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '02' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-MD131 ")]),_c('p',{staticClass:"img-area"},[_vm._v(" 寸法（mm）W45*H6.1*D28.4 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '03' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-MD123 ")]),_c('p',{staticClass:"img-area"},[_vm._v(" 寸法（mm）：W22*H10*D15 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '04' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-MD80 ")]),_c('p',{staticClass:"img-area"},[_vm._v(" 寸法（mm）：W58*H7.8*D38 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '05' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-MD1312 ")]),_c('p',{staticClass:"img-area"})])]),_c('h2',[_vm._v("RGBフルカラータイプ")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '06' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-MD124 ")]),_c('p',{staticClass:"img-area"},[_vm._v(" 寸法（mm）：W22*H10*D15 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '07' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-735G-RGB ")]),_c('p',{staticClass:"img-area"},[_vm._v(" 寸法（mm）：W75*H5*D15 ")])])]),_c('h2',[_vm._v("デジタルタイプ")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '08' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-MD125 ")]),_c('p',{staticClass:"img-area"},[_vm._v(" 寸法（mm）：W22*H10*D15 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '09' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 品番：FL-MD115 ")]),_c('p',{staticClass:"img-area"},[_vm._v(" 寸法（mm）：W65*H7.5*D15.4 ")])])]),_c('h2',[_vm._v("専用オプション品（単色タイプ用）")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '10' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 記憶型リモコン式調光器 ")]),_c('p'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '11' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" リモコン式調光器 ")]),_c('p'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1)]),_c('h2',[_vm._v("専用オプション品（RGBフルカラータイプ用）")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '12' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" リモコン式RGB調光制御器 ")]),_c('p'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '13' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" DMX to RGBデコーダー ")]),_c('p'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '14' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" RGB信号増幅機 ")]),_c('p'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1)]),_c('h2',[_vm._v("専用オプション品（デジタルタイプ用）")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-07-' + '15' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" DMX to SPIデコーダー ")]),_c('p'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }