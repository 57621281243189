<template>
	<div class="news-content-01 news-content">
		<div class="img-wrapper">
			<div class="img-area news-img-05-01" :style="{'background-image': 'url(' + require('@/assets/img/npc-05-01.jpg') + ')'}"></div>
		</div>
		<p>
			日本キャタピラー合同会社様へテープライトを納品しましたご縁から、建築重機試乗会(2021年2月に開催)に参加致しました。重機周囲への注意喚起として、赤色テープライトをご利用いただいております。
		</p>
		<br><br><br>
		<div class="video">
		<youtube :video-id="videoId" :player-vars="playerVars" :width="videoWidth" :height="videoHeight" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
	return {
	videoId: 'AfOMfFNqstk',
	widthRate: 0.76,
	heightRate: 0.76*0.5625,
	windowWidth: window.innerWidth,
	playerVars: {
        autoplay: 0,
		modestbranding: 1,
		rel: 0
	}
	}
	},
	computed: {
		videoWidth: function() {
			return this.windowWidth*this.widthRate;
		},
		videoHeight: function() {
			return this.windowWidth*this.heightRate;
		}
	},
	methods: {
	handleResize: function() {
      this.windowWidth = window.innerWidth;
    //   this.height = window.innerHeight;
	if(window.innerWidth > 899) {
		this.widthRate = 0.76;
		this.heightRate = 0.76*0.5625;
	}else {
		this.widthRate = 0.92;
		this.heightRate = .92*.5625;
	}
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
	if(window.innerWidth > 899) {
		this.widthRate = 0.76;
		this.heightRate = 0.76*0.5625;
	}else {
		this.widthRate = 0.92;
		this.heightRate = .92*.5625;
	}
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>
	@import "../assets/sass/global.scss";
	.img-wrapper {
		position: relative;
		width: 100%;
		margin: 0 0 4vw;
	}
	.img-wrapper:before {
		content:"";
		display: block;
		padding-top: 56.25%; /* 高さを幅の75%に固定 */
	}
	.news-img-05-01 {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	.video {
		@include md {
			margin-left: -2vw;
		}
	}
</style>
