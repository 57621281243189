<template>
  <div class="products-detail-content">
    <h2>単色タイプ</h2>
    <div class="list-wrap">
      <!-- 02 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '02' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-MD131
        </h3>
        <p class="img-area">
          寸法（mm）W45*H6.1*D28.4
        </p>
      </div>
      <!-- 03 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '03' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-MD123
        </h3>
        <p class="img-area">
          寸法（mm）：W22*H10*D15
        </p>
      </div>
      <!-- 04 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '04' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-MD80
        </h3>
        <p class="img-area">
          寸法（mm）：W58*H7.8*D38
        </p>
      </div>
      <!-- 05 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '05' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-MD1312
        </h3>
        <p class="img-area"></p>
      </div>
    </div>

    <h2>RGBフルカラータイプ</h2>
    <div class="list-wrap">
      <!-- 06 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '06' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-MD124
        </h3>
        <p class="img-area">
          寸法（mm）：W22*H10*D15
        </p>
      </div>
      <!-- 07 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '07' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-735G-RGB
        </h3>
        <p class="img-area">
          寸法（mm）：W75*H5*D15
        </p>
      </div>
    </div>

    <h2>デジタルタイプ</h2>
    <div class="list-wrap">
      <!-- 08 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '08' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-MD125
        </h3>
        <p class="img-area">
          寸法（mm）：W22*H10*D15
        </p>
      </div>
      <!-- 09 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '09' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          品番：FL-MD115
        </h3>
        <p class="img-area">
          寸法（mm）：W65*H7.5*D15.4
        </p>
      </div>
    </div>

    <h2>専用オプション品（単色タイプ用）</h2>
    <div class="list-wrap">
      <!-- 10 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '10' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          記憶型リモコン式調光器
        </h3>
        <p></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
      <!-- 11 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '11' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          リモコン式調光器
        </h3>
        <p></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
    </div>

    <h2>専用オプション品（RGBフルカラータイプ用）</h2>
    <div class="list-wrap">
      <!-- 12 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '12' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          リモコン式RGB調光制御器
        </h3>
        <p></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
      <!-- 13 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '13' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          DMX to RGBデコーダー
        </h3>
        <p></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
      <!-- 14 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '14' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          RGB信号増幅機
        </h3>
        <p></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
    </div>

    <h2>専用オプション品（デジタルタイプ用）</h2>
    <div class="list-wrap">
      <!-- 15 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-07-' + '15' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          DMX to SPIデコーダー
        </h3>
        <p></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.hasHeight {
  // height: 5.2em;
}
</style>
