<template>
    <div style="position: relative;">
        <hooper class="hooper__hooper02" :settings="hooperSettings">
            <slide class="hooper__slide" v-for="n in length" :key="n.id">
                <div v-if="n<10" class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/wp-'+ id + '-0' + n + '.jpg') + ')'}"></div>
                <div v-else class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/wp-'+ id + '-' + n + '.jpg') + ')'}"></div>
            </slide>
            <hooper-pagination slot="hooper-addons" />
            <hooper-navigation slot="hooper-addons" />
        </hooper>
        <img src="../assets/img/mk-03.svg" alt="" class="left-mark">
        <img src="../assets/img/mk-04.svg" alt="" class="right-mark">
    </div>
</template>


<script>
  import {Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation} from 'hooper';
  import 'hooper/dist/hooper.css';

export default {
    methods: {
    },
    components: {
      Hooper,
      Slide,
      HooperPagination,
      HooperNavigation
    },
    data() {
      return {
        hooperSettings: {
            itemsToShow: 1,
            centerMode: true,
            wheelControl: false,
            infiniteScroll: true,
            autoPlay: true,
            playSpeed: 6000,
            transition: 640
        },
      }
    },
    props: {
        id: {
            type: String
        },
        length: {
            // default: 4,
            type: Number
        }
    }
    // ['id', 'photoLength']
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
 .hooper {
     margin: auto;
    text-align: center;

    &__hooper {

        }

    &__slide {
        // border: #828282 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }

  }

    .left-mark,
    .right-mark {
        position: absolute;
        top: 46%;
        width: 1.2vw;
        @include translate(0, -50%);
        pointer-events: none;
        @include md {
            top: 43%;
            width: 2.4vw;
        }
    }
    .left-mark {
        left: 2vw;
        @include md {
            left: 3.2vw;
        }
    }
    .right-mark {
        right: 2vw;
        @include md {
            right: 3.2vw;
        }
    }

    .img-area {
        width: 100%; height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

</style>

<style lang="scss">
@import "../assets/sass/global.scss";

.hooper__hooper02 {
    $height: 56vw;
    @include md {
        // $height: 68vw;
    }
    height: $height;
    @include md {
        height: 78vw;
    }
        .hooper-list {
            position: relative;
            // height: 39.8vw;
            // overflow-x: hidden;
            // overflow-y: visible;
            @include md {
                width: 100vw;
            }
        }
        .hooper-navigation {
            position: absolute;
            top: 0;
            width: 100%; height: calc(#{$height}*0.92);
            @include md {
                height: calc(78vw * 0.86);
            }
        .hooper-prev,
        .hooper-next {
            position: absolute;
            // background: black; //test
            width: 5.6vw; height: 16vw;
        //     transition: 0.3s;
            @include md {
                width: 12vw;
            }
            svg {
                display: none;
            }
        //     &.is-disabled {
        //         opacity: 0.8;
        //     }
        //     &:hover {
        //         background: $color-green;
        //         filter: brightness(0.85) saturate(1.1);
        //         transition: 0.12s;
        //     }
        }
    }
    .hooper-track {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        > li {
            position: relative;
            width: 100% !important; height: 92%;
            @include md {
                height: 86%;
            }
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
    .hooper-indicator {
        width: 12px; height: 12px;
        border-radius: 50%;
        margin: 0 0.48vw;
        border: #BDBDBD 1px solid;
        @include md {
            width: 9px; height: 9px;
            margin: 0 1.04vw;
        }
        &:hover {
            background: $color-green;
            border: #BDBDBD00 1px solid;
            transition: 0.3s;
        }
        &.is-active {
            background: #BDBDBD;
            border: #BDBDBD00 1px solid;
        }
    }
}

</style>
