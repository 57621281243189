<template>
    <div class="products-detail-content">

        <h2>カラーバリエーション例</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    色の組み合わせ：赤
                </h3>
                <p>
                    真っ赤なLED照明です。赤色は炎や熱をイメージさせますので焼き物の食品を扱う方に人気が有ります。使い方次第で本物の炎を使わず安全に炎を演出するのに向いています。
                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    色の組み合わせ：緑
                </h3>
                <p>
                    緑色は植物の色合いを演出できます。単体では使わずに他の色と組み合わせて楽しげなイメージを出すクリスマス用の置物等に使われることが多いです。
                </p>
            </div>
            <!-- 04 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '04' + '.jpg') + ')'}"></div>
                <h3>
                    色の組み合わせ：青
                </h3>
                <p>
                    単色の中でも最も人気なのが青色のLED照明。防水という特性の相性は良く水槽に取り付けたり、車のライトに利用したり他のLEDカラーと比べて演出幅が広いので応用が効きます。
                </p>
            </div>
            <!-- 05 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '05' + '.jpg') + ')'}"></div>
                <h3>
                    色の組み合わせ：黄色（赤＋緑）
                </h3>
                <p>
                    黄色は赤と緑の組み合わせで発光しているので単体で使われる事は少ないです。他の色と組み合わせて賑やかな演出が可能です。単体の場合は色合い的に電球色のLEDを選択した方が良い場合もあります。
                </p>
            </div>
            <!-- 06 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '06' + '.jpg') + ')'}"></div>
                <h3>
                    色の組み合わせ：緑＋青
                </h3>
                <p>

                </p>
            </div>
            <!-- 07 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '07' + '.jpg') + ')'}"></div>
                <h3>
                    色の組み合わせ：赤＋青
                </h3>
                <p>

                </p>
            </div>
            <!-- 08 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '08' + '.jpg') + ')'}"></div>
                <h3>
                    色の組み合わせ：赤＋緑＋青
                </h3>
                <p>

                </p>
            </div>
        </div>

        <h2>FLEXIA LEDテープライトの特徴</h2>
        <div class="list-wrap">
            <!-- 09 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '09' + '.jpg') + ')'}"></div>
                <h3>
                    1. 高性能な防水性と防塵性
                </h3>
                <p>
                    シリコン樹脂で保護された基盤全体をチューブで360°覆うことで高寿命、保護等級IP68準拠の防塵性・防水性を実現！また、ハイパワーのLEDチップが等間隔に並んでいるため、従来の白熱灯やLEDに比べとても明るい上に省エネ性も高く、常時点灯していても電気代は安く済みます。
                </p>
            </div>
            <!-- 10 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '10' + '.jpg') + ')'}"></div>
                <h3>
                    2. 好きな長さにカット可能
                </h3>
                <p>
                    等間隔で「カットポイント」が設けられており好きな長さでカットすることができます。間違ってカットしてしまってもはんだ付けで再び繋ぐことも可能ですし、両面テープでは貼り付けにくい出っ張り等は導線で跨がせることにより従来のLEDでは表現できなかった照明効果を発揮できます。
                </p>
            </div>
            <!-- 11 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '11' + '.jpg') + ')'}"></div>
                <h3>
                    3. 扱いやすい極薄仕様で曲げられる
                </h3>
                <p>
                    幅10.5mm、厚さ3.5mmと幅が短く極薄仕様！強力な両面テープで様々な場所に施工可能です。また、FLEXIAは光軸方向に曲げることが可能なLEDです。その名の通りテープのように曲げることが可能なので、直線だけでなく、曲面・曲線施工に最適です。
                </p>
            </div>
        </div>


        <h2>専用オプション品</h2>
        <div class="list-wrap">
            <!-- 12 -->
            <div class="list hasRm">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '12' + '.jpg') + ')'}"></div>
                <h3>
                    5050タイプ専用アルミバー
                </h3>
                <p>
                    「高輝度」な5050タイプに取り付けが可能なアルミバーです。放熱に優れ、ライン照明にはおすすめです。
                </p>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 15 }}"
                    class="link"
                >
                詳細はこちら
                </router-link>
            </div>
            <!-- 13 -->
            <div class="list hasRm">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '13' + '.jpg') + ')'}"></div>
                <h3>
                    角設置専用アルミバー
                </h3>
                <p>
                    角に設置するために断面が三角形になっているアルミバーです。どのLEDテープライトにもご使用頂けます。
                </p>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 15 }}"
                    class="link"
                >
                詳細はこちら
                </router-link>
            </div>
            <!-- 14 -->
            <div class="list hasRm">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '14' + '.jpg') + ')'}"></div>
                <h3>
                    ドットレス専用アルミバー
                </h3>
                <p>
                    他のアルミバーよりも深さがあり、より粒感をなくした美しい光を演出できます。どのLEDテープライトにもご使用頂けます。
                </p>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 15 }}"
                    class="link"
                >
                詳細はこちら
                </router-link>
                    <div class="recommended">
                    <p>おすすめ</p>
                </div>
            </div>
            <!-- 15 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '15' + '.jpg') + ')'}"></div>
                <h3>
                    リモコン式RGB調光制御器
                </h3>
                <p>

                </p>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 14 }}"
                    class="link"
                >
                詳細はこちら
                </router-link>
            </div>
            <!-- 16 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '16' + '.jpg') + ')'}"></div>
                <h3>
                    DMX to RGBデコーダー
                </h3>
                <p>

                </p>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 14 }}"
                    class="link"
                >
                詳細はこちら
                </router-link>
            </div>
            <!-- 17 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-02-'+ '17' + '.jpg') + ')'}"></div>
                <h3>
                    RGB信号増幅機
                </h3>
                <p>

                </p>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 14 }}"
                    class="link"
                >
                詳細はこちら
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
