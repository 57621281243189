<template>
    <div class="home">
        <div id="preload">
            <img rel="preload" alt="" src="../assets/img/wp-12-01.jpg" />
            <img rel="preload" alt="" src="../assets/img/wp-14-01.jpg" />
            <img rel="preload" alt="" src="../assets/img/wp-31-01.jpg" />

            <img rel="preload" alt="" src="../assets/img/np-02.jpg" />
            <img rel="preload" alt="" src="../assets/img/np-03.jpg" />
            <img rel="preload" alt="" src="../assets/img/np-04.jpg" />

            <img rel="preload" alt="" src="../assets/img/wp-11-01.jpg" />
            <img rel="preload" alt="" src="../assets/img/wp-06-01.jpg" />
            <img rel="preload" alt="" src="../assets/img/wp-13-01.jpg" />
            <img rel="preload" alt="" src="../assets/img/wp-12-01.jpg" />
            <img rel="preload" alt="" src="../assets/img/wp-03-01.jpg" />
            <img rel="preload" alt="" src="../assets/img/wp-07-01.jpg" />
        </div>
        <section :style="{ height: winH + 'px' }" id="mv-area" v-observe-visibility="visibilityChangedTop"
            v-bind:class="{ active: isVisibleTop }">
            <div class="img-area">
                <video class="vid_main" src="../assets/img/top-movie-2024.mp4" autoplay loop playsinline muted></video>
                <img style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40vw;
      " src="../assets/logo_international_white_rgb.png" />
            </div>
            <div class="scroll-down"
                v-scroll-to="{
          el: '#news-link-area',
          duration: 660,
          offset: 0,
          easing: ease,
        }">
                <p>SCROLLING</p>
                <img :class="{ move: boolMove }" alt="" src="../assets/img/mk-07.svg" />
            </div>
        </section>

        <main ref="main">
            <!-- <temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp>--新着情報<temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>---- -->
            <section id="news-link-area">
                <!-- <img alt="" :class="['bg-logo', { move: bgLogoMove }]" src="../assets/img/tp-01.png" />
                <img alt="" :class="['bg-logo', { move: bgLogoMove2 }]" src="../assets/img/tp-01.png" /> -->
                <div class="title-area">
                    <h1>
                        <p>新着情報</p>
                        <p>NEWS</p>
                    </h1>
                </div>

                <div class="link-content-area" v-observe-visibility="visibilityChanged01"
                    v-bind:class="{ active: isVisible01 }">
                    <router-link :to="{ name: 'news-detail', params: { id: 15 } }">
                        <div class="link-content">
                            <div :style="{
                                'background-size': 'contain',
                                'background-image': 'url(' + require('@/assets/img/logo_mark.png') + ')',
                            }"
                                class="img-area" />
                            <div class="text-area">
                                <p>2024.04.01<span>|</span>お知らせ</p>
                                <h4>
                                    新しいロゴ：当社のブランドイメージを一新
                                </h4>
                            </div>
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'news-detail', params: { id: 14 } }">
                        <div class="link-content">
                            <div :style="{
                                'background-size': 'contain',
                                'background-image': 'url(' + require('@/assets/img/np-14.jpg') + ')',
                            }"
                                class="img-area" />
                            <div class="text-area">
                                <p>2024.04.01<span>|</span>お知らせ</p>
                                <h4>
                                    【重要】事業譲渡のご案内
                                </h4>
                            </div>
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'news-detail', params: { id: 13 } }">
                        <div class="link-content">
                            <div :style="{
                                'background-size': 'contain',
                                'background-image': 'url(' + require('@/assets/img/np-14.jpg') + ')',
                            }"
                                class="img-area" />
                            <div class="text-area">
                                <p>2023.07.25<span>|</span>お知らせ</p>
                                <h4>
                                    夏季休業のお知らせ
                                </h4>
                            </div>
                        </div>
                    </router-link>
                    <!-- <router-link :to="{ name: 'news-detail', params: { id: 13 } }">
            <div class="link-content">
              <div
                :style="{
                    'background-image': 'url(' + require('@/assets/img/np-12.jpg') + ')',
                }"
                class="img-area"
              />
              <div class="text-area">
                <p>2023.07.25<span>|</span>お知らせ</p>
                <h4>
                  夏季休業のお知らせ
                </h4>
              </div>
            </div>
          </router-link>
          <router-link :to="{ name: 'news-detail', params: { id: 12 } }">
            <div class="link-content">
              <div
                :style="{
                    'background-image': 'url(' + require('@/assets/img/np-12.jpg') + ')',
                }"
                class="img-area"
              />
              <div class="text-area">
                <p>2022.07.16<span>|</span>お知らせ</p>
                <h4>
                  夏季休業のお知らせ
                </h4>
              </div>
            </div>
          </router-link> -->
                    <!-- <router-link :to="{ name: 'news-detail', params: { id: 8 } }">
            <div class="link-content">
              <div
                :style="{
                    'background-image': 'url(' + require('@/assets/img/np-08.jpg') + ')',
                }"
                class="img-area"
              />
              <div class="text-area">
                <p>2021.7.7<span>|</span>検証実験</p>
                <h4>
                  結晶化ガラス建材「パリトーン」と当社LEDの検証実験（2/2回）～流水の表現～
                </h4>
              </div>
            </div>
          </router-link> -->
                </div>

                <div class="list-link-router-wrap" v-observe-visibility="visibilityChangedLink01"
                    v-bind:class="{ active: isVisibleLink01 }">
                    <router-link active-class="link--active" class="list-link-router" exact to="/news">
                        <div class="list-link">
                            <p>すべての新着情報をみる</p>
                            <img alt="" src="../assets/img/mk-02.svg" />
                        </div>
                    </router-link>
                </div>
            </section>

            <!-- ---</temp><temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>------納入事例---</temp><temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp>- -->
            <!-- <to-information></to-information> -->
            <section id="works-link-area">
                <div class="title-area">
                    <h1>
                        <p>納入事例</p>
                        <p>WORKS</p>
                    </h1>
                </div>

                <div class="img-area" v-observe-visibility="visibilityChangedWorks"
                    v-bind:class="{ active: isVisibleWorks }">
                    <div class="flex-area">
                        <router-link :to="{ name: 'works-detail', params: { id: 11 } }" class="router">
                            <div class="img-wrap1 img-wrap">
                                <div class="img1 img"></div>
                            </div>
                            <div class="text-area">
                                <h4 class="title">{{ works[11] . name }}</h4>
                                <p class="show-detail">詳細はこちら</p>
                            </div>
                        </router-link>

                        <router-link :to="{ name: 'works-detail', params: { id: 12 } }" class="router">
                            <div class="img-wrap2 img-wrap">
                                <div class="img2 img"></div>
                            </div>
                            <div class="text-area">
                                <h4 class="title">{{ works[12] . name }}</h4>
                                <p class="show-detail">詳細はこちら</p>
                            </div>
                        </router-link>

                        <router-link :to="{ name: 'works-detail', params: { id: 13 } }" class="router">
                            <div class="img-wrap3 img-wrap">
                                <div class="img3 img"></div>
                            </div>
                            <div class="text-area">
                                <h4 class="title">{{ works[13] . name }}</h4>
                                <p class="show-detail">詳細はこちら</p>
                            </div>
                        </router-link>
                    </div>

                    <div class="flex-area">
                        <router-link :to="{ name: 'works-detail', params: { id: 14 } }" class="router">
                            <div class="img-wrap4 img-wrap">
                                <div class="img4 img"></div>
                            </div>
                            <div class="text-area">
                                <h4 class="title">{{ works[14] . name }}</h4>
                                <p class="show-detail">詳細はこちら</p>
                            </div>
                        </router-link>

                        <router-link :to="{ name: 'works-detail', params: { id: 15 } }" class="router">
                            <div class="img-wrap5 img-wrap">
                                <div class="img5 img"></div>
                            </div>
                            <div class="text-area">
                                <h4 class="title">{{ works[15] . name }}</h4>
                                <p class="show-detail">詳細はこちら</p>
                            </div>
                        </router-link>

                        <router-link :to="{ name: 'works-detail', params: { id: 16 } }" class="router">
                            <div class="img-wrap6 img-wrap">
                                <div class="img6 img"></div>
                            </div>
                            <div class="text-area">
                                <h4 class="title">{{ works[16] . name }}</h4>
                                <p class="show-detail">詳細はこちら</p>
                            </div>
                        </router-link>
                    </div>
                </div>

                <div class="list-link-router-wrap" v-observe-visibility="visibilityChangedLink02"
                    v-bind:class="{ active: isVisibleLink02 }">
                    <router-link active-class="link--active" class="list-link-router" exact to="/works">
                        <div class="list-link">
                            <p>すべての納入事例をみる</p>
                            <img alt="" src="../assets/img/mk-02.svg" />
                        </div>
                    </router-link>
                </div>
            </section>

            <!-- <temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp>--主な取扱製品<temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>---- -->
            <section id="product-link-area">
                <div class="title-area">
                    <h1>
                        <p>主な取扱製品</p>
                        <p>PRODUCT</p>
                    </h1>
                </div>

                <slider :content="productSlideContent" class="product-slider"
                    v-observe-visibility="visibilityChangedProduct" v-bind:class="{ active: isVisibleProduct }" />

                <div class="list-link-router-wrap" v-observe-visibility="visibilityChangedLink03"
                    v-bind:class="{ active: isVisibleLink03 }">
                    <router-link active-class="link--active" class="list-link-router" exact to="/products">
                        <div class="list-link">
                            <p>すべての製品をみる</p>
                            <img alt="" src="../assets/img/mk-02.svg" />
                        </div>
                    </router-link>
                </div>
            </section>

            <!-- ---</temp><temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>------お受けできる業務---</temp><temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp>- -->
            <section id="service-link-area">
                <div class="title-area">
                    <h1>
                        <p>お受けできる業務</p>
                        <p>SERVICE</p>
                    </h1>
                </div>
                <ul class="list-area">
                    <li class="list">
                        <img alt="" src="../assets/img/tp-02.png" />
                        <div class="description-area">
                            <h4><span>1</span>LED製品の販売・加工</h4>
                            <p>
                                LED製品の販売および自社工場にてお客様のご要望に合わせてLED製品の加工を行います。弊社ブランドの製品であれば、在庫を保有しており、短納期での対応も可能です。
                            </p>
                        </div>
                    </li>
                    <li class="list">
                        <img alt="" src="../assets/img/tp-03.png" />
                        <div class="description-area">
                            <h4><span>2</span>特注器具の企画開発・販売</h4>
                            <p>
                                LED製品や照明器具以外でも、お客様のご要望に合わせて特注で制作し、販売することが可能です。過去に「ライナーライト」や「スパイラル電球」など、年間数万個単位での販売実績がございます。
                            </p>
                        </div>
                    </li>
                    <li class="list">
                        <img alt="" src="../assets/img/tp-04.png" />
                        <div class="description-area">
                            <h4><span>3</span>組み立て加工（アッセンブリ）</h4>
                            <p>
                                照明器具の販売だけでなく、弊社工場にてお客様の部材と照明器具の組み立て加工も行っております。「洗面化粧台」は年間約２万本出荷の実績がございます。
                            </p>
                        </div>
                    </li>
                    <li class="list">
                        <img alt="" src="../assets/img/tp-05.png" />
                        <div class="description-area">
                            <h4><span>4</span>ご提案からトータルプロデュース</h4>
                            <p>
                                空間デザインのご提案から、器具の選定、制御まで一括してお受けできます。どんな照明にするかお悩みの場合には、是非お客様のご要望をお聞かせ下さい。
                            </p>
                        </div>
                    </li>
                </ul>
                <p>
                    上記の業務以外でも、<br class="md" />お客様のお困りごとはなんでもご相談下さい
                </p>
            </section>

            <!-- <temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp>--まずはお問い合わせください<temp data-prettydiff-ignore>-------</temp><temp data-prettydiff-ignore>-------</temp>---- -->
            <section id="contact-link-area">
                <div class="title-area">
                    <h1>
                        <p>まずはお問い合わせください</p>
                        <p>CONTACT</p>
                    </h1>
                </div>
                <div class="form-link-area">
                    <router-link active-class="link--active" class="form-link" exact to="/contact">
                        <img alt="" src="../assets/img/mk-01.svg" />
                        <p>お問い合わせ</p>
                    </router-link>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
    // import ToInformation from '../components/HomeToInformation';
    import Slider from "../components/Slider";
    import works from "../assets/json/works.json";

    export default {
        data: () => {
            return {
                works: works,
                productSlideContent: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                boolMove: false,
                bgLogoMove: false,
                bgLogoMove2: false,
                scrollY: 0,
                mvImgShowA: false,
                mvImgShowB: false,
                mvImgShowC: false,
                winH: window.innerHeight,
                isVisibleTop: false,
                isVisible01: false,
                isVisibleWorks: false,
                isVisibleLink01: false,
                isVisibleLink02: false,
                isVisibleLink03: false,
                isVisibleProduct: false,
                // isVisible02: false
            };
        },
        methods: {
            scrolling: function() {
                scrollTo({
                    left: 0,
                    top: this.winH,
                    behavior: "smooth",
                });
            },
            onScroll() {
                this.scrollY = window.pageYOffset;
            },
            changeMvAreaImg() {
                this.mvImgShowA = true;
                this.mvImgShowB = false;
                this.mvImgShowC = false;
                setTimeout(() => {
                    this.mvImgShowA = false;
                    this.mvImgShowB = true;
                    this.mvImgShowC = false;
                }, 4400);
                setTimeout(() => {
                    this.mvImgShowA = false;
                    this.mvImgShowB = false;
                    this.mvImgShowC = true;
                }, 8800);
            },
            bgLogoMoveChange() {
                this.bgLogoMove = false;
                setTimeout(() => {
                    this.bgLogoMove = true;
                }, 40);
            },
            bgLogoMoveChange2() {
                this.bgLogoMove2 = false;
                setTimeout(() => {
                    this.bgLogoMove2 = true;
                }, 10);
            },
            visibilityChangedTop(isVisibleTop, entry) {
                if (isVisibleTop == true) {
                    this.isVisibleTop = isVisibleTop;
                }
                console.log(entry);
            },
            visibilityChanged01(isVisible01, entry) {
                if (this.isVisible01 === false) {
                    this.isVisible01 = isVisible01;
                }
                console.log(entry);
            },
            visibilityChangedWorks(isVisibleWorks, entry) {
                if (this.isVisibleWorks === false) {
                    this.isVisibleWorks = isVisibleWorks;
                }
                console.log(entry);
            },
            visibilityChangedLink01(isVisibleLink01, entry) {
                if (this.isVisibleLink01 === false) {
                    this.isVisibleLink01 = isVisibleLink01;
                }
                console.log(entry);
            },
            visibilityChangedLink02(isVisibleLink02, entry) {
                if (this.isVisibleLink02 === false) {
                    this.isVisibleLink02 = isVisibleLink02;
                }
                console.log(entry);
            },
            visibilityChangedLink03(isVisibleLink03, entry) {
                if (this.isVisibleLink03 === false) {
                    this.isVisibleLink03 = isVisibleLink03;
                }
                console.log(entry);
            },
            visibilityChangedProduct(isVisibleProduct, entry) {
                if (this.isVisibleProduct === false) {
                    this.isVisibleProduct = isVisibleProduct;
                }
                console.log(entry);
            },
            // visibilityChanged02(isVisible02, entry) {
            //     this.isVisible02 = isVisible02
            // }
        },
        created() {
            this.changeMvAreaImg();
            setInterval(() => {
                this.changeMvAreaImg();
            }, 13200);
            setInterval(() => {
                this.boolMove = !this.boolMove;
            }, 1100);
            this.winH = window.innerHeight;
            this.bgLogoMoveChange();
            setInterval(() => {
                this.bgLogoMoveChange();
            }, 80000);
            setTimeout(() => {
                this.bgLogoMoveChange2();
                setInterval(() => {
                    this.bgLogoMoveChange2();
                }, 80000);
            }, 40000);
        },
        components: {
            //  ToInformation,
            Slider,
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        computed: {
            isHideScrolling() {
                if (this.scrollY > window.innerHeight * 0.32) {
                    return true;
                } else {
                    return false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/sass/global.scss";
    $bg-gray: #e0e0e0;

    #mv-area {
        position: relative;
        width: 100vw;
        height: 100vh;
        opacity: 0;

        &.active {
            opacity: 1;
            transition: 2.4s 0.8s;
        }

        @include lg {
            // margin-top: 80px;
        }

        @include md {
            height: 100vh;
        }

        .bg-area {
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vw;
            background: rgb(255, 255, 255);
        }

        .img-area {
            transition: 0.3s;
            z-index: 1;

            @include lg {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            width: 100%;
            height: 100%;

            .img {
                position: relative;
                overflow: hidden;

                @include lg {
                    height: 100%;
                }

                @include md {
                    width: 100%;
                    filter: brightness(0.85) saturate(1);
                    transition: filter 1.1s;

                    &.bright {
                        filter: brightness(0.64) saturate(1.1);
                        transition: filter 1.1s;
                    }
                }
            }

            .img1 {
                .img-inner {
                    // left: 0.2vw;
                    transition: opacity 1.6s 0.3s, transform 0s 3.2s, top 0s 3.2s,
                        filter 0s 3.2s;

                    &.show {
                        // left: -0.2vw;
                        transition: opacity 1.6s 0.3s, transform 2.8s 0s, top 2.8s 0s,
                            filter 0.61s 0.09s;
                    }
                }

                @include lg {
                    width: 28vw;
                }

                @include md {
                    height: 28%;
                }
            }

            .img2 {
                .img-inner {
                    transition: opacity 1.6s 0.32s, transform 0s 3.2s, top 0s 3.2s,
                        filter 0s 3.2s;

                    &.show {
                        @include transform(scale(1.064));
                        // top: -0.1vh;
                        transition: opacity 1.6s 0.32s, transform 2.8s 0s, top 2.8s 0s,
                            filter 0.62s 0.14s;
                    }
                }

                @include lg {
                    width: 44vw;
                }

                @include md {
                    height: 41%;
                }
            }

            .img3 {
                .img-inner {
                    // left: -0.2vw;
                    transition: opacity 1.6s 0.35s, transform 0s 3.2s, top 0s 3.2s,
                        filter 0s 3.2s;

                    &.show {
                        // left: 0.2vw;
                        transition: opacity 1.6s 0.35s, transform 2.8s 0s, top 2.8s 0s,
                            filter 0.62s 0.17s;
                    }
                }

                @include lg {
                    width: 28vw;
                }

                @include md {
                    height: 31%;
                }
            }

            .img-inner {
                z-index: 1;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                @include transform(scale(1.04));
                filter: brightness(1.2) hue-rotate(360deg);

                &.show {
                    filter: brightness(1) hue-rotate(0deg);
                    @include transform(scale(1.06));
                    left: 0;
                    z-index: 2;
                    opacity: 1;
                }

                &:first-of-type {}

                &:nth-of-type(2) {}

                &:nth-of-type(3) {}
            }

            .img1-a {
                @include lg {
                    background: url("../assets/img/wp-22-01.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-22-01.jpg") no-repeat center center/300%;
                }
            }

            .img1-b {
                @include lg {
                    background: url("../assets/img/wp-23-01.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-23-01.jpg") no-repeat 75% center/140%;
                }
            }

            .img1-c {
                @include lg {
                    background: url("../assets/img/wp-24-01.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-24-01.jpg") no-repeat 55% center/160%;
                }
            }

            .img2-a {
                @include lg {
                    background: url("../assets/img/wp-25-01.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-25-01.jpg") no-repeat center center/250%;
                }
            }

            .img2-b {
                @include lg {
                    background: url("../assets/img/wp-26-03.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-26-03.jpg") no-repeat 65% 8%/230%;
                }
            }

            .img2-c {
                @include lg {
                    background: url("../assets/img/wp-27-01.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-27-01.jpg") no-repeat 70% center/220%;
                }
            }

            .img3-a {
                @include lg {
                    background: url("../assets/img/wp-31-01.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-31-01.jpg") no-repeat 40% center/230%;
                }
            }

            .img3-b {
                @include lg {
                    background: url("../assets/img/wp-25-01.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-25-01.jpg") no-repeat 55% 50%/185%;
                }
            }

            .img3-c {
                @include lg {
                    background: url("../assets/img/wp-23-03.jpg") no-repeat center center/cover;
                }

                @include md {
                    background: url("../assets/img/wp-23-03.jpg") no-repeat center center/220%;
                }
            }
        }

        .copy {
            z-index: 2;
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%, -50%));
            display: inline;
            padding-left: 1em;
            box-sizing: border-box;
            color: white;
            font-size: calc(18px + 2vw);
            letter-spacing: 0.9em;
            text-align: center;
            @include setFontSerif();
            user-select: none;
            opacity: 0;

            @include lg {
                white-space: nowrap;

                br.md {
                    display: none;
                }
            }

            @include md {
                // width: 100vw;
                opacity: 1;
                top: 49%;
                padding: 0;
                font-size: calc(18px + 1.8vw);
                text-align: left;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                letter-spacing: 0.78em;
                line-height: 1.7;
            }
        }

        &.active {
            .copy {
                opacity: 1;
                transition: 0.8s 1.2s;
            }
        }

        .scroll-down {
            z-index: 3;
            position: absolute;
            bottom: calc(0.8vw + 1.6vh);
            left: 50vw;
            @include translate(-50%, 0);
            height: 4vw;
            cursor: pointer;
            opacity: 0.85;
            transition: opacity 0.2s;
            user-select: none;
            opacity: 0;

            @include md {
                bottom: calc(12px + 5.6vh);
                height: calc(8px + 9.6vw);
                opacity: 0.9;
            }

            &.hide {
                opacity: 0;
            }

            &:hover {
                opacity: 0.95;
                transition: opacity 0.2s;

                p {
                    letter-spacing: 0.24em;
                    transition: 0.2s;
                }
            }

            p {
                color: white;
                font-size: calc(7.2px + 0.72vw);
                letter-spacing: 0.2em;
                padding-left: 0.2em;
                transition: 0.2s;

                @include md {
                    font-size: calc(8px + 0.8vw);
                }
            }

            img {
                position: absolute;
                top: 52%;
                left: 50%;
                @include translate(-50%, 0);
                width: 3.2vw;
                opacity: 0.95;
                transition: top 0.4s;

                @include md {
                    width: 8vw;
                }

                &.move {
                    top: 68%;
                    transition: top 1.1s;
                }
            }
        }

        &.active {
            .scroll-down {
                opacity: 1;
                transition: 1.6s 1.6s;
            }
        }
    }

    .home main>section {
        position: relative;
        padding: 6vw 0 9.6vw;
        width: 100vw;

        @include md {
            padding: 14.4vw 0 6.4vw;
        }

        .title-area {
            // position: relative;
            left: 0;
            width: 100vw;
            padding-bottom: 2vw;

            @include md {
                padding-bottom: 0;
            }
        }

        .list-link-router-wrap {
            z-index: 50;

            @include lg {
                top: 5.6vw;
                right: 11.6vw;

                &.active {
                    right: 11vw;
                }
            }

            @include md {
                margin-top: 9.6vw;
            }
        }

        .list-link-router {
            @include lg {
                // position: absolute;
                top: 0.96vw;
            }

            @include md {
                position: relative;
                // padding: calc(8px + 3.2vw) 4vw;

                // left: 50%;
                // @include translate(-50%, 0);
            }
        }

        .list-link {
            @include lg {
                top: 0;
            }
        }
    }

    #news-link-area {
        background: $bg-gray;

        @include lg {
            padding-bottom: 8vw;
        }

        @include md {
            // padding-bottom: 4vw;
        }

        .bg-logo {
            z-index: 0;
            position: absolute;
            top: 50%;
            left: 0.8vw;
            @include translate(0, -50%);
            height: 100%;

            // opacity: 0;
            &.active {
                opacity: 1;
                transition: opcity 0.8s 0.2s;
            }

            @include lg {
                left: 100vw;
                transition: left 0s, opcity 0.8s 0.2s;

                &.move {
                    left: -50vw;
                    transition: left 80s linear, opcity 0.8s 0.2s;
                }
            }

            @include md {
                top: 48%;
                left: -9.7vw;
                height: 47%;

                &:nth-of-type(2) {
                    display: none;
                }
            }
        }

        .title-area {
            z-index: 5;
            position: relative;
        }

        .link-content-area {
            z-index: 5;
            opacity: 0;
            @include translate(0, 0.96vw);

            &.active {
                opacity: 1;
                @include translate(0, 0);
                transition: 1.2s 0.32s;
            }

            position: relative;
            left: $lg-mar-lr;
            width: $lg-width;

            @include lg {
                margin-top: 0.8vw;
                display: flex;
                justify-content: space-between;
            }

            @include md {
                margin-top: 6.4vw;
                margin-bottom: 4vw;
            }

            .link-content {
                position: relative;
                width: calc(#{$lg-width} / 3 - 2.8vw / 3);
                height: calc(32px + 7.2vw);
                background: white;
                display: flex;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

                @include lg {
                    transition: 0.3s;

                    &:hover {
                        @include transform(scale(1.02));
                        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
                    }
                }

                @include md {
                    margin-top: 4vw;
                    width: 100%;
                    height: calc(64px + 12.8vw);
                }

                // img {
                //     width: 38%;
                // }
                .img-area {
                    width: calc(32px + 7.2vw);
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    @include md {
                        width: calc(64px + 12.8vw);
                    }
                }

                .text-area {
                    margin-left: 0.7em;
                    padding-right: 0.7em;
                    box-sizing: border-box;
                    width: 62%;
                    color: #333;

                    p {
                        line-height: 1.6;

                        &:first-of-type {
                            font-weight: normal;
                            padding: 1.08vw 0 0.64vw;
                            font-size: calc(1.5px + 0.77vw);

                            @include md {
                                font-size: calc(8px + 0.8vw);
                                padding: 4vw 0 1.6vw;
                            }
                        }

                        span {
                            padding: 0 0.16em;
                        }
                    }
                }

                h4 {
                    margin: 0;
                    font-size: calc(2px + 0.85vw);
                    font-weight: normal;

                    @include md {
                        letter-spacing: normal;
                        font-size: calc(9px + 0.9vw);
                    }
                }
            }
        }
    }

    #works-link-area {
        @include lg {
            padding-bottom: 0.8vw;
        }

        @include md {
            // margin-bottom: 4vw;
        }

        .title-area {
            @include md {
                margin-bottom: 2.4vw;
            }
        }

        .img-area {
            position: relative;
            width: 100vw;

            &.active {
                .flex-area {
                    .img-wrap {
                        filter: brightness(1);
                        opacity: 1;
                    }

                    .img-wrap1 {
                        transition: filter 1.2s 0.2s opacity 0.2s 0s;
                    }

                    .img-wrap2 {
                        transition: filter 1.2s 0.5s opacity 0.2s 0.2s;
                    }

                    .img-wrap3 {
                        transition: filter 1.2s 0.8s opacity 0.2s 0.4s;
                    }

                    .img-wrap4 {
                        transition: filter 1.2s 1.1s opacity 0.2s 0.6s;
                    }

                    .img-wrap5 {
                        transition: filter 1.2s 1.4s opacity 0.2s 0.8s;
                    }

                    .img-wrap6 {
                        transition: filter 1.2s 1.7s opacity 0.2s 1s;
                    }
                }
            }

            @include lg {
                height: 52vw;
            }

            @include md {
                margin: 6.4vw 0 0vw;
                display: flex;
            }

            .flex-area {
                position: relative;

                @include lg {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                }

                width: 100%;
                height: 100%;

                .router {
                    position: relative;
                    display: block;
                    transition: 0.3s;

                    .text-area {
                        position: absolute;

                        @include lg {
                            top: 50%;
                            left: 50%;
                            @include translate(-50%, -50%);
                        }

                        opacity: 0;
                        transition: 0.3s;

                        @include md {
                            right: 1.2vw;
                            bottom: 1.2vw;
                            opacity: 1;
                        }
                    }

                    p,
                    h4 {
                        position: relative;
                        color: white;
                        user-select: none;
                        text-align: center;
                    }

                    .title {
                        font-weight: normal;
                        margin: 0;
                        margin-bottom: 2vw;
                        font-size: calc(7px + 0.7vw);

                        @include md {
                            margin: 0;
                            white-space: nowrap;
                            padding: 1.2vw 2vw;
                            font-size: calc(8.4px + 0.84vw);
                            letter-spacing: 0.1em;
                            // color: black;
                            background: #00000066;
                        }
                    }

                    .show-detail {
                        width: 18vw;
                        padding: 1.04vw 0;
                        border: 1px white solid;
                        background: #ffffff00;
                        margin-left: 50%;
                        font-size: calc(6px + 0.6vw);
                        @include translate(-50%, 0);

                        @include md {
                            display: none;
                        }

                        &:hover {
                            background: #{$color-green}99;
                            filter: brightness(1.15) saturate(1);
                            // color: black;
                            border: #ffffff00 1px solid;
                            transition: all 0.3s, color 0.3s;
                        }
                    }

                    &:hover {
                        .img {
                            filter: brightness(0.3);
                            @include transform(scale(1.04));
                            transition: 0.3s;
                        }

                        .text-area {
                            opacity: 1;
                            transition: opacity 0.3s;
                        }

                        .show-detail {}
                    }
                }

                .img-wrap {
                    overflow: hidden;
                    height: 100%;
                    filter: brightness(5);
                    opacity: 0;
                    transition: 0.64s 0.4s;
                }

                .img {
                    width: 100%;
                    height: 100%;
                    @include transform(scale(1.01));
                    transition: 0.3s;

                    @include md {
                        // height: 42vw;
                    }
                }

                &:first-of-type {
                    @include lg {
                        height: 60%;

                        .img-wrap1 {
                            width: 32vw;
                        }

                        .img-wrap2 {
                            width: 24vw;
                        }

                        .img-wrap3 {
                            width: 44vw;
                        }
                    }

                    @include md {
                        width: 50%;

                        .img-wrap1 {
                            height: 43vw;
                        }

                        .img-wrap2 {
                            height: 43vw;
                        }

                        .img-wrap3 {
                            height: 53vw;
                        }
                    }

                    .img1 {
                        background: url("../assets/img/wp-22-01.jpg") no-repeat center center/cover;

                        @include md {
                            background: url("../assets/img/wp-22-01.jpg") no-repeat center center/200%;
                        }
                    }

                    .img2 {
                        background: url("../assets/img/wp-23-01.jpg") no-repeat center center/cover;

                        @include md {
                            background: url("../assets/img/wp-23-01.jpg") no-repeat center center/200%;
                        }
                    }

                    .img3 {
                        background: url("../assets/img/wp-24-01.jpg") no-repeat center center/auto 144%;

                        @include md {
                            background: url("../assets/img/wp-24-01.jpg") no-repeat center center/auto 200%;
                        }
                    }
                }

                &:nth-of-type(2) {
                    @include lg {
                        height: 40%;

                        .img-wrap4 {
                            width: 32vw;
                        }

                        .img-wrap5 {
                            width: 32vw;
                        }

                        .img-wrap6 {
                            width: 36vw;
                        }
                    }

                    @include md {
                        width: 50%;

                        .img-wrap4 {
                            height: 43vw;
                        }

                        .img-wrap5 {
                            height: 51vw;
                        }

                        .img-wrap6 {
                            height: 45vw;
                        }
                    }

                    .img4 {
                        background: url("../assets/img/wp-25-01.jpg") no-repeat center center/196% auto;

                        @include md {
                            background: url("../assets/img/wp-25-01.jpg") no-repeat center center/296% auto;
                        }
                    }

                    .img5 {
                        background: url("../assets/img/wp-26-01.jpg") no-repeat center center/cover;

                        @include md {
                            background: url("../assets/img/wp-26-01.jpg") no-repeat center center/200%;
                        }
                    }

                    .img6 {
                        background: url("../assets/img/wp-27-01.jpg") no-repeat center center/344% auto;

                        @include md {
                            background: url("../assets/img/wp-27-01.jpg") no-repeat center center/344% auto;
                        }
                    }
                }
            }
        }
    }

    #product-link-area {
        @include lg {
            margin-top: 2.4vw;
            padding-bottom: 8vw;
        }

        @include md {
            // padding-bottom: 4vw;
            background: #f2f2f2;
        }

        .title-area {
            @include md {
                margin-bottom: 2.4vw;
            }
        }

        .product-slider {
            filter: saturate(0) brightness(0);
            opacity: 0;

            &.active {
                filter: saturate(1) brightness(1);
                opacity: 1;
                transition: filter 0.6s 0.2s, opacity 0.4s 0.4s;
            }

            @include md {
                margin: 6.4vw 0 8vw;
            }
        }
    }

    #service-link-area {
        padding-bottom: 6.4vw;
        color: #222;

        .list-area {
            padding: 0;
            margin: 0;
            margin-top: 0.8vw;
            margin-left: $lg-mar-lr;

            @include md {
                padding-bottom: 3.2vw;
                margin-top: 6.4vw;
                margin-left: 0vw;
            }

            .list {
                list-style: none;
                position: relative;
                width: $lg-width;
                margin-top: 1.6vw;
                background: #f2f2f2;

                @include md {
                    width: 100vw;
                    margin-top: 2.4vw;
                }

                &:first-of-type {
                    margin-top: 0;
                }

                img {
                    position: relative;
                    margin-left: 4%;

                    @include lg {
                        margin-left: 8%;
                        margin-top: 2vw;
                        padding-bottom: 2vw;
                        width: 32%;
                    }

                    @include md {
                        display: block;
                        margin: 0 auto;
                        padding-top: 2.8vw;
                        width: 46%;
                    }
                }

                .description-area {
                    @include lg {
                        position: absolute;
                        top: 50%;
                        left: 46.5%;
                        @include translate(0, -50%);
                        width: 45%;
                    }

                    @include md {
                        margin: 2vw auto;
                        width: 88%;
                    }

                    h4 {
                        margin: 0 0 0.2em;
                        font-weight: 500;
                        font-size: calc(8.4px + 0.84vw);
                        letter-spacing: 0.04em;

                        span {
                            font-size: calc(8.8px + 0.88vw);
                            line-height: 1.6;
                            padding-left: 0.2em;
                            padding-right: 0.48em;
                        }

                        @include md {
                            font-size: calc(10px + 1vw);

                            span {
                                line-height: 1.2;
                                font-size: calc(11px + 1.1vw);
                                padding-left: 0.08em;
                                padding-right: 0.4em;
                            }
                        }
                    }

                    p {
                        // font-weight: bold;
                        text-align: left;
                        margin-top: 1em;
                        font-size: calc(6.8px + 0.68vw);
                        line-height: 1.9;

                        @include md {
                            margin-top: 1.1em;
                            padding-bottom: 7.2vw;
                            font-size: calc(9px + 0.9vw);
                        }
                    }
                }
            }
        }

        >p {
            position: relative;
            display: inline-block;
            margin-top: 6.4vw;
            margin-left: 50vw;
            @include translate(-50%, 0);
            padding: 0 0.2em 0.4em;
            border-bottom: 1px $color-yellow-dark solid;
            font-size: calc(8.4px + 0.84vw);
            font-weight: 500;
            letter-spacing: 0.1em;

            @include lg {
                white-space: nowrap;

                br.md {
                    display: none;
                }
            }

            @include md {
                font-size: calc(9.6px + 0.96vw);
                white-space: nowrap;
                margin-bottom: 3.6vw;
            }
        }
    }

    #contact-link-area {
        background: $bg-gray;
        margin-bottom: 4.8vw;
        padding-bottom: 8.4vw;

        @include md {
            padding-bottom: 32vw;
            margin-bottom: 9.6vw;
        }

        .form-link-area {
            position: relative;
            height: calc(14px + 1.4vw);

            .form-link {
                left: 50vw;

                @include md {
                    margin: 4.8vw 0;
                    // padding: 4vw 9.6vw;
                }

                @include translate(-50%, 24%);

                p {
                    font-size: calc(7.2px + 0.72vw);

                    @include md {
                        font-size: calc(11.7px + 1.17vw);
                    }
                }

                @include lg {
                    transition: 0.3s;

                    &:hover {
                        background: #{$color-green}bb;
                        border: #{$color-green}bb 1px solid;
                        filter: brightness(1.15) saturate(1.05);
                        box-shadow: 0px 2px 16px rgba(33, 150, 83, 0.28);
                        transition: all 0.3s, box-shadow 0.3s;
                    }
                }
            }
        }
    }
</style>
