<template>
    <div class="spec-table">
        <div class="table-area">
            <h3>砲弾型</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>電圧</th>
                    <th>電流値/pcs</th>
                    <th>消費電力/pcs</th>
                    <th>ビット数</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-HDS-B-2903</td>
                    <td>DC12V</td>
                    <td>0.06A</td>
                    <td>0.72W</td>
                    <td>8bit</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>

        <div class="table-area">
            <h3>スクエア型</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>電圧</th>
                    <th>電流値/pcs</th>
                    <th>消費電力/pcs</th>
                    <th>ビット数</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-HDS-S-2903</td>
                    <td>DC12V</td>
                    <td>0.06A</td>
                    <td>0.72W</td>
                    <td>8bit</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
        th {
            // &:nth-of-type(1) {
            //     width: 24%;
            // }
            // &:nth-of-type(2) {
            //     width: 24%;
            // }
            // &:nth-of-type(3) {
            //     width: 12%;
            // }
            // &:nth-of-type(4) {
            //     width: 8%;
            // }
            // &:nth-of-type(5) {
            //     width: 10%;
            // }
            // &:nth-of-type(6) {
            //     width: 12%;
            // }
            // &:nth-of-type(7) {
            //     width: 10%;
            // }
        }
}

</style>
