<template>
  <div class="information-page">
    <div id="page-head">
      <div class="bg-img"></div>
      <div class="cover"></div>
      <h1>
        <p>プライバシーポリシー</p>
        <p>PRIVACY POLICY</p>
      </h1>
    </div>

    <main ref="main">
      <section class="pp-area">
        <p>トーエーテクノインターナショナル株式会社（以下、「当社」といいます。）は、当社におけるユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。</p>

        <h2>1条（定義）</h2>
        <ol>
          <li>本ポリシーにおいて「個人情報」とは、個人情報の保護に関する法律（平成15年法律第57号、以下「個人情報保護法」 といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、 当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。</li>
          <li>本ポリシーにおいて「個人データ」とは、データベース化された個人情報を指します。</li>
        </ol>

        <h2>2条（個人情報の収集方法）</h2>
        <p>当社は、ユーザーがお問い合わせをする際に氏名、生年月日、住所、電話番号、メールアドレスなどの個人情報を入力していただくことがあります。</p>

        <h2>3条（個人情報を収集・利用する目的）</h2>
        <p>当社が個人情報を収集・利用する目的は、以下のとおりです。</p>
        <p>お問い合わせに対する回答や必要な情報を電話及び電子メールなどでご連絡する目的</p>


        <h2>4条（利用目的の変更）</h2>
        <ol>
          <li>当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。</li>
          <li>利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。</li>
        </ol>

        <h2>5条（個人情報の第三者提供）</h2>
        <ol>
          <li>当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報及び個人データを提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
            <ol>
              <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
              <li>予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき用目的に第三者への提供を含むこと
                <ol>
                  <li>利用目的に第三者への提供を含むこと</li>
                  <li>第三者に提供される個人データの項目</li>
                  <li>第三者への提供の手段または方法</li>
                  <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
                  <li>本人の求めを受け付ける方法</li>
                  <li>前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
            <ol>
              <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
              <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
              <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合</li>
            </ol>
          </li>
        </ol>

        <h2>6条（個人情報の開示）</h2>
        <ol>
          <li>当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1、000円の手数料を申し受けます。
            <ol>
              <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
              <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
              <li>その他法令に違反することとなる場合</li>
            </ol>
          </li>
          <li>前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</li>
        </ol>

        <h2>7条（個人情報の訂正および削除）</h2>
        <p>ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。</p>
        <p>当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。</p>
        <p>当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。</p>

        <h2>8条（個人情報の利用停止等）</h2>
        <ol>
          <li>当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。</li>
          <li>前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。</li>
          <li>当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。</li>
          <li>前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。</li>
        </ol>

        <h2>9条 (アクセス解析ツールの使用)</h2>
        <p>サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>

        <h2>10条（暗号化通信（SSL）について）</h2>
        <p>サイトでは、SSL（暗号化通信）を採用しております。 SSLはwebサイトとwebサーバーの間で情報を暗号化し送受信できる通信方法です。</p>
        <p>キュリティ機能に対応したブラウザを使用することで、お客様が入力される氏名や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されるため、万が一送受信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。</p>

        <h2>11条（プライバシーポリシーの変更）</h2>
        <ol>
          <li>1. ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。</li>
          <li>2. 当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。</li>
        </ol>

        <h2>12条（お問い合わせ及びご連絡窓口）</h2>
        <p>ご意見、ご質問、苦情のお申出、その他の個人情報の取扱いに関するお問い合わせ、6条、第7条、第8条などについてのご連絡は、下記の窓口までお願いいたします。</p>

        <p>住所：〒105-0013 東京都港区浜松町1-6-2 丸神ビル5F</p>
        <p>社名：トーエーテクノインターナショナル株式会社</p>
        <p>電話番号：03-6450-1486</p>
        <p>FAX番号：03-6450-1487</p>

        <h2>13条（免責事項）</h2>
        <ol>
          <li>本サイトに掲載されている情報の正確さには万全を期していますが、利用者が当社のWebサイトの情報を用いて行う一切の行為に関して、当社は一切の責任を負わないものとします。</li>
          <li>当社は、利用者が本サイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。</li>
        </ol>

        <h2>14条（著作権・肖像権）</h2>
        <p>当サイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されており、無断での使用や転用は禁止されています。</p>

        <p style="text-align:right; padding-top:2vw;">以上</p>

      </section>
    </main>
  </div>
</template>

<script>


export default {
  data: () => {
    return {
      // google: null,
      // mapConfig: {
      //     center: {
      //     lat: 35.68944,
      //     lng: 139.69167
      //     },
      //     zoom: 17
      // }
      // map:null
    };
  },
  // mounted() {
  //     L.map( 'map', { center: L.latLng( 35.659063, 139.757602 ), zoom: 15 } ).addLayer(
  //         L.tileLayer( 'http://{s}.tile.osm.org/{z}/{x}/{y}.png' )
  //     )
  // },
  // mounted() {
  //     const map = L.map( 'app', { center: L.latLng( 35.659063, 139.757602 ), zoom: 15 } ).addLayer(
  //         L.tileLayer( 'http://{s}.tile.osm.org/{z}/{x}/{y}.png' )
  //     ).on(
  //         'click'
  //     ,   p => map.addLayer( L.marker( p.latlng ) )
  //     )
  // },
  // async mounted() {
  //     this.google = await GoogleMapsApiLoader({
  //     apiKey: 'API_KEY'
  //     });
  //     this.initializeMap();
  // },
  // methods: {
  //     initializeMap() {
  //     new this.google.maps.Map(this.$refs.googleMap, this.mapConfig);
  //     }
  // },

  // mounted() {
  //     if (navigator.geolocation) {
  //     // callback関数内でthis使えないため
  //     let vm = this
  //     navigator.geolocation.getCurrentPosition(
  //         function(position){
  //         let latlng = new window.google.maps.LatLng(
  //             position.coords.latitude,
  //             position.coords.longitude
  //         );
  //         vm.map = new window.google.maps.Map(vm.$refs["map"], {
  //             center: latlng,
  //             zoom: 4
  //         })
  //         new window.google.maps.Marker({
  //             position: latlng,
  //             map: vm.map
  //         })
  //         }
  //     )
  //     }
  // },
  components: {
    // PageHead
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

#page-head {
  .bg-img {
    background: url("../assets/img/cp-01.jpg") no-repeat center center/cover;
  }
  .cover {
    background: #ffffff28;
  }
}

h1 p {
  @include lg {
    br.md {
      display: none;
    }
  }
  @include md {
    &:first-of-type {
      font-size: calc(11.6px + 1.16vw);
      line-height: 1.2;
    }
    &:nth-of-type(2) {
      margin-top: 0.2em;
      font-size: calc(5.4px + 0.54vw);
    }
  }
}

.pp-area {
  width: 50vw;
  margin: 6.4vw auto;
  @include md {
    width: 80vw;
    margin: 10vw auto;
  }
  img {
    display: block;
    width: 6.8vw;
    margin: 0 auto;
    @include md {
      width: 12vw;
    }
  }
  h1 {
    margin: 2.4vw 0 3.6vw;
    p {
      width: 100%;
    }
  }
  h2 {
    margin: 2.4vw 0 1.6vw;
  }
  ol {
    padding-top: 1.0vw;
     li {
        font-size:calc(6.8px + 0.68vw);
        margin-bottom: 0.6vw;
     }
  }
  > p {
    font-size: calc(6.8px + 0.68vw);
    text-align: left;
    letter-spacing: 0.024em;
    line-height: 1.96;
    @include md {
      letter-spacing: 0.04em;
      margin-top: 6.4vw;
      font-size: calc(9.4px + 0.94vw);
    }
  }
}

.description-area {
  margin: 9.6vw 0;
  @include md {
    margin: 14.4vw 0;
  }
  h1 {
    margin: 0 0 5.6vw;
  }
  ul {
    padding: 0;
  }
  .description {
    list-style: none;
    position: relative;
    margin: 4vw 0;
    width: 100vw;
    height: 28vw;
    @include md {
      margin: 4vw 0vw;
      width: 100vw;
      height: 52vw;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .img-area {
      position: absolute;
      width: 48vw;
      height: 24vw;
      @include md {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
    .text-area-wrap {
      position: absolute;
      width: 40vw;
      height: 20vw;
      box-sizing: border-box;
      @include lg {
        top: 2vw;
        padding: 0 3.2vw;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background: #f2f2f2;
      }
      @include md {
        position: absolute;
        top: 0;
        padding: 2vw 6vw;
        width: 100%;
        height: 100%;
        color: white;
        background: #3d3d3dbe;
      }
      .text-area {
        position: relative;
        top: 50%;
        @include translate(0, -50%);
      }
      h2 {
        font-weight: 500;
        font-size: calc(8px + 0.8vw);
        letter-spacing: 0.2em;
        @include md {
          font-size: calc(9.8px + 0.98vw);
          letter-spacing: 0.096em;
        }
      }
      p {
        margin-top: 0.96vw;
        font-size: calc(6.4px + 0.64vw);
        line-height: 1.88;
        text-align: left;
        @include md {
          letter-spacing: normal;
          margin-top: 3.2vw;
          font-size: calc(8.6px + 0.86vw);
        }
      }
    }
    @include lg {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        .img-area {
          right: 0;
        }
        .text-area-wrap {
          right: 36vw;
        }
      }
      &:nth-of-type(2) {
        .img-area {
          left: 0;
        }
        .text-area-wrap {
          left: 36vw;
        }
      }
    }
    &:nth-of-type(1) {
      margin-top: 3.2vw;
      .img-area {
        background: url("../assets/img/cp-03.jpg") no-repeat center center/cover;
      }
    }
    &:nth-of-type(2) {
      .img-area {
        background: url("../assets/img/cp-04.jpg") no-repeat center center/cover;
      }
    }
    &:nth-of-type(3) {
      .img-area {
        background: url("../assets/img/cp-05.jpg") no-repeat center center/cover;
      }
    }
  }
}

.about-area2 {
  margin: 8.8vw 0;
  @include md {
    margin: 14.4vw 0;
  }
  h1 {
    margin: 4vw 0;
  }
  table {
    margin-top: 1.6vw;
    margin-left: $lg-mar-lr;
    width: calc(100vw - #{$lg-mar-lr}* 2);
    border-collapse: separate;
    letter-spacing: 0.08em;
    border-spacing: 0px 0.12vw;
    @include md {
      margin-top: 0;
      &:first-of-type {
        margin-top: 6vw;
      }
      margin-left: 4vw;
      width: 92vw;
      border-spacing: 0px 2.4vw;
      border-collapse: separate;
    }
    th {
      width: 20%;
      padding: 2.4vw 1.2vw;
      background: #000;
      color: white;
      font-weight: normal;
      font-size: calc(6.4px + 0.64vw);
      text-align: left;
      @include md {
        // width: 24%;
        padding: 1.6vw 2.4vw;
        font-size: calc(9px + 0.9vw);
        font-weight: 500;
      }
    }
    td {
      width: 80%;
      padding-left: 1.2vw;
      font-size: calc(7.2px + 0.72vw);
      line-height: 1.6;
      border: #e0e0e0 1px solid;
      @include md {
        letter-spacing: normal;
        font-size: calc(10px + 1vw);
        padding: 2.4vw 2.4vw;
        border: none;
        background: #f2f2f2;
      }
      a {
        display: inline-block;
        margin: 0 0 0.8vw;
        padding: 0.64vw 0.2vw 0.4vw;
        span {
          opacity: 0.7;
          transition: 0.3s;
        }
        &:hover {
          span {
            color: $color-green;
            transition: 0.3s;
          }
        }
      }
    }
    th,
    td {
      @include md {
        display: block;
        width: 100%;
        box-sizing: border-box;
      }
    }
    .gp td {
      padding: 1.6vw 1.2vw;
      @include md {
        padding: 4vw 2.4vw;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      @include lg {
        td {
          position: relative;
          width: unset;
          display: flex;
          p {
            margin-top: calc(60px + 6vw);
            @include translate(0, -50%);
            width: 44%;
          }
          .map {
            position: relative;
            width: 56%;
            height: calc(140px + 14vw);
          }
        }
      }
      @include md {
        td {
          padding: 0;
          p {
            padding: 4vw 2.4vw;
          }
          .map {
            height: 48vw;
          }
        }
      }
    }
  }
}
</style>
