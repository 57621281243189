var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide-rel-work",staticStyle:{"position":"relative"}},[_c('hooper',{staticClass:"hooper__hooper03",attrs:{"settings":_vm.hooperSettings}},[_vm._l((_vm.idList),function(workId){return _c('slide',{key:workId.id,staticClass:"content-wrap"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"img-wrap"},[_c('div',{staticClass:"img",style:({
              'background-image':
                'url(' +
                require('@/assets/img/wp-' + _vm.works[workId].id + '-01.jpg') +
                ')',
            })})]),_c('div',{staticClass:"text-area"},[_c('p',[_vm._v(_vm._s(_vm.works[workId].name))]),(_vm.works[workId].id != '00')?_c('router-link',{staticClass:"show-detail",attrs:{"to":{ name: 'works-detail', params: { id: Number(workId) } }}},[_vm._v("詳細はこちら ")]):_vm._e()],1)])])}),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"}),_c('hooper-navigation',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2),_vm._m(0),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-mark"},[_c('img',{attrs:{"src":require("../assets/img/mk-03.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-mark"},[_c('img',{attrs:{"src":require("../assets/img/mk-04.svg"),"alt":""}})])
}]

export { render, staticRenderFns }