<template>
  <div class="products-detail-content">
    <h2>カラーバリエーション例</h2>
    <div class="list-wrap">
      <!-- 02 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-09-' + '02' + '.jpg') + ')',
          }"
        ></div>
        <h3></h3>
        <p class="hasHeight"></p>
      </div>
      <!-- 03 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-09-' + '03' + '.jpg') + ')',
          }"
        ></div>
        <h3></h3>
        <p class="hasHeight"></p>
      </div>
      <!-- 04 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-09-' + '04' + '.jpg') + ')',
          }"
        ></div>
        <h3></h3>
        <p class="hasHeight"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.products-detail-content {
  .hasHeight {
    // height: 2em;
  }
}
</style>
