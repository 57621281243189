<template>
    <div class="spec-table">
        <div class="table-area">
            <h3>1チップ制御タイプ</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>発光色</th>
                    <th>ICチップ</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-WS2812B-I-1C</td>
                    <td>1chip制御</td>
                    <td>WS2812B</td>
                    <td>60pcs</td>
                    <td>5V</td>
                    <td>3.2A</td>
                    <td>16W</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>

        <div class="table-area">
            <h3>3チップ制御タイプ</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>発光色</th>
                    <th>ICチップ</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-CLWS2811-O-3C</td>
                    <td>3chip制御</td>
                    <td>WS2812</td>
                    <td>30pcs</td>
                    <td>12V</td>
                    <td>0.72A</td>
                    <td>8.6W</td>
                    <td>IP65</td>
                </tr>
                <tr>
                    <td>FL-CLUCS1903S-60-3C</td>
                    <td>3chip制御</td>
                    <td>UCS1903S</td>
                    <td>60pcs</td>
                    <td>12V</td>
                    <td>1.2A</td>
                    <td>14.4W</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>

        <div class="table-area">
            <h3>6チップ制御タイプ</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>発光色</th>
                    <th>ICチップ</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-CLUCS2903-O-6C</td>
                    <td>6chip制御</td>
                    <td>UCS2903</td>
                    <td>60pcs</td>
                    <td>24V</td>
                    <td>0.6A</td>
                    <td>14.4W</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
        th {
            &:nth-of-type(1) {
                width: 20%;
            }
            &:nth-of-type(2) {
                width: 12%;
            }
            &:nth-of-type(3) {
                width: 12%;
            }
            &:nth-of-type(4) {
                width: 12%;
            }
            &:nth-of-type(5) {
                width: 7%;
            }
            &:nth-of-type(6) {
                width: 11%;
            }
            &:nth-of-type(7) {
                width: 14%;
            }
            &:nth-of-type(8) {
                width: 12%;
            }
        }
}

</style>
