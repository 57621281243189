<template>
    <header :class="{ show: ActiveBtn }">
        <div class="hamburger_btn" @click='ActiveBtn=!ActiveBtn' v-show="winMd">
            <span class="line line_01" :class="{ 'btn_line01': ActiveBtn }"></span>
            <span class="line line_02" :class="{ 'btn_line02': ActiveBtn }"></span>
            <span class="line line_03" :class="{ 'btn_line03': ActiveBtn }"></span>
        </div>

        <div class="header-md-bg-b" v-show="ActiveBtn" />
        <div class="header-md-bg" />

        <!-- <div> -->
        <router-link to='/' class="logo-area logo-area-md" @click="activeFalse">
            <img src="../assets/logo.png" alt="" class="logo">
        </router-link>
        <!-- </div> -->

        <transition name="menu">
            <nav v-show="ActiveBtn" @click="activeFalse" v-scroll-lock="ActiveBtn">
                <router-link to='/' class="logo-area logo-area-lg">
                    <img src="../assets/logo.png" alt="" class="logo">
                </router-link>

                <div class="link-list">
                    <router-link to="/products" active-class="link--active" exact class="link">
                        <p>製品</p>
                    </router-link>

                    <router-link to="/works" active-class="link--active" exact class="link">
                        <p>納入事例</p>
                    </router-link>

                    <router-link to="/information" active-class="link--active" exact class="link">
                        <p>企業情報</p>
                    </router-link>

                    <router-link to="/download" active-class="link--active" class="link">
                        <p>ダウンロード</p>
                    </router-link>
                </div>

                <!-- <router-link
              to="/contact"
              exact
              class="form-link"
          >
            <img src="../assets/img/mk-01.svg" alt="">
            <p>お問い合わせ</p>
          </router-link> -->
                <a href="https://form.run/@finelinks-inc-1621391070" target="_blank" rel="noopener noreferrer"
                    class="form-link">
                    <img src="../assets/img/mk-01.svg" alt="">
                    <p>お問い合わせ</p>
                </a>

            </nav>
        </transition>

    </header>
</template>

<script>
    // import Products from "./Products";
    export default {
        components: {
            //  Products
        },
        data: () => {
            return {
                ActiveBtn: false,
                winMd: true,
                open: false
            }
        },
        created() {
            if (window.innerWidth > 899) {
                this.ActiveBtn = true;
                this.winMd = false;
            }
        },
        methods: {
            activeFalse() {
                if (window.innerWidth <= 899) {
                    this.ActiveBtn = false;
                    this.winMd = true;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/sass/global.scss";
</style>
