<template>
    <div class="products-detail-content">

        <h2>製品一覧</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-06-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    品番：FL-XC-CL3P-RGB
                </h3>
                <p>

                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-06-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    品番：FL-XC-CL4P-RGB
                </h3>
                <p>

                </p>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
