<template>
  <!-- 8 デジタルストリングライト -->
  <div>
    <div class="table-area">
      <h3>砲弾型</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-HDS-B-2903</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            <a
              href="../specifications/FL-HDS-B-2903.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>スクエア型</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-HDS-S-2903</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            <a
              href="../specifications/FL-HDS-S-2903.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
