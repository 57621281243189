<template>
    <div class="products-detail-content">

        <h2>制御タイプ一覧</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-03-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    １チップ制御タイプ
                </h3>
                <p>
                    LED一つにつきICチップが搭載されており、1つずつ色の制御が可能です。複数列並べることにより文字や映像を流したりすることに適しています。
                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-03-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    ３チップ制御タイプ
                </h3>
                <p>
                    ICチップが3つのLEDを1つのLEDとして認識して制御するので細かい映像等や文字を流すのには不向きですが、コントローラーには制御限度数がありますので、長い距離を演出したい且つ抽象的なグラデーション等で流れを表現したい場面に適しています。
                </p>
            </div>
            <!-- 04 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-03-'+ '04' + '.jpg') + ')'}"></div>
                <h3>
                    ６チップ制御タイプ
                </h3>
                <p>
                    3チップ制御タイプよりも制御数が倍の数になり、24V仕様も併せてさらに長い距離を演出することができます。
                </p>
            </div>
        </div>

        <h2>専用オプション品</h2>
        <div class="list-wrap">
            <!-- 05 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-03-'+ '05' + '.jpg') + ')'}"></div>
                <h3>
                    DMX to SPIデコーダー
                </h3>
                <p>

                </p>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 14 }}"
                    class="link"
                >
                詳細はこちら
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
