<template>
  <!-- 14 調光用コントローラー -->
  <div>
    <div class="table-area">
      <h3>記憶型リモコン式調光器</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-Z100</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-Z100.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>リモコン式調光器</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-RMDA</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-RMDA.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>リモコン式RGB調光制御器</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-T3600RF</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-T3600RF.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>DMX to RGBデコーダー</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-T853-6A</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-T853-6A.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>RGB信号増幅機</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-RGBAP30A</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-RGBAP30A.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>DMX to SPIデコーダー</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-C820</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-C820.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
