<template>
  <div class="products-page">
    <!-- <div class="title-area">
            <h1>
                <p>主な取扱製品</p>
                <p>PRODUCT</p>
            </h1> -->

    <div id="page-head">
      <div class="bg-img"></div>
      <div class="cover"></div>
      <h1>
        <p>主な取扱製品</p>
        <p>PRODUCT</p>
      </h1>
    </div>

    <main>
      <h2>LED製品</h2>
      <div class="link-product-area">
        <div v-for="n in 12" :key="n.id">
          <router-link
            :to="{ name: 'products-detail', params: { id: n } }"
            class="link-product"
          >
            <div class="img-area-wrap">
              <div
                class="img-area"
                :style="{
                  'background-image':
                    'url(' +
                    require('@/assets/img/ppt-' + products[n].id + '.jpg') +
                    ')',
                }"
              ></div>
            </div>
            <div class="text-area">
              <p>{{ products[n].name }}</p>
              <p class="type" v-if="products[n].name_type != ''">
                {{ products[n].name_type }}
              </p>
              <p class="series">{{ products[n].series }}</p>
            </div>
          </router-link>
        </div>
      </div>

      <h2>オプション品</h2>
      <div class="link-product-area">
        <div v-for="n in 3" :key="n.id">
          <router-link
            :to="{ name: 'products-detail', params: { id: n + 12 } }"
            class="link-product"
          >
            <div class="img-area-wrap">
              <div
                class="img-area no-light"
                :style="{
                  'background-image':
                    'url(' +
                    require('@/assets/img/ppt-' +
                      products[n + 12].id +
                      '.jpg') +
                    ')',
                }"
              ></div>
            </div>
            <div class="text-area">
              <p>{{ products[n + 12].name }}</p>
              <p class="type" v-if="products[n + 12].name_type != ''">
                {{ products[n + 12].name_type }}
              </p>
              <p class="series">{{ products[n + 12].series }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </main>

    <!-- </div> -->
  </div>
</template>

<script>
import products from "../assets/json/products.json";
export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
#page-head {
  .cover {
    background: #f2f2f2;
  }
  h1 p {
    color: black;
  }
}
.products-page {
  @include lg {
    // padding: calc(80px + 4.8vw) 0 3.2vw;
  }
  @include md {
    // padding: calc(24px + 20vw) 0 9.6vw;
  }
  background: #f2f2f2;
}
h1 {
  margin-bottom: 2.4vw;
}
h2 {
  @include lg {
    margin-left: $lg-mar-lr;
    width: calc(100vw - #{$lg-mar-lr} * 2 - 4vw);
    font-size: calc(8.8px + 0.88vw);
    padding: 0.8vw 2vw;
  }
  @include md {
    margin-left: 4vw;
    width: 92vw;
    padding: 1.6vw 3.2vw;
    font-size: calc(10px + 1vw);
    box-sizing: border-box;
  }
  background: black;
  color: white;
  font-weight: normal;
  letter-spacing: 0.2em;
}
main {
  // margin-top: 4.8vw;
  @include md {
    // margin-top: 9.6vw;
  }
}

.link-product-area {
  @include lg {
    margin: 2.8vw $lg-mar-lr 0vw;
    padding-bottom: 8vw;
  }
  @include md {
    margin: 4vw 4vw 0;
    padding-bottom: 6.4vw;
  }
}
</style>
