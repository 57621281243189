<template>
    <div class="information-page">
        <div id="page-head">
            <div class="bg-img"></div>
            <div class="cover"></div>
            <h1>
                <p>企業情報</p>
                <p>COMPANY</p>
            </h1>
        </div>
        <main ref="main">
            <section class="about-area">
                <img src="../assets/logo.png" alt="" rel="preload" />
                <h1>
                    <p>トーエーテクノインターナショナルとは</p>
                    <p>About Toei Techno International</p>
                </h1>
                <p>
                    トーエーテクノインターナショナルは、常に上質かつ優れた製品やサービスを多くのお客様に提案し、結びつけていく企業である姿を目指しています。<br />
                    グローバルな視点を持ち、日々進歩する技術革新の中でお客様のニーズに合った商品を厳選し、最適なソリューションをご提案します。<br />
                    我々は、アジアを中心にビジネスを展開しており、旧ファインリンクスの強固な基盤と、私たちの深い業界知識と経験を組み合わせることで、より大きな価値をお客様に提供します。
                </p>
            </section>

            <section class="description-area">
                <h1>
                    <p>トーエーテクノインターナショナルが<br class="md" />選ばれる3つの理由</p>
                    <p>STRENGTH</p>
                </h1>
                <ul>
                    <li class="description">
                        <div class="img-area"></div>
                        <div class="text-area-wrap">
                            <div class="text-area">
                                <h2>自社工場でのカスタム加工が可能です</h2>
                                <p>
                                  自社工場を完備しており、お客様のニーズに合わせた特殊加工を承ります。テープライトについても、使用目的に応じたカスタムサイズ、配線処理、コネクタの加工が可能です。さらに、迅速な納期対応でお客様のご要望にお応えします。
                                </p>
                            </div>
                        </div>
                    </li>

                    <li class="description">
                        <div class="img-area"></div>
                        <div class="text-area-wrap">
                            <div class="text-area">
                                <h2>迅速かつコスト効率の高いサービス</h2>
                                <p>
                                  中国にグループ企業を持ち、日本語対応が可能なスタッフが常駐しています。これにより、標準製品はもちろん、お客様の特別な要望に応じたカスタムオーダー製品の調達を迅速かつコスト効率良く行うことが可能です。お客様のビジネスをサポートするため、高品質かつ経済的なソリューションをご提供します。
                                </p>
                            </div>
                        </div>
                    </li>

                    <li class="description">
                        <div class="img-area"></div>
                        <div class="text-area-wrap">
                            <div class="text-area">
                                <h2>照明プロジェクトの全面プロデュース</h2>
                                <p>
                                  照明の企画・デザインから始まり、特注器具の開発、さらにはデジタル制御製品の施工に至るまで、一貫したトータルプロデュースを行います。お客様のビジョンを形にするため、最初のアイデアから完成まで、一つ一つのステップを丁寧にサポートします。
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>

            <section class="about-area2">
                <h1>
                    <p>会社概要</p>
                    <p>Company Profile</p>
                </h1>
                <table>
                    <tr>
                        <th>会社名</th>
                        <td>トーエーテクノインターナショナル株式会社<br />
                            Toei Techno International Inc.</td>
                    </tr>
                    <tr>
                        <th>創業</th>
                        <td>平成8年6月</td>
                    </tr>
                    <tr>
                        <th>設立</th>
                        <td>平成31年4月</td>
                    </tr>
                    <tr>
                        <th>資本金</th>
                        <td>2,000万円</td>
                    </tr>
                    <tr>
                        <th>従業員数</th>
                        <td>56名</td>
                    </tr>
                    <tr>
                        <th>代表者</th>
                        <td>代表取締役 <span> </span>東浦 英之</td>
                    </tr>
                    <tr>
                        <th>取引銀行</th>
                        <td>みずほ銀行、百五銀行</td>
                    </tr>
                    <tr>
                        <th>事業内容</th>
                        <td>
                            LED照明、デジタルサイネージ、空間演出、デジタルソリューション開発
                        </td>
                    </tr>
                    <tr class="gp">
                        <th>グループ企業</th>
                        <td>
                            <p>
                                トーエーテクノ株式会社<br />
                                株式会社ネクスウェイ<br />
                                SHENZHEN TOEI TECHNOLOGY CO., LTD.<br />
                                PT.TOEI TECHNO INTERNATIONAL INDONESIA<br />
                                PT.TOEI TEKNOLOGI INDONESIA<br />
                                WONDER BOX CO., LTD.
                            </p>
                            <a href="https://global.toei-group.com/">> <span>トーエーテクノグループサイトはこちら</span></a>
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <th>東京オフィス</th>
                        <td>
                            <p>
                                〒105-0013<br />
                                東京都港区浜松町1-6-2 丸神ビル5F<br />
                                TEL：03-6450-1486 / FAX：03-6450-1487<br />
                                最寄り駅<br />
                                ・都営地下鉄「浜松町」駅から徒歩５分<br />
                                ・都営地下鉄「大門」駅から徒歩４分
                            </p>
                            <!-- <div class="map" ref="map" /> -->
                            <div class="map">
                                <!-- map -->
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.7282279182814!2d139.75541291505448!3d35.659066938795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bc402b6aced%3A0xdb7fec6a57911dd1!2z44CSMTA1LTAwMTMg5p2x5Lqs6YO95riv5Yy65rWc5p2-55S677yR5LiB55uu77yW4oiS77yS!5e0!3m2!1sja!2sjp!4v1606730806551!5m2!1sja!2sjp"
                                    width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""
                                    aria-hidden="false" tabindex="0"></iframe>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>大阪オフィス</th>
                        <td>
                            <p>
                                〒542-0081<br />
                                大阪府大阪市中央区南船場4-8-6 渕上ビル3F<br />
                                TEL：06-4704-8778 / FAX：06-6210-2388<br />
                                最寄り駅<br />
                                ・大阪メトロ「心斎橋」駅から徒歩5分<br />
                                ・大阪メトロ「本町」駅から徒歩8分
                            </p>
                            <div class="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.088409200288!2d135.49700121502988!3d34.677718091956855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e71b753bcd8d%3A0xadce734df49691a7!2z44CSNTQyLTAwODEg5aSn6Ziq5bqc5aSn6Ziq5biC5Lit5aSu5Yy65Y2X6Ii55aC077yU5LiB55uu77yY4oiS77yW!5e0!3m2!1sja!2sjp!4v1606733747733!5m2!1sja!2sjp"
                                    width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen=""
                                    aria-hidden="false" tabindex="0"></iframe>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>INDONESIA Office</th>
                        <td>
                            <p>
                                EightyEight @ Kasablanka Office Tower 26 Floor Unit D Jl. Casablanca Kav. 88, Jakarta
                                Selatan, DKI Jakarta 128701.
                                <br />
                                <br />
                                TEL: (+62) 21-2963-1696
                            </p>
                            <div class="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63460.760923756265!2d106.841683!3d-6.224441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f329a9f6eec9%3A0x4bd23fdec62229ad!2s88office%40kasablanka!5e0!3m2!1sen!2sus!4v1715150710761!5m2!1sen!2sus"
                                    width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>CAMBODIA Office</th>
                        <td>
                            <p>
                                #1159, B4 GF, National Road No.2, Prek Tanou Village, Commune of Chak Angre Leu Mean
                                Chey, Phnom Penh, Cambodia.
                                <br />
                                <br />
                                TEL: +855 99 660 815
                            </p>
                            <div class="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3909.403581088503!2d104.9296845!3d11.522880099999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310950c970930e99%3A0x1a993a98716ff349!2sFACTORY%20Phnom%20Penh!5e0!3m2!1sen!2sjp!4v1715151264629!5m2!1sen!2sjp"
                                    width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </td>
                    </tr>
                </table>

            </section>
        </main>

        <div id="preload">
            <img rel="preload" alt="" src="../assets/img/cp-01.jpg" />

            <img rel="preload" alt="" src="../assets/img/cp-03.jpg" />
            <img rel="preload" alt="" src="../assets/img/cp-04.jpg" />
            <img rel="preload" alt="" src="../assets/img/cp-05.jpg" />
        </div>
    </div>
</template>

<script>
    // import GoogleMapsApiLoader from 'google-maps-api-loader';
    // import  'leaflet/dist/leaflet.css'
    // import  L from 'leaflet'

    // delete  L.Icon.Default.prototype._getIconUrl

    // L.Icon.Default.mergeOptions(
    //     {   iconUrl         : require( 'leaflet/dist/images/marker-icon.png' )
    //     ,   iconRetinaUrl   : require( 'leaflet/dist/images/marker-icon-2x.png' )
    //     ,   shadowUrl       : require( 'leaflet/dist/images/marker-shadow.png' )
    //     }
    // )

    export default {
        data: () => {
            return {
                // google: null,
                // mapConfig: {
                //     center: {
                //     lat: 35.68944,
                //     lng: 139.69167
                //     },
                //     zoom: 17
                // }
                // map:null
            };
        },
        // mounted() {
        //     L.map( 'map', { center: L.latLng( 35.659063, 139.757602 ), zoom: 15 } ).addLayer(
        //         L.tileLayer( 'http://{s}.tile.osm.org/{z}/{x}/{y}.png' )
        //     )
        // },
        // mounted() {
        //     const map = L.map( 'app', { center: L.latLng( 35.659063, 139.757602 ), zoom: 15 } ).addLayer(
        //         L.tileLayer( 'http://{s}.tile.osm.org/{z}/{x}/{y}.png' )
        //     ).on(
        //         'click'
        //     ,   p => map.addLayer( L.marker( p.latlng ) )
        //     )
        // },
        // async mounted() {
        //     this.google = await GoogleMapsApiLoader({
        //     apiKey: 'API_KEY'
        //     });
        //     this.initializeMap();
        // },
        // methods: {
        //     initializeMap() {
        //     new this.google.maps.Map(this.$refs.googleMap, this.mapConfig);
        //     }
        // },

        // mounted() {
        //     if (navigator.geolocation) {
        //     // callback関数内でthis使えないため
        //     let vm = this
        //     navigator.geolocation.getCurrentPosition(
        //         function(position){
        //         let latlng = new window.google.maps.LatLng(
        //             position.coords.latitude,
        //             position.coords.longitude
        //         );
        //         vm.map = new window.google.maps.Map(vm.$refs["map"], {
        //             center: latlng,
        //             zoom: 4
        //         })
        //         new window.google.maps.Marker({
        //             position: latlng,
        //             map: vm.map
        //         })
        //         }
        //     )
        //     }
        // },
        components: {
            // PageHead
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/sass/global.scss";

    #page-head {
        .bg-img {
            background: url("../assets/img/cp-01.jpg") no-repeat center center/cover;
        }

        .cover {
            background: #ffffff28;
        }
    }

    h1 p {
        @include lg {
            br.md {
                display: none;
            }
        }

        @include md {
            &:first-of-type {
                font-size: calc(11.6px + 1.16vw);
                line-height: 1.2;
            }

            &:nth-of-type(2) {
                margin-top: 0.2em;
                font-size: calc(5.4px + 0.54vw);
            }
        }
    }

    .about-area {
        width: 40vw;
        margin: 6.4vw auto;

        @include md {
            width: 80vw;
            margin: 10vw auto;
        }

        img {
            display: block;
            width: 6.8vw;
            margin: 0 auto;

            @include md {
                width: 12vw;
            }
        }

        h1 {
            margin: 2.4vw 0 3.6vw;

            p {
                width: 100%;
            }
        }

        >p {
            font-size: calc(6.8px + 0.68vw);
            text-align: left;
            letter-spacing: 0.024em;
            line-height: 1.96;

            @include md {
                letter-spacing: 0.04em;
                margin-top: 6.4vw;
                font-size: calc(9.4px + 0.94vw);
            }
        }
    }

    .description-area {
        margin: 9.6vw 0;

        @include md {
            margin: 14.4vw 0;
        }

        h1 {
            margin: 0 0 5.6vw;
        }

        ul {
            padding: 0;
        }

        .description {
            list-style: none;
            position: relative;
            margin: 4vw 0;
            width: 100vw;
            height: 28vw;

            @include md {
                margin: 4vw 0vw;
                width: 100vw;
                height: 52vw;
                // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }

            .img-area {
                position: absolute;
                width: 48vw;
                height: 24vw;

                @include md {
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }

            .text-area-wrap {
                position: absolute;
                width: 40vw;
                height: 20vw;
                box-sizing: border-box;

                @include lg {
                    top: 2vw;
                    padding: 0 3.2vw;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    background: #f2f2f2;
                }

                @include md {
                    position: absolute;
                    top: 0;
                    padding: 2vw 6vw;
                    width: 100%;
                    height: 100%;
                    color: white;
                    background: #3d3d3dbe;
                }

                .text-area {
                    position: relative;
                    top: 50%;
                    @include translate(0, -50%);
                }

                h2 {
                    font-weight: 500;
                    font-size: calc(8px + 0.8vw);
                    letter-spacing: 0.2em;

                    @include md {
                        font-size: calc(9.8px + 0.98vw);
                        letter-spacing: 0.096em;
                    }
                }

                p {
                    margin-top: 0.96vw;
                    font-size: calc(6.4px + 0.64vw);
                    line-height: 1.88;
                    text-align: left;

                    @include md {
                        letter-spacing: normal;
                        margin-top: 3.2vw;
                        font-size: calc(8.6px + 0.86vw);
                    }
                }
            }

            @include lg {

                &:nth-of-type(1),
                &:nth-of-type(3) {
                    .img-area {
                        right: 0;
                    }

                    .text-area-wrap {
                        right: 36vw;
                    }
                }

                &:nth-of-type(2) {
                    .img-area {
                        left: 0;
                    }

                    .text-area-wrap {
                        left: 36vw;
                    }
                }
            }

            &:nth-of-type(1) {
                margin-top: 3.2vw;

                .img-area {
                    background: url("../assets/img/cp-03.jpg") no-repeat center center/cover;
                }
            }

            &:nth-of-type(2) {
                .img-area {
                    background: url("../assets/img/cp-04.jpg") no-repeat center center/cover;
                }
            }

            &:nth-of-type(3) {
                .img-area {
                    background: url("../assets/img/cp-05.jpg") no-repeat center center/cover;
                }
            }
        }
    }

    .about-area2 {
        margin: 8.8vw 0;

        @include md {
            margin: 14.4vw 0;
        }

        h1 {
            margin: 4vw 0;
        }

        table {
            margin-top: 1.6vw;
            margin-left: $lg-mar-lr;
            width: calc(100vw - #{$lg-mar-lr}* 2);
            border-collapse: separate;
            letter-spacing: 0.08em;
            border-spacing: 0px 0.12vw;

            @include md {
                margin-top: 0;

                &:first-of-type {
                    margin-top: 6vw;
                }

                margin-left: 4vw;
                width: 92vw;
                border-spacing: 0px 2.4vw;
                border-collapse: separate;
            }

            th {
                width: 20%;
                padding: 2.4vw 1.2vw;
                background: #000;
                color: white;
                font-weight: normal;
                font-size: calc(6.4px + 0.64vw);
                text-align: left;

                @include md {
                    // width: 24%;
                    padding: 1.6vw 2.4vw;
                    font-size: calc(9px + 0.9vw);
                    font-weight: 500;
                }
            }

            td {
                width: 80%;
                padding-left: 1.2vw;
                font-size: calc(7.2px + 0.72vw);
                line-height: 1.6;
                border: #e0e0e0 1px solid;

                @include md {
                    letter-spacing: normal;
                    font-size: calc(10px + 1vw);
                    padding: 2.4vw 2.4vw;
                    border: none;
                    background: #f2f2f2;
                }

                a {
                    display: inline-block;
                    margin: 0 0 0.8vw;
                    padding: 0.64vw 0.2vw 0.4vw;

                    span {
                        opacity: 0.7;
                        transition: 0.3s;
                    }

                    &:hover {
                        span {
                            color: $color-green;
                            transition: 0.3s;
                        }
                    }
                }
            }

            th,
            td {
                @include md {
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            .gp td {
                padding: 1.6vw 1.2vw;

                @include md {
                    padding: 4vw 2.4vw;
                }
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                @include lg {
                    td {
                        position: relative;
                        width: unset;
                        display: flex;

                        p {
                            margin-top: calc(60px + 6vw);
                            @include translate(0, -50%);
                            width: 44%;
                        }

                        .map {
                            position: relative;
                            width: 56%;
                            height: calc(140px + 14vw);
                        }
                    }
                }

                @include md {
                    td {
                        padding: 0;

                        p {
                            padding: 4vw 2.4vw;
                        }

                        .map {
                            height: 48vw;
                        }
                    }
                }
            }
        }
    }
</style>
