<template>
  <div class="news-content-01 news-content">
    <div class="img-wrapper">
      <div
        class="img-area news-img-07-01"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/npc-07-01.jpg') + ')',
        }"
      ></div>
    </div>
    <p>
      4月某日、弊社事務所にて、結晶化ガラス建材「パリトーン」と弊社LED製品の組み合わせによる検証実験を行いました。<br />「パリトーン」は、株式会社ウイング様が販売している透過性のあるガラス建材です。<br />この「パリトーン」内部にLED灯具を仕込むと、どのような透光結果になるかを実験いたしました。<br /><br />この実験は、ビル壁面や飲食店内装、その他多数の実績を持つウイング様との共同実験です。<br />実験の詳細は下記リンクご覧下さい。
    </p>
    <br />
    <a href="https://paritone.jp/blog/lab_008/" target="_blank" rel="noopener">
      <p class="link-url"><span class="mark">＞</span>ぱりとん君の実験室</p>
    </a>
    <br />
    <a href="https://paritone.jp/" target="_blank" rel="noopener">
      <p class="link-url">
        <span class="mark">＞</span>株式会社ウイング様<br />
        (外壁や屋内装飾で好評の結晶化ガラス建材「パリトーン」を販売している企業様です。)
      </p>
    </a>
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoId: "hI_mz2APwY0",
      videoId2: "gHaPJpl47oE",
      widthRate: 0.76,
      heightRate: 0.76 * 0.5625,
      windowWidth: window.innerWidth,
      playerVars: {
        autoplay: 0,
        modestbranding: 1,
        rel: 0,
      },
    };
  },
  computed: {
    videoWidth: function() {
      return this.windowWidth * this.widthRate;
    },
    videoHeight: function() {
      return this.windowWidth * this.heightRate;
    },
  },
  methods: {
    handleResize: function() {
      this.windowWidth = window.innerWidth;
      //   this.height = window.innerHeight;
      if (window.innerWidth > 899) {
        this.widthRate = 0.76;
        this.heightRate = 0.76 * 0.5625;
      } else {
        this.widthRate = 0.92;
        this.heightRate = 0.92 * 0.5625;
      }
    },
  },
  mounted: function() {
    window.addEventListener("resize", this.handleResize);
    if (window.innerWidth > 899) {
      this.widthRate = 0.76;
      this.heightRate = 0.76 * 0.5625;
    } else {
      this.widthRate = 0.92;
      this.heightRate = 0.92 * 0.5625;
    }
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.img-wrapper {
  position: relative;
  width: 100%;
  margin: 0 0 4vw;
}
.img-wrapper:before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 高さを幅の75%に固定 */
}
.news-img-07-01 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.link-url {
  display: inline-block;
  padding: 0.8vw;
  padding-left: 0.2vw;
  font-size: calc(6.4px + 0.64vw);
  .mark {
    margin-right: 0.4vw;
    font-size: calc(5.6px + 0.56vw);
  }
  @include lg {
    transition: 0.2s;
    &:hover {
      color: $color-green;
      opacity: 0.7;
    }
  }
}
.link-url1 {
}
.video {
  @include md {
    margin-left: -2vw;
  }
}
</style>
