<template>
    <div class="spec-table">
        <div class="table-area">
            <h3>単色タイプ</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>LEDチップ</th>
                    <th>色温度</th>
                    <th>全光束</th>
                    <th>配光角</th>
                    <th>電圧</th>
                    <th>電流値/1pcs</th>
                    <th>消費電力/1pcs</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-MD131</td>
                    <td>SMD 5050</td>
                    <td>6000K</td>
                    <td>110-120lm</td>
                    <td>160度</td>
                    <td>12V</td>
                    <td>0.17A</td>
                    <td>2W</td>
                    <td>IP65</td>
                </tr>
                <tr>
                    <td>FL-MD123</td>
                    <td>SMD 5050</td>
                    <td>6000K</td>
                    <td>60-70lm</td>
                    <td>160度</td>
                    <td>12V</td>
                    <td>0.06A</td>
                    <td>0.72W</td>
                    <td>IP65</td>
                </tr>
                <tr>
                    <td>FL-MD80</td>
                    <td>SMD 5050</td>
                    <td>6000K</td>
                    <td>240-250lm</td>
                    <td>160度</td>
                    <td>12V</td>
                    <td>0.25A</td>
                    <td>3W</td>
                    <td>IP65</td>
                </tr>
                <tr>
                    <td>FL-MD1312</td>
                    <td>SMD 5050</td>
                    <td>6000K</td>
                    <td>110-120lm</td>
                    <td>160度</td>
                    <td>12V</td>
                    <td>0.125A</td>
                    <td>1.5W</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>

        <div class="table-area">
            <h3>RGBフルカラータイプ</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>LEDチップ</th>
                    <th>色温度</th>
                    <th>配光角</th>
                    <th>電圧</th>
                    <th>電流値/1pcs</th>
                    <th>消費電力/1pcs</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-MD124</td>
                    <td>SMD 5050RGB</td>
                    <td>
                        R:620-630nm<br>
                        G:520-530nm<br>
                        B:420-430nm
                    </td>
                    <td>160度</td>
                    <td>12V</td>
                    <td>0.06A</td>
                    <td>0.72W</td>
                    <td>IP65</td>
                </tr>
                <tr>
                    <td>FL-735G-RGB</td>
                    <td>SMD 5050RGB 3pcs</td>
                    <td>
                        R:620-630nm<br>
                        G:520-530nm<br>
                        B:420-430nm
                    </td>
                    <td>125度</td>
                    <td>12V</td>
                    <td>0.072A</td>
                    <td>0.86W</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>

        <div class="table-area">
            <h3>デジタルタイプ</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>LEDチップ</th>
                    <th>色温度</th>
                    <th>配光角</th>
                    <th>電圧</th>
                    <th>電流値/1pcs</th>
                    <th>消費電力/1pcs</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>FL-MD125</td>
                    <td>SMD 5050RGB</td>
                    <td>
                        専用デジタルコントローラにて制御可能
                    </td>
                    <td>160度</td>
                    <td>5V</td>
                    <td>0.06A</td>
                    <td>0.72W</td>
                    <td>IP65</td>
                </tr>
                <tr>
                    <td>FL-MD115</td>
                    <td>SMD 5050RGB 3pcs</td>
                    <td>
                        専用デジタルコントローラにて制御可能
                    </td>
                    <td>120度</td>
                    <td>12V</td>
                    <td>0.072A</td>
                    <td>0.86W</td>
                    <td>IP65</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
        th {
            &:nth-of-type(1) {
                width: 16%;
            }
            // &:nth-of-type(2) {
            //     width: 24%;
            // }
            // &:nth-of-type(3) {
            //     width: 12%;
            // }
            // &:nth-of-type(4) {
            //     width: 8%;
            // }
            // &:nth-of-type(5) {
            //     width: 10%;
            // }
            // &:nth-of-type(6) {
            //     width: 12%;
            // }
            // &:nth-of-type(7) {
            //     width: 10%;
            // }
        }
        &:nth-of-type(1) {
            th {
                &:nth-of-type(2) {
                    width: 18%;
                }
                &:nth-of-type(3) {
                    width: 8%;
                }
                &:nth-of-type(4) {
                    width: 12%;
                }
                &:nth-of-type(5) {
                    width: 7%;
                }
                &:nth-of-type(6) {
                    width: 7%;
                }
                &:nth-of-type(7) {
                    width: 11%;
                }
                &:nth-of-type(8) {
                    width: 12%;
                }
                &:nth-of-type(9) {
                    width: 9%;
                }
            }
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
            th {
                &:nth-of-type(2) {
                    width: 18%;
                }
                &:nth-of-type(3) {
                    width: 20%;
                }
                &:nth-of-type(4) {
                    width: 7%;
                }
                &:nth-of-type(5) {
                    width: 7%;
                }
                &:nth-of-type(6) {
                    width: 11%;
                }
                &:nth-of-type(7) {
                    width: 12%;
                }
                &:nth-of-type(8) {
                    width: 9%;
                }
            }
        }
}

</style>
