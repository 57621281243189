<template>
    <div class="products-detail-content">

        <h2>単色の製品用</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-14-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    記憶型リモコン式調光器
                </h3>
                <p>

                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-14-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    リモコン式調光器
                </h3>
                <p>

                </p>
            </div>
        </div>

        <h2>RGBフルカラーの製品用</h2>
        <div class="list-wrap">
            <!-- 04 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-14-'+ '04' + '.jpg') + ')'}"></div>
                <h3>
                    リモコン式RGB調光制御器
                </h3>
                <p>

                </p>
            </div>
            <!-- 05 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-14-'+ '05' + '.jpg') + ')'}"></div>
                <h3>
                    DMX to RGBデコーダー
                </h3>
                <p>

                </p>
            </div>
            <!-- 06 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-14-'+ '06' + '.jpg') + ')'}"></div>
                <h3>
                    RGB信号増幅機
                </h3>
                <p>

                </p>
            </div>
        </div>

        <h2>デジタルの製品用</h2>
        <div class="list-wrap">
            <!-- 07 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-14-'+ '07' + '.jpg') + ')'}"></div>
                <h3>
                    DMX to SPIデコーダー
                </h3>
                <p>

                </p>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
