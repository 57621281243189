<template>
    <div class="spec-table">
        <div class="table-area">
            <h3>【12V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>波長</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN5050RGB-12</td>
                    <td>
                        R=>620-630nm<br>
                        G=>520-530nm<br>
                        B=>455-465nm
                    </td>
                    <td>60pcs</td>
                    <td>DC12V</td>
                    <td>1.2A</td>
                    <td>14.4W</td>
                    <td>IP68準拠</td>
                </tr>
            </table>
        </div>

        <div class="table-area">
            <h3>【24V仕様】</h3>
            <table>
                <tr>
                    <th>品番</th>
                    <th>波長</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>YN5050RGB-24</td>
                    <td>
                        R=>620-630nm<br>
                        G=>520-530nm<br>
                        B=>455-465nm
                    </td>
                    <td>60pcs</td>
                    <td>DC24V</td>
                    <td>0.6A</td>
                    <td>14.4W</td>
                    <td>IP68準拠</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
        th {
            &:nth-of-type(1) {
                width: 24%;
            }
            &:nth-of-type(2) {
                width: 24%;
            }
            &:nth-of-type(3) {
                width: 12%;
            }
            &:nth-of-type(4) {
                width: 8%;
            }
            &:nth-of-type(5) {
                width: 10%;
            }
            &:nth-of-type(6) {
                width: 12%;
            }
            &:nth-of-type(7) {
                width: 10%;
            }
        }
}

</style>
