<template>
  <div class="products-detail-content">
    <h2>カラーバリエーション例</h2>
    <div class="list-wrap">
      <!-- 02 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-05-' + '02' + '.jpg') + ')',
          }"
        ></div>
        <h3></h3>
        <p class="hasHeight"></p>
      </div>
      <!-- 03 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-05-' + '03' + '.jpg') + ')',
          }"
        ></div>
        <h3></h3>
        <p class="hasHeight"></p>
      </div>
      <!-- 04 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-05-' + '04' + '.jpg') + ')',
          }"
        ></div>
        <h3></h3>
        <p class="hasHeight"></p>
      </div>
    </div>

    <h2>専用オプション品</h2>
    <div class="list-wrap">
      <!-- 05 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-05-' + '05' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          ART-NET NODE
        </h3>
        <p>
          ※仕様はお問い合わせ下さい
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.hasHeight {
  // height: 5.2em;
}
</style>
