<template>
    <div class="products-detail-content">

        <h2>製品一覧</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-15-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    5050タイプ専用アルミバー
                </h3>
                <p>
                    FLEXIA LEDテープライト（白色・電球色タイプ）および（RGBフルカラータイプ）の「高輝度」な5050タイプ専用のアルミバーです。5050タイプをご使用になられる方はこちらをお選び下さい。
                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-15-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    3528タイプ専用アルミバー
                </h3>
                <p>
                    FLEXIA LEDテープライト（白色・電球色タイプ）の「省エネ」な3528タイプ専用のアルミバーです。3528タイプをご使用になられる方はこちらをお選び下さい。
                </p>
            </div>
            <!-- 04 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-15-'+ '04' + '.jpg') + ')'}"></div>
                <h3>
                    角設置専用アルミバー
                </h3>
                <p>
                    角に設置をするご予定であれば、このアルミバーをおすすめ致します。三角形になっており、光の面を傾けることが可能です。FLEXIA LEDテープライトのどの製品にも取り付けが可能です。
                </p>
            </div>
            <!-- 05 -->
            <div class="list hasRm">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-15-'+ '05' + '.jpg') + ')'}"></div>
                <h3>
                    ドットレス専用アルミバー
                </h3>
                <p>
                    ドットレス仕様にしたい場合はこちらをご選択下さい。他のアルミバーよりも深さがあり、より粒感をなくした美しい光を演出できます。カバーもドットレス専用の物になります。FLEXIA LEDテープライトのどの製品にも取り付けが可能です。
                </p>
                <div class="recommended">
                    <p>おすすめ</p>
                </div>
            </div>
        </div>

        <h2>各カバーの光の見え方比較例</h2>
        <div class="list-wrap">
            <!-- 06 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-15-'+ '06' + '.jpg') + ')'}"></div>
                <h3>
                    フロスト
                </h3>
                <p>
                    フロストのカバーは、LEDテープライトに取り付けることでLEDの粒感を抑えた光を演出することができます。
                </p>
            </div>
            <!-- 07 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-15-'+ '07' + '.jpg') + ')'}"></div>
                <h3>
                    クリア
                </h3>
                <p>
                    クリアのカバーは、LEDの粒感をそのまま残したまま、放熱に優れた仕様にすることが可能です。
                </p>
            </div>
            <!-- 08 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-15-'+ '08' + '.jpg') + ')'}"></div>
                <h3>
                    ドットレス
                </h3>
                <p>
                    ドットレス専用のカバーはマットな質感で、フロストよりもさらに粒感を抑えることが可能です。美しいライン状の光を演出できます。
                </p>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
