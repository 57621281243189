var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-detail-content"},[_c('h2',[_vm._v("カラーバリエーション例")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-09-' + '02' + '.jpg') + ')',
        })}),_c('h3'),_c('p',{staticClass:"hasHeight"})]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-09-' + '03' + '.jpg') + ')',
        })}),_c('h3'),_c('p',{staticClass:"hasHeight"})]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-09-' + '04' + '.jpg') + ')',
        })}),_c('h3'),_c('p',{staticClass:"hasHeight"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }