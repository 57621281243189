<template>
  <div style="position: relative;" class="slide-rel-work">
    <hooper class="hooper__hooper03" :settings="hooperSettings">
      <slide v-for="workId in idList" :key="workId.id" class="content-wrap">
        <!-- <p>{{ index }}</p> -->
        <div class="content">
          <div class="img-wrap">
            <div
              class="img"
              :style="{
                'background-image':
                  'url(' +
                  require('@/assets/img/wp-' + works[workId].id + '-01.jpg') +
                  ')',
              }"
            ></div>
          </div>

          <div class="text-area">
            <p>{{ works[workId].name }}</p>
            <router-link
              :to="{ name: 'works-detail', params: { id: Number(workId) } }"
              class="show-detail"
              v-if="works[workId].id != '00'"
              >詳細はこちら
            </router-link>
          </div>
        </div>
      </slide>

      <hooper-pagination slot="hooper-addons" />
      <hooper-navigation slot="hooper-addons" />
    </hooper>

    <div class="left-mark">
      <img src="../assets/img/mk-03.svg" alt="" />
    </div>
    <div class="right-mark">
      <img src="../assets/img/mk-04.svg" alt="" />
    </div>
  </div>
</template>

<script>
import works from "../assets/json/works.json";
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },
  data: () => {
    return {
      works,
      hooperSettings: {
        itemsToShow: 4.8,
        // centerMode: true,
        wheelControl: false,
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 2000,
        transition: 400,
      },
    };
  },
  props: {
    idList: {
      type: String,
    },
  },
  created() {
    if (window.innerWidth < 899) {
      this.hooperSettings = {
        itemsToShow: 1.8,
        wheelControl: true,
        infiniteScroll: true,
      };
    } else {
      if (this.idList.length <= 3) {
        this.hooperSettings = {
          itemsToShow: 3.2,
          wheelControl: false,
          infiniteScroll: true,
        };
      }
    }
  },
};
</script>

<style lang="scss">
@import "../assets/sass/global.scss";
.hooper__hooper03 {
  position: relative;
  width: 100vw;
  height: 32vw;
  @include md {
    height: 84vw;
  }
  .hooper-list {
    position: relative;
    display: block;
    .hooper-track {
      @include md {
        padding-left: calc(32vw / 1.5);
      }
    }
    .content-wrap {
      @include lg {
        border: 8px white solid;
      }
      // margin-left: 1.2vw;
      // &:first-of-type {
      //     margin-left: calc(50vw / 5.8) !important;
      // }
    }
    .content {
      position: relative;
      height: 100%;
      @include lg {
        &:hover {
          .img {
            filter: brightness(0.3);
            transition: 0.3s;
          }
          .text-area {
            opacity: 1;
            transition: 0.3s;
          }
        }
      }
    }
    .img-wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .img {
        width: 100%;
        height: 100%;
        @include transform(scale(1.61));
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @include md {
          filter: brightness(0.5);
        }
        transition: 0.3s;
      }
    }
    .text-area {
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -44%);
      width: 100%;
      color: white;
      text-align: center;
      font-size: calc(6.6px + 0.66vw);
      opacity: 0;
      transition: 0.3s;
      font-weight: 500;
      user-select: none;
      .show-detail {
        display: inline-block;
        color: white;
        margin-top: 1.6vw;
        padding: 1.04vw 4vw;
        border: 1px white solid;
        font-size: calc(5.6px + 0.56vw);
        @include lg {
          font-weight: normal;
        }
        &:hover {
          @include lg {
            background: #{$color-green}88;
            filter: brightness(1.2);
            border: #{$color-green}11 1px solid;
          }
        }
      }
      @include md {
        background: #00000080;
        top: 100%;
        left: 50%;
        @include translate(-50%, -100%);
        height: 12vw;
        opacity: 0;
        p {
          text-align: left;
          padding: 2.8vw 2vw;
          font-size: calc(10.5px + 1.05vw);
          position: absolute;
          top: 0%;
          // @include translate(0, -50%);
          width: 100%;
        }
        .show-detail {
          position: absolute;
          width: 100%;
          // height: 8vw;
          left: 0;
          bottom: -4vw;
          // @include translate(-50%, 0);
          padding: 3.6vw 0vw;
          border: none;
          // border-bottom: 1px white solid;
          font-size: calc(9px + 0.9vw);
          background: #{$color-green}cc;
          filter: brightness(1.05);
          transition: 0.3s;
        }
      }
    }
    .is-current {
      .img {
        @include md {
          filter: brightness(1.05);
          transition: 0.3s;
        }
      }
      .text-area {
        @include md {
          background: #00000080;
          top: 100%;
          left: 50%;
          @include translate(-50%, -100%);
          height: 30vw;
          opacity: 1;
          p {
            width: 100%;
          }
          .show-detail {
            bottom: 0vw;
            transition: 0.3s;
          }
        }
      }
    }

    .hooper-prev,
    .hooper-next {
      width: calc(50vw / 4.8);
      height: 100%;
      // cursor: default;
      @include md {
        width: calc(20vw / 1.6);
      }
      .icon {
        display: none;
      }
    }

    .hooper-pagination {
      display: none;
    }
  }
}
</style>

<style lang="scss">
@import "../assets/sass/global.scss";

.slide-rel-work {
  .left-mark,
  .right-mark {
    position: absolute;
    top: 50%;
    @include translate(0, -50%);
    background: #{$color-green}aa;
    filter: brightness(1.15);
    pointer-events: none;
    @include md {
      width: 9.6vw;
      height: 9.6vw;
      border-radius: 50%;
    }
    img {
      cursor: pointer;
      user-select: none;
      width: 1vw;
      @include md {
        margin: 3.2vw;
        width: 2.4vw;
      }
    }
  }
  .left-mark {
    padding: 1.4vw 1.8vw 1.3vw 1.6vw;
    left: 2.8vw;
    @include md {
      left: 3.6vw;
      // padding: 4.4vw 2.8vw 4.3vw 2.6vw;
      padding: 0;
      img {
      }
    }
  }
  .right-mark {
    padding: 1.4vw 1.6vw 1.3vw 1.8vw;
    right: 2.8vw;
    @include md {
      right: 3.6vw;
      // padding: 4.4vw 2.6vw 4.3vw 2.8vw;
      padding: 0;
    }
  }
}
</style>
