<template>
  <!-- 3 LEDテープライト（デジタルタイプ） -->
  <div>
    <div class="table-area">
      <h3>１チップ制御タイプ</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-WS2812B-I-1C</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            <a
              href="../specifications/FL-CLWS2812B-I-1C.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>３チップ制御タイプ</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-CLWS2811-O-3C</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            <a
              href="../specifications/FL-CLWS2811-O-3C.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
        <tr>
          <td>FL-CLUCS1903S-60-3C</td>
          <td>-</td>
          <td rowspan="1">
            <a
              href="../specifications/FL-CLUCS1903S-60-3C.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>６チップ制御タイプ</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-CLUCS2903-O-6C</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            <a
              href="../specifications/FL-CLUCS2903-O-6C.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
