<template>
    <div class="products-detail-content">

        <h2>カラーラインナップ</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-04-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    色温度：2500K（電球色）
                </h3>
                <p>
                    最も色温度が低く、イメージとしてはロウソクの明かりのようなアンティークで優しく暖かい演出をすることができます。
                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-04-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    色温度：2800K（電球色）
                </h3>
                <p>
                    2番目に色温度が低い電球色で、一般白熱ランプと同じ明るさです。日の出、日没のような色合いで落ち着いた雰囲気を演出できます。
                </p>
            </div>
            <!-- 04 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-04-'+ '04' + '.jpg') + ')'}"></div>
                <h3>
                    色温度：6000K（昼白色）
                </h3>
                <p>
                    青み掛かった白色になります。太陽の光のような色で、白さを際立たせたい時によく使われる、展示会などで人気のカラーです。
                </p>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
