<template>
	<div class="news-content-01 news-content">
		<div class="img-wrapper">
			<div class="img-area news-img-06-01" :style="{'background-image': 'url(' + require('@/assets/img/npc-06-01.jpg') + ')'}"></div>
		</div>
		<p>
			聖火リレーの車両にて、弊社（担当：土屋）が「燃え盛る炎」を、特殊な手法でリアルに演出しています。<br>これから最終目的地の東京を目指して、全国各地を巡回します。<br>（ご覧になる際は、人混みを避け、十分な感染対策等をお願い申し上げます。）
		</p>
		<br>
		<a href="https://tokyo2020.org/ja/torch/route/" target="_blank" rel="noopener">
			<p class="link-url"><span class="mark">＞</span>聖火リレールート情報ページへ</p>
		</a>
		<br><br><br><br>
		<p>
			弊社ではLED等、照明の加工、販売から、演出、制御までトータルでお受けできます。<br>お悩みの場合はお客様のご要望をお聞かせください。
		</p>
		<br>
		<router-link
			to="/contact"
		>
			<p class="link-url link-url1"><span class="mark">＞</span>お問い合わせはこちらから</p>
		</router-link>
		<br><br><br>
		<div class="video">
		<youtube :video-id="videoId" :player-vars="playerVars" :width="videoWidth" :height="videoHeight" />
		</div>
		<br><br>
		<div class="video">
		<youtube :video-id="videoId2" :player-vars="playerVars" :width="videoWidth" :height="videoHeight" />
		</div>
		<br>
	</div>
</template>

<script>
export default {
	data() {
	return {
	videoId: 'hI_mz2APwY0',
	videoId2: 'gHaPJpl47oE',
	widthRate: 0.76,
	heightRate: 0.76*0.5625,
	windowWidth: window.innerWidth,
	playerVars: {
        autoplay: 0,
		modestbranding: 1,
		rel: 0
	}
	}
	},
	computed: {
		videoWidth: function() {
			return this.windowWidth*this.widthRate;
		},
		videoHeight: function() {
			return this.windowWidth*this.heightRate;
		}
	},
	methods: {
	handleResize: function() {
      this.windowWidth = window.innerWidth;
    //   this.height = window.innerHeight;
	if(window.innerWidth > 899) {
		this.widthRate = 0.76;
		this.heightRate = 0.76*0.5625;
	}else {
		this.widthRate = 0.92;
		this.heightRate = .92*.5625;
	}
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
	if(window.innerWidth > 899) {
		this.widthRate = 0.76;
		this.heightRate = 0.76*0.5625;
	}else {
		this.widthRate = 0.92;
		this.heightRate = .92*.5625;
	}
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>
	@import "../assets/sass/global.scss";
	.img-wrapper {
		position: relative;
		width: 100%;
		margin: 0 0 4vw;
	}
	.img-wrapper:before {
		content:"";
		display: block;
		padding-top: 56.25%; /* 高さを幅の75%に固定 */
	}
	.news-img-06-01 {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	.link-url {
		display: inline-block;
		padding: 0.8vw;
		padding-left: 0.2vw;
		font-size: calc(6.4px + 0.64vw);
		.mark {
			margin-right: 0.4vw;
			font-size: calc(5.6px + 0.56vw);
		}
		@include lg {
			transition: 0.2s;
			&:hover {
				color: $color-green;
				opacity: 0.7;
			}
		}
	}
	.link-url1 {
	}
	.video {
		@include md {
			margin-left: -2vw;
		}
	}
</style>
