<template>
    <div id="page-head">
        <div class="bg-img"></div>
        <div class="cover"></div>
        <h1>
            <p>納入事例</p>
            <p>WORKS</p>
        </h1>

        <div id="preload">
            <img rel="preload" alt="" src="../assets/img/wp-12-03.jpg">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
    #page-head {
        .bg-img {
            background: url('../assets/img/wp-12-03.jpg') no-repeat  center center/cover;
            @include md {
                background: url('../assets/img/wp-12-03.jpg') no-repeat  center center/150%;
            }
        }
    }

</style>
