<template>
  <div class="form-page">
    <div id="page-head">
      <div class="bg-img"></div>
      <div class="cover"></div>
      <h1>
        <p>お問い合わせ</p>
        <p>CONTACT</p>
      </h1>
    </div>

    <main>
      <!-- <div class="info">
        <h2>年末年始休業のお知らせ</h2>
        <p>
          誠に勝手ではこざいますが、当社の年末年始休業につきまして下記の通りとさせていただきます。<br />
          何卒ご理解頂きますようお願い申し上げます。<br />
          ●年末年始休業期間：<br class="md" /><span
            >2021年12月29日(水)<br class="md" />～2022年1月5日(水)</span
          ><br />
          2022年1月6日(木)から通常通り営業させていただきます。<br />
          休業期間中にいただいたお問合せにつきましては、1月6日より順次ご対応させて頂きます。
        </p>
      </div> -->

      <!-- <section class="message-area"> -->
      <!-- <p>
                    製品についてのご質問・ご相談など、<br class="md">お気軽にお問い合わせ下さい。<br>
                    お電話でのお問い合わせも<br class="md">受け付けております。
                </p> -->
      <!-- <p>
          製品についてのご質問・ご相談など、<br
            class="md"
          />お気軽にお問い合わせ下さい。<br />
        </p> -->
      <!-- <p class="faxnum1">
          <br /><br />
          ※現在お問い合わせフォーム調整中につき、お見積のご依頼等は下記FAX番号にお願いします↓<br />
        </p>
        <p class="faxnum2">
          東京：03-6450-1487<span>|</span><br />大阪：06-6210-2388<br />
        </p> -->
      <!-- </section> -->

      <!-- <section class="link-area"> -->
      <!-- <router-link
                    to="/contact-form"
                    class="link"
                    target="_blank"
                    rel="noopener"
                > -->
      <!-- <router-link to="/contact-form" class="link" rel="noopener">
          入力画面へ
        </router-link> -->
      <!-- class, action, methodを変更しないでください -->

      <!-- <a href="https://form.run/@1621219560" target="_blank" rel="noopener"
          >入力画面へ</a
        > -->
      <!-- </section> -->

      <!-- <iframe src="" frameborder="0"> -->

      <!-- <h2>フォームでお問い合わせ</h2> -->
      <div id="formrun-wrap">
        <div id="formrun">
          <!-- <div
            class="formrun-embed"
            data-formrun-form="@1621219560"
            data-formrun-redirect="true"
          ></div> -->
          <div
            class="formrun-embed"
            data-formrun-form="@finelinks-inc-1621391070"
            data-formrun-redirect="true"
          ></div>
        </div>
      </div>
      <!-- </iframe> -->
      <!-- <h2>お電話でお問い合わせ</h2> -->
      <section class="info-area">
        <div class="flex-area">
          <div class="inner-flex">
            <div class="img-area">
              <img src="../assets/img/mk-05.svg" alt="" />
            </div>

            <div class="about-area">
              <p>
                <span class="md-span"
                  >お電話・FAXでのお問い合わせはこちら<br
                /></span>
                営業時間：10:00 ～ 18:00<br class="md" />（休日・祝日を除く）
              </p>
            </div>
          </div>
          <div class="phone-num-area">
            <p>
              東京<br class="md" />
              <span class="lg">TEL：03-6450-1486 / FAX：03-6450-1487</span>
              <span class="md">
                TEL：03-6450-1486<br class="md" />
                FAX：03-6450-1487
              </span>
            </p>
            <p>
              大阪<br class="md" />
              <span class="lg">TEL：06-4704-8778 / FAX：06-6210-2388</span>
              <span class="md">
                TEL：06-4704-8778<br />
                FAX：06-6210-2388
              </span>
            </p>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  mounted: function() {
    // this.$store.state.count = true;
    // window.checkreload = true;
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

// .faxnum1 {
//   color: red;
//   font-weight: 500;
//   padding-bottom: 1.2vw;
// }

// .faxnum2 {
//   display: inline-block;
//   position: absolute;
//   padding: 0.8vw 2.4vw;
//   left: 50vw;
//   @include translate(-50%, 0);
//   color: white;
//   background: red;
//   font-weight: 500;
//   @include md {
//     padding: 2vw 8vw;
//     width: unset !important;
//     text-align: center;
//   }
//   span {
//     padding: 0 1.6vw;
//     @include md {
//       display: none;
//     }
//   }
//   br {
//     @include lg {
//       display: none;
//     }
//   }
// }

.form-page {
  background: #f2f2f2;
}

.info {
  display: none;
  // width: 100vw;
  // margin: 2vw auto 6.4vw;
  // padding: 3.2vw 12vw 3.2vw;
  // background: #e4e4e4;
  // // border-radius: 4px;
  // @include md {
  //   width: 92vw;
  //   margin: 0 0 8vw;
  //   padding: 8vw 4vw;
  // }
  // @include lg {
  //   br.md {
  //     display: none;
  //   }
  // }
  // h2 {
  //   font-size: calc(9.4px + 0.94vw);
  //   line-height: 2.1;
  //   padding-bottom: 0.8vw;
  //   @include md {
  //     font-size: 20px;
  //   }
  // }
  // p {
  //   font-size: calc(6.8px + 0.68vw);
  //   line-height: 2.1;
  //   text-align: left;
  //   letter-spacing: 0.04em;
  //   padding-left: 0.04em;
  //   @include md {
  //     font-size: 16px;
  //   }
  //   span {
  //     font-size: calc(8px + 0.8vw);
  //     line-height: 3;
  //     font-weight: bold;
  //     color: #f40000;
  //     @include md {
  //       font-size: 18px;
  //       line-height: 2.1;
  //     }
  //   }
  // }
}

#formrun-wrap {
  background: #f2f2f2;
}
#formrun {
  background: white;
  padding: 4vw 0;
  margin-left: 24vw;
  margin-bottom: 4vw;
  width: 52vw;
  @include md {
    margin-left: 4vw;
    width: 92vw;
  }
  //   border: 1px black solid;
}

#page-head {
  .cover {
    // background: white;i
    background: #f2f2f2;
  }
  h1 p {
    color: black;
  }
}

.message-area {
  @include lg {
    margin-top: 2.4vw;
  }
  br.md {
    display: none;
  }
  p {
    margin: 0 auto;
    text-align: center;
    font-size: calc(7px + 0.7vw);
    line-height: 1.8;
    @include md {
      text-align: justify;
      margin-top: 4.8vw;
      width: 60vw;
      font-size: calc(9px + 0.9vw);
    }
  }
}

.link-area {
  width: 100vw;
  margin: 6.4vw 0 10.4vw;
  @include md {
    margin: 16vw 0 28vw;
  }
  a {
    margin: 0 auto;
    display: block;
    width: 24vw;
    padding: 1.2vw 0;
    border: 1px black solid;
    text-align: center;
    font-size: calc(6.4px + 0.64vw);
    user-select: none;
    transition: 0.3s;
    @include md {
      width: 48vw;
      padding: 3.2vw 0;
      font-size: calc(9px + 0.9vw);
    }

    &:hover {
      @include lg {
        color: white;
        border: #{$color-green}aa 1px solid;
        background: #{$color-green}aa;
        transition: 0.3s;
      }
    }
  }
}

.info-area {
  // margin-top: 9.6vw;
  margin-bottom: 4vw;
  @include md {
    margin-top: 8vw;
  }
  background: #000;
  .flex-area {
    position: relative;
    @include lg {
      //   max-width: 1560px;
      margin-left: 50vw;
      @include translate(-50%, 0);
      //   margin-left: calc(8px + 8vw);
      width: calc(320px + 56vw);
      display: flex;
      justify-content: space-around;
    }
    @include md {
      margin-left: 0vw;
      width: 100vw;
    }
    padding: 3.2vw 0;
    color: white;
    .inner-flex {
      @include lg {
        display: flex;
      }
    }
    .img-area {
      @include md {
        height: 4vw;
      }
      img {
        @include lg {
          position: relative;
          top: 50%;
          @include translate(0, -52%);
          width: 3.4vw;
          height: auto;
        }
        @include md {
          display: none;
          margin-top: 9.6vw;
          margin-left: -2vw;
          width: 8.8vw;
          height: auto;
        }
      }
    }
    .about-area {
      //   padding-left: 3.2vw;
      font-size: calc(7px + 0.7vw);
      line-height: 1.9;
      @include lg {
        margin-left: 1.6vw;
        // margin-right: 4vw;
        br.md {
          display: none;
        }
      }
      @include md {
        text-align: center;
        overflow-wrap: break-word;
        // padding-left: 10.4vw;
        // white-space: nowrap;
        font-size: 14px;
        .md-span {
          font-size: 18px;
          margin-left: -3px;
        }
      }
    }
    .phone-num-area {
      font-size: calc(7.9px + 0.79vw);
      line-height: 1.6;
      @include md {
        display: inline-block;
        font-size: 18px;
        margin: 6.4vw 49vw 9.6vw;
        @include translate(-50%, 0);
        white-space: nowrap;
        p {
          margin-top: 32px;
          //   margin-bottom: 24px;
          text-align: center;
        }
      }
      @include lg {
        br.md {
          display: none;
        }
      }
      span {
        font-size: calc(8px + 0.8vw);
        font-weight: bold;
        padding-left: 1.2vw;
        @include lg {
          &.md {
            display: none;
          }
        }
        @include md {
          padding-left: 1.2vw;
          font-size: 17px;
          &.lg {
            display: none;
          }
        }
      }
    }
  }
}
</style>
