import { render, staticRenderFns } from "./DlList05.vue?vue&type=template&id=7caef972"
import script from "./DlList05.vue?vue&type=script&lang=js"
export * from "./DlList05.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports