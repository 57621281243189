<template>
  <div class="news-content-01 news-content">
    <div class="img-wrapper">
      <div
        class="img-area news-img-08-01"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/npc-08-01.jpg') + ')',
        }"
      ></div>
    </div>
    <p>
      結晶化ガラス建材「パリトーン」と、弊社LED製品の動く光で、流水の表現を生み出せるか検証実験を行いました。<br /><br />「パリトーン」は、株式会社ウイング様が販売している透過性のあるガラス建材です。<br /><br />このガラス建材「パリトーン」内照光源として、弊社製品<a
        href="https://finelinks.co.jp/products-detail/3"
        target="_blank"
        rel="noopener"
        class="link-url2"
        >「LEDテープライト（デジタルタイプ）」</a
      >を組み込むことにより光の動きがどのように表現されるのか…？<br />結果は
      <a
        href="https://paritone.jp/blog/lab_009/"
        target="_blank"
        rel="noopener"
        class="link-url2"
        >ぱりとん君の実験室</a
      >をご覧下さい。
    </p>
    <br /><br />
    <p>
      この実験は、ビル壁面や飲食店内装、その他多数の実績を持つ
      <a
        href="https://paritone.jp/"
        target="_blank"
        rel="noopener"
        class="link-url2"
        >株式会社ウイング様</a
      >
      との共同実験です。
    </p>
    <!-- <a href="https://paritone.jp/" target="_blank" rel="noopener">
      <p class="link-url">
        <span class="mark">＞</span>株式会社ウイング様<br />
      </p>
    </a> -->
    <br /><br /><br />
    <div class="video">
      <youtube
        :video-id="videoId"
        :player-vars="playerVars"
        :width="videoWidth"
        :height="videoHeight"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoId: "HjhdcGJx9rY",
      widthRate: 0.76,
      heightRate: 0.76 * 0.5625,
      windowWidth: window.innerWidth,
      playerVars: {
        autoplay: 0,
        modestbranding: 1,
        rel: 0,
      },
    };
  },
  computed: {
    videoWidth: function() {
      return this.windowWidth * this.widthRate;
    },
    videoHeight: function() {
      return this.windowWidth * this.heightRate;
    },
  },
  methods: {
    handleResize: function() {
      this.windowWidth = window.innerWidth;
      //   this.height = window.innerHeight;
      if (window.innerWidth > 899) {
        this.widthRate = 0.76;
        this.heightRate = 0.76 * 0.5625;
      } else {
        this.widthRate = 0.92;
        this.heightRate = 0.92 * 0.5625;
      }
    },
  },
  mounted: function() {
    window.addEventListener("resize", this.handleResize);
    if (window.innerWidth > 899) {
      this.widthRate = 0.76;
      this.heightRate = 0.76 * 0.5625;
    } else {
      this.widthRate = 0.92;
      this.heightRate = 0.92 * 0.5625;
    }
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.img-wrapper {
  position: relative;
  width: 100%;
  margin: 0 0 4vw;
}
.img-wrapper:before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 高さを幅の75%に固定 */
}
.news-img-08-01 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.link-url {
  display: inline-block;
  padding: 0.8vw;
  padding-left: 0.2vw;
  font-size: calc(6.4px + 0.64vw);
  color: $color-green;
  .mark {
    margin-right: 0.4vw;
    font-size: calc(5.6px + 0.56vw);
  }
  @include lg {
    transition: 0.2s;
    &:hover {
      color: $color-green;
      opacity: 0.7;
    }
  }
}
.link-url2 {
  color: $color-green;
  font-weight: 500;
  transition: 0.3s;
  &:hover {
    opacity: 0.5;
  }
}
.video {
  @include md {
    margin-left: -2vw;
  }
}
</style>
