<template>
    <div style="position: relative;">
        <hooper class="hooper__hooper01" :settings="hooperSettings">
            <slide class="hooper__slide" v-for="n in content" :key="n.id">
                <slide-content-top :id='n'></slide-content-top>
            </slide>
            <hooper-pagination slot="hooper-addons" />
            <hooper-navigation slot="hooper-addons" />
        </hooper>

        <img src="../assets/img/mk-03.svg" alt="" class="left-mark">
        <img src="../assets/img/mk-04.svg" alt="" class="right-mark">
    </div>
</template>

<script>
  import {Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation} from 'hooper';
  import 'hooper/dist/hooper.css';
  import SlideContentTop from './SlideContentTop';
export default {
    methods: {
    },
    components: {
      Hooper,
      Slide,
      HooperPagination,
      HooperNavigation,
      SlideContentTop
    },
    data() {
      return {
        hooperSettings: {
          itemsToShow: 1,
          centerMode: true,
          wheelControl: false,
          infiniteScroll: true,
          autoPlay: true,
          playSpeed: 3000,
          transition: 480
        },
      }
    },
    props: {
        content: {
            // type: Object
        }
    },
    created() {
         if(window.innerWidth < 899 ){
            this.hooperSettings = {
                itemsToShow: 1,
                centerMode: true,
                wheelControl: true,
                infiniteScroll: true,
                autoPlay: true,
                playSpeed: 3000,
                transition: 480
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
 .hooper {
    width: $lg-width; height: $slider-top-height;
    margin: auto;
    text-align: center;
    @include md {
        width: 100vw;
        height: $md-slider-top-height;
    }
    &__hooper {

    }

    &__slide {
        border: #828282 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }

  }

    .left-mark,
    .right-mark {
        position: absolute;
        top: calc(#{$slider-top-height} / 2);
        width: 0.96vw;
        @include translate(0, -50%);
        pointer-events: none;
        @include md {
            margin-top: 8vw;
            top: calc(#{$md-slider-top-height} * 0.4);
            width: calc(8px + 0.96vw);
        }
    }
    .left-mark {
        left: calc(#{$lg-mar-lr} + 1.4vw);
        @include md {
            // left: calc(8vw + 19%);
            left: 7.2vw;
        }
    }
    .right-mark {
        right: calc(#{$lg-mar-lr} + 1.4vw);
        @include md {
            // right: calc(8vw + 19%);
            right: 7.2vw;
        }
    }
</style>

<style lang="scss">
@import "../assets/sass/global.scss";

.hooper__hooper01 {
        .hooper-list {
            position: relative;
            width: 100%;
            height: calc(#{$slider-top-height} + 4vw);
            @include md {
                width: 100vw;
                height: calc(#{$md-slider-top-height} + 27vw);
            }
            overflow: hidden;
            // overflow-x: hidden;
            // overflow-y: visible;
        }
        .hooper__slide {
            @include md {
                border: none;
            }
        }
        .hooper-navigation {
            position: relative;
            // top: 0;
            // width: 100%;
            // height: 36vw;
        .hooper-prev,
        .hooper-next {
            position: absolute;
            background: black;
            width: 4vw; height: $slider-top-height;
            @include md {
                // margin-top: 12vw;
                // top: $md-slider-top-height;
                // width: 48%;
                // height: calc(16px + 8vw);
                top: calc(#{$md-slider-top-height} * 0.4 + 3.2vw);

                width: 9.6vw; height: 9.6vw;
                opacity: 0.5;
                border-radius: 50%;
            }
            transition: 0.3s;
            @include translate(0, 0);
            svg {
                display: none;
            }
            &.is-disabled {
                opacity: 0.8;
            }
            &:hover {
                // background: $color-green;
                background: #555;
                // filter: brightness(1.15) saturate(1.05);
                transition: 0.12s;
            }
        }
        .hooper-prev {
            @include md {
                left: 4.4vw;
            }
        }
        .hooper-next {
            @include md {
                right: 4.4vw;
            }
        }
    }
    .hooper-track {
        position: absolute;
        top: 0; left: 5.6vw;
        width: 64.8vw;
        @include md {
            width: 80vw;
            left: 0;

            width: 100vw;
        }
        > li {
            position: relative;
            width: 64.8vw !important; height: $slider-top-height;
            margin-left: 11.2vw;
            @include md {
                // width: calc(80vw - 19.2vw) !important;
                width: calc(80vw) !important;
                height: $md-slider-top-height;
                // margin-left: calc(16vw + 3.2vw);
                // margin-left: 0;

                margin-left: 20vw;
            }
            &:first-of-type {
                margin-left: 0;
                @include md {
                    margin-left: 10vw;
                }
            }
        }
    }
    .hooper-pagination {
        @include md {
            bottom: calc(-12px + 22vw);
        }
    }
    .hooper-indicator {
        width: calc(3px + 0.7vw); height: calc(3px + 0.7vw);
        border-radius: 50%;
        margin: 0 0.48vw;
        border: #BDBDBD 1px solid;
        background: transparent;
        @include md {
            display: none;
            margin: 0 0vw;
            width: calc(12px + 2vw); height: calc(6px + 1.04vw);
            border-radius: unset;
        }
        &:hover {
            @include lg {
                background: $color-green;
                border: #BDBDBD00 1px solid;
                transition: 0.3s;
            }
        }
        &.is-active {
            background: #BDBDBD;
            border: #BDBDBD00 1px solid;
            @include md {
                // background: #{$color-green}99;
            }
        }
    }
}

</style>
