<template>
  <div>
    <footer>
      <div class="info-area">
        <div class="logo-area">
          <img src="../assets/logo_w.png" alt="" class="logo" />
          <p>トーエーテクノインターナショナル株式会社</p>
          <p>Ⓒ2024 Toei Techno International Inc.</p>
        </div>
        <div class="address-area">
          <div class="address">
            <p>東京オフィス</p>
            <p>
              〒105-0013<br />
              東京都港区浜松町1-6-2 丸神ビル5F<br />
              TEL：03-6450-1486 / FAX：03-6450-1487
            </p>
          </div>

          <div class="address">
            <p>大阪オフィス</p>
            <p>
              〒542-0081<br />
              大阪府大阪市中央区南船場4-8-6 渕上ビル3F<br />
              TEL：06-4704-8778 / FAX：06-6210-2388
            </p>
          </div>
        </div>
      </div>

      <div class="site-map">
        <p class="title">サイトマップ</p>
        <router-link to="/" class="link">
          <p>> <span class="link-name">TOP</span></p>
        </router-link>
        <router-link to="/news" class="link">
          <p>> <span class="link-name">新着情報</span></p>
        </router-link>
        <router-link to="/products" class="link">
          <p>> <span class="link-name">製品</span></p>
        </router-link>
        <router-link to="/works" class="link">
          <p>> <span class="link-name">納入事例</span></p>
        </router-link>
        <router-link to="/information" class="link">
          <p>> <span class="link-name">会社概要</span></p>
        </router-link>
        <router-link to="/privacypolicy" class="link">
          <p>> <span class="link-name">プライバシーポリシー</span></p>
        </router-link>
        <router-link to="/download" class="link">
          <p>> <span class="link-name">ダウンロード</span></p>
        </router-link>
        <br />
        <hr />
        <br />
        <p class="title">関連ページ</p>
        <a href="https://global.toei-group.com/" target="_blank" class="link">
          <p>> <span class="link-name">トーエーテクノグループ</span></p>
        </a>
        <a href="https://studio.toei-group.com/" target="_blank" class="link">
          <p>> <span class="link-name">クリエイティブ</span></p>
        </a>
      </div>

      <div class="product-link">
        <p class="title">製品</p>
        <div v-for="n in 12" :key="n.id">
          <router-link
            :to="{ name: 'products-detail', params: { id: n } }"
            class="link"
          >
            <p>
              >
              <span class="link-name"
                >{{ products[n].name
                }}<span v-if="products[n].name_type != ''"
                  >({{ products[n].name_type }})</span
                ></span
              >
            </p>
          </router-link>
        </div>
      </div>
    </footer>
    <div class="marubeni">
      <a
        href="https://www.arch-log.com/search/ja/?key=%E3%83%95%E3%82%A1%E3%82%A4%E3%83%B3%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%B9&bim=false&d=desc&o=popularity_i&ec=false"
        target="_blank"
        ><img
          rel="preload"
          alt="丸紅アークログ"
          src="../assets/img/marubenilogo.png"
      /></a>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";
export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
footer {
  background: black;
  @include lg {
    width: calc(100vw - 8vw * 2);
    padding: 5.6vw 8vw 1vw;
    display: flex;
    justify-content: space-between;
  }
  @include md {
    padding: 14.4vw 12% 16vw;
    margin-bottom: -1px;
  }
  p {
    color: white;
    font-size: calc(6px + 0.6vw);
    line-height: 2;
    letter-spacing: 0.2em;
    @include md {
      font-size: calc(8.8px + 0.88vw);
      line-height: 2.8;
    }
  }
  .title {
    font-size: calc(6px + 0.6vw);
    margin-bottom: 1.04vw;
    letter-spacing: 0.3em;
    @include md {
      font-size: calc(8.8px + 0.88vw);
    }
  }
  .link {
    span.link-name {
      opacity: 0.8;
      // padding-left: 0px;
      // padding-right: 1px;
      // transition: 0.2s;
    }
    &:hover {
      span.link-name {
        // padding-top: 1px;
        // padding-left: 1px;
        padding-right: 0px;
        opacity: 0.6;
        // transition: 0.2s;
      }
    }
  }
  .info-area {
    // margin-left: $lg-mar-lr;
    .logo-area p {
      line-height: 2;
    }
    .logo {
      width: calc(68px + 6.8vw);
      margin-bottom: 0.8vw;
      @include md {
        width: calc(96px + 8.8vw);
        margin-bottom: 2.8vw;
        margin-left: -1.6vw;
      }
    }
    .address-area {
      margin-top: 4vw;
      @include md {
        margin-top: 8vw;
      }
      .address {
        @include lg {
          display: flex;
          margin-top: 2vw;
        }
        @include md {
          margin-top: 5.6vw;
        }
        p {
          letter-spacing: 0.103em;
          @include md {
            line-height: 2.3;
          }
          &:first-of-type {
            @include lg {
              width: 7.2vw;
            }
          }
        }
      }
    }
  }
  .site-map,
  .product-link {
    @include lg {
      p {
        font-size: calc(6px + 0.6vw);
        line-height: 2.3;
        letter-spacing: 0.24em;
      }
    }
    @include md {
      p {
        font-size: calc(8.8px + 0.88vw);
      }
    }
  }
  .site-map {
    @include lg {
      margin-left: calc(-80px + 8.6vw);
    }
    @include md {
      margin-top: 9.6vw;
    }
  }
  .product-link {
    @include lg {
      margin-right: calc(-20px + 4.4vw);
    }
    @include md {
      margin-top: 8.8vw;
    }
  }
}

.marubeni {
  background-color: #000;
  width: calc(100vw - 8vw * 2);
  padding: 0 8vw 8vw;
  a {
    width: 230px;
    display: block;
    background-color: #fff;
    padding: 10px;
    img {
      width: 100%;
    }
  }
  @include lg {
  }
  @include md {
  }
}
</style>
