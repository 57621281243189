<template>
  <div class="works-detail">
    <!-- <div class="header-bg"></div> -->
    <nav class="topic-path">
      <router-link to="/" class="link has-link">TOP</router-link>
      <p>></p>
      <router-link to="/works" class="link has-link">納入事例</router-link>
      <p>></p>
      <div class="link">{{ works[id].name }}</div>
    </nav>

    <main>
      <article>
        <section>
          <h1 class="detail-title">{{ works[id].name }}</h1>

          <div class="detail-tag tag">
            <p v-for="tag in works[id].tags" :key="tag.id">
              {{ tags[tag] }}
            </p>
          </div>

          <div class="slider" v-if="works[id].length > 1">
            <slider
              :id="works[id].pid"
              :length="Number(works[id].length)"
            ></slider>
          </div>
          <div
            class="slider single-img"
            v-else
            :style="{
              'background-image':
                'url(' +
                require('@/assets/img/wp-' + works[id].pid + '-01.jpg') +
                ')',
            }"
          />
        </section>

        <section>
          <div class="about-area" v-if="works[id].about != ''">
            <h3 class="title">概要</h3>
            <!-- <span></span> -->
            <div class="product-name-wrap">
              <div class="product-name">
                <!-- <p>{{ works[id].about }}</p> -->
                <p v-html="works[id].about"></p>
              </div>
            </div>
          </div>

          <div class="movie-name-area" v-if="works[id].movie_titles[0] != ''">
            <h3 class="title">動画URL</h3>
            <!-- <span></span> -->
            <div class="product-name-wrap">
              <div
                v-for="n in works[id].movie_titles.length"
                :key="n.id"
                class="product-name"
              >
                <p>{{ works[id].movie_titles[n] }}</p>
                <!-- <p style="padding-left: 1.2em;">{{ works[id].movie_links[n] }}</p> -->
                <a
                  class="movie-link"
                  :href="works[id].movie_links[n]"
                  target="_blank"
                  rel="noopener"
                  >{{ works[id].movie_links[n] }}
                </a>
              </div>
            </div>
          </div>

          <div class="product-name-area" v-if="works[id].charge != ''">
            <h3 class="title">担当者</h3>
            <!-- <span></span> -->
            <div class="product-name-wrap">
              <div class="product-name">
                <p>{{ works[id].charge }}</p>
              </div>
            </div>
          </div>

          <div class="product-name-area" v-if="works[id].products_name != ''">
            <h3 class="title">使用製品</h3>
            <!-- <span></span> -->
            <div class="product-name-wrap">
              <div class="product-name">
                <!-- <p>{{ works[id].products_name }}</p> -->
                <p v-html="works[id].products_name"></p>
              </div>
            </div>
          </div>

          <div class="link-product-area" v-if="works[id].products[0] != ''">
            <div
              v-for="product in works[id].products"
              :key="product.id"
              class="link-product-wrap"
            >
              <router-link
                :to="{ name: 'products-detail', params: { id: product } }"
                class="link-product"
              >
                <div class="img-area-wrap">
                  <div
                    :class="[{ 'no-light': Number(product) >= 13 }, 'img-area']"
                    :style="{
                      'background-image':
                        'url(' +
                        require('@/assets/img/ppt-' +
                          products[product].id +
                          '.jpg') +
                        ')',
                    }"
                  ></div>
                </div>
                <div class="text-area">
                  <p>{{ products[product].name }}</p>
                  <p class="type" v-if="products[product].name_type != ''">
                    {{ products[product].name_type }}
                  </p>
                  <p class="series">{{ products[product].series }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </section>
      </article>
    </main>

    <router-link
      :to="{ name: 'works', hash: '#' + works[id].id }"
      class="link-back"
    >
      <p>納入事例一覧へ</p>
    </router-link>
  </div>
</template>

<script>
import Slider from "../components/SliderWorks";
import works from "../assets/json/works.json";
import tags from "../assets/json/tagName.json";
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      works: works,
      tags: tags,
      products: products,
    };
  },
  methods: {},
  components: {
    Slider,
  },
  props: {
    id: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
$trTimePrLink: 0.2s;
.header-bg {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  // background: #79797960;
  background: #7a7a7aa2;
}
.works-detail {
  margin-top: 80px;
  margin-left: $lg-mar-lr;
  width: calc(100vw - #{$lg-mar-lr} * 2);
  @include md {
    margin-left: 6vw;
    width: 88vw;
    .tag p {
      width: 16vw;
      font-size: calc(8.4px + 0.84vw);
    }
  }
}
.slider {
  margin-top: 3.2vw;
  width: 100%;
  height: 60vw;
  &.single-img {
    margin-bottom: 4.8vw;
    height: 52vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include md {
      margin-bottom: 8vw;
      height: calc(78vw * 0.86);
    }
  }
  @include md {
    margin-top: 8vw;
    margin-left: -6vw;
    margin-bottom: 4vw;
    width: 100vw;
    height: 80vw;
  }
}
.about-area,
.product-name-area,
.movie-name-area {
  position: relative;
  display: flex;
  margin-bottom: 2.1em;
  padding-left: 0.2em;
  padding-bottom: 1.4em;
  border-bottom: $color-gray-1 1px solid;
  font-size: calc(7.2px + 0.72vw);
  line-height: calc(13px + 1.3vw);
  @include md {
    font-size: calc(8px + 0.8vw);
  }
  > span {
    font-weight: normal;
    width: 1px;
    height: calc(13px + 1.3vw);
    background: $color-gray-1;
  }
  .title {
    font-weight: normal;
    width: 16%;
    margin: 0.4em 0;
    font-size: calc(6.8px + 0.68vw);
    line-height: calc(13px + 1.3vw);
    @include md {
      width: 18vw;
      font-size: calc(8px + 0.8vw);
    }
  }
  .product-name-wrap {
    position: relative;
    padding: 0 2vw 0 3.2vw;
    width: 84%;
    border-left: 1px $color-gray-1 solid;
    @include md {
      padding-left: 2.4vw;
    }
  }
  .product-name {
    box-sizing: border-box;
    @include lg {
      display: flex;
    }
    @include md {
      // white-space: nowrap;
      margin-bottom: 0.64em;
    }
    p {
      position: relative;
      margin: 0.4em 0;
      @include md {
        left: 2.4vw;
        margin-bottom: 0.12em;
      }
      // @include lg {
      /deep/ a {
        transition: 0.2s;
        &:hover {
          color: $color-green;
          transition: 0.2s;
        }
      }
      // }
    }
    .sep {
      // margin-right: 0.8vw;
    }
  }
}
.movie-name-area {
  .product-name {
    p {
      left: unset;
      position: relative;
      top: unset;
      @include translate(0, 0);
    }
  }
}
a.movie-link {
  margin: 0.4em 0;
  padding-left: 1.2em;
  @include lg {
    &:hover {
      color: $color-green;
      filter: brightness(1.2) saturate(1.1);
      // opacity: 0.6;
    }
  }
}
.link-product-area {
  position: relative;
  margin: 4vw 0 1.6vw;
  display: flex;
  justify-content: flex-start;
  @include md {
    margin-top: 6.4vw;
    margin-left: -2vw;
    width: 98.8vw;
  }
  .link-product-wrap {
    position: relative;
  }
}
.link-back {
  position: relative;
  display: inline-block;
  text-decoration: none;
  border-radius: 4px;
  @include lg {
    margin: 5.6vw 0 8vw;
    left: calc(50vw - #{$lg-mar-lr});
    @include translate(-50%, 0);
    width: 20vw;
    border: 1px black solid;
  }
  transition: 0.2s;
  @include md {
    left: -2vw;
    width: 92vw;
    background: #{$color-green}bb;
    filter: brightness(1.05);
    margin: 6.4vw 0 5.6vw;
  }
  &:hover {
    @include lg {
      // border: white 1px solid;
      background: #{$color-green}bb;
      border: #{$color-green}bb 1px solid;
      filter: brightness(1.15) saturate(1.05);
      box-shadow: 0px 2px 16px rgba(33, 150, 83, 0.28);
      transition: all 0.3s, box-shadow 0.3s;
      p {
        // background: #{$color-green};
        // filter: brightness(1.15) saturate(1.05);
        opacity: 0.75;
        color: white;
        transition: 0.2s;
      }
    }
  }

  p {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 1em 0;
    font-size: calc(7px + 0.7vw);
    text-align: center;
    @include md {
      font-size: calc(9.6px + 0.96vw);
      // border-bottom: 1px black solid;
      color: white;
      padding: 4vw 0.04em;
    }
    transition: 0.2s;
  }
}
</style>
