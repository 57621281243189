<template>
  <div class="download-page">
    <div id="page-head">
      <div class="bg-img"></div>
      <div class="cover"></div>
      <h1>
        <p>ダウンロード</p>
        <p>DOWNLOAD</p>
      </h1>
    </div>

    <main>
      <!-- 1 LEDテープライト（白色・電球色タイプ）-->
      <!-- <section id="product-01" class="download-list">
                <h2 class="title-bar-01">{{ products[1].name }}</h2>
                <router-link
                    :to="{ name: 'products-detail', params: { id: 1 }}"
                    class="list-link-router"
                >
                    <div class="list-link">
                        <p>製品の詳細情報をみる</p>
                        <img src="../assets/img/mk-02.svg" alt="">
                    </div>
                </router-link>

                <dl-list-01 />
            </section> -->

      <!-- ループで表示する -->
      <div v-for="n in dlListNum" :key="n.id" class="download-list">
        <section :id="`product-${products[n].id}`">
          <router-link
            :to="{ name: 'products-detail', params: { id: Number(n) } }"
          >
            <h2 :class="`title-bar-01`">
              {{ products[n].name
              }}<span v-if="products[n].name_type != ''">
                ({{ products[n].name_type }})</span
              >
            </h2>
            <div class="list-link-router">
              <div class="list-link">
                <p>製品の詳細ページへ</p>
                <img src="../assets/img/mk-02.svg" alt="" />
              </div>
            </div>
          </router-link>

          <!-- <component :is="getDownloadName(Number(n))"></component> -->
          <component :is="`dl-list-${products[n].id}`"></component>
        </section>
      </div>

      <!-- 2 LEDテープライト（RGBフルカラータイプ） -->
      <!-- <dl-list-02 /> -->

      <!-- 3 LEDテープライト（デジタルタイプ） -->
      <!-- <dl-list-03 /> -->

      <!-- 4 VENUS -->
      <!-- <dl-list-04 /> -->

      <!-- 5 LEDピクセルライト -->
      <!-- <dl-list-05 /> -->

      <!-- 6 LEDクラスタ― -->
      <!-- <dl-list-06 /> -->

      <!-- 9 STORM ハイパワー投光器（1パネル制御タイプ） -->
      <!-- <dl-list-09 /> -->

      <!-- 10 STORM ハイパワー投光器（3パネル制御タイプ） -->
      <!-- <dl-list-10 /> -->

      <!-- 12 DENEB ウォールウォッシャー（スリムタイプ） -->
      <!-- <dl-list-12 /> -->

      <!-- 11 DENEB ウォールウォッシャー（個別制御タイプ） -->
      <!-- <dl-list-11 /> -->

      <!-- 8 デジタルストリングライト -->
      <!-- <dl-list-08 /> -->

      <!-- 7 LEDモジュール -->
      <!-- <dl-list-07 /> -->

      <!-- 13 電源 -->
      <!-- <dl-list-13 /> -->

      <!-- 14 調光用コントローラー -->
      <!-- <dl-list-14 /> -->

      <!-- 15 アルミバー -->
      <!-- <dl-list-15 /> -->
    </main>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

import DlList01 from "../components/DlList01";
import DlList02 from "../components/DlList02";
import DlList03 from "../components/DlList03";
import DlList04 from "../components/DlList04";
import DlList05 from "../components/DlList05";
import DlList06 from "../components/DlList06";
import DlList07 from "../components/DlList07";
import DlList08 from "../components/DlList08";
import DlList09 from "../components/DlList09";
import DlList10 from "../components/DlList10";
import DlList11 from "../components/DlList11";
import DlList12 from "../components/DlList12";
import DlList13 from "../components/DlList13";
import DlList14 from "../components/DlList14";
import DlList15 from "../components/DlList15";

export default {
  data: () => {
    return {
      products: products,
      dlListNum: [1, 2, 3, 4, 5, 6, 9, 10, 12, 11, 8, 7, 13, 14, 15],
    };
  },
  components: {
    DlList01,
    DlList02,
    DlList03,
    DlList04,
    DlList05,
    DlList06,
    DlList07,
    DlList08,
    DlList09,
    DlList10,
    DlList11,
    DlList12,
    DlList13,
    DlList14,
    DlList15,
  },
  beforeUpdate() {
    const hash = this.$route.hash;
    if (hash && hash.match(/^#.+$/)) {
      this.$scrollTo(hash);
    }
  },
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
    getDownloadName: function(num) {
      if (Number(num) < 10) {
        return "dl-list-0" + num;
      } else {
        return "dl-list-" + num;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
#page-head {
  .cover {
    background: white;
  }
  h1 p {
    color: black;
  }
}

main {
  margin-left: $lg-mar-lr;
  width: $lg-width;
  margin-bottom: 7.2vw;

  @include md {
    width: 92vw;
    margin-left: 4vw;
  }
}
</style>
