var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content-01 news-content"},[_c('div',{staticClass:"img-wrapper"},[_c('div',{staticClass:"img-area news-img-07-01",style:({
        'background-image':
          'url(' + require('@/assets/img/npc-07-01.jpg') + ')',
      })})]),_vm._m(0),_c('br'),_vm._m(1),_c('br'),_vm._m(2),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 4月某日、弊社事務所にて、結晶化ガラス建材「パリトーン」と弊社LED製品の組み合わせによる検証実験を行いました。"),_c('br'),_vm._v("「パリトーン」は、株式会社ウイング様が販売している透過性のあるガラス建材です。"),_c('br'),_vm._v("この「パリトーン」内部にLED灯具を仕込むと、どのような透光結果になるかを実験いたしました。"),_c('br'),_c('br'),_vm._v("この実験は、ビル壁面や飲食店内装、その他多数の実績を持つウイング様との共同実験です。"),_c('br'),_vm._v("実験の詳細は下記リンクご覧下さい。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://paritone.jp/blog/lab_008/","target":"_blank","rel":"noopener"}},[_c('p',{staticClass:"link-url"},[_c('span',{staticClass:"mark"},[_vm._v("＞")]),_vm._v("ぱりとん君の実験室")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://paritone.jp/","target":"_blank","rel":"noopener"}},[_c('p',{staticClass:"link-url"},[_c('span',{staticClass:"mark"},[_vm._v("＞")]),_vm._v("株式会社ウイング様"),_c('br'),_vm._v(" (外壁や屋内装飾で好評の結晶化ガラス建材「パリトーン」を販売している企業様です。) ")])])
}]

export { render, staticRenderFns }