<template>
  <!-- 2 LEDテープライト（RGBフルカラータイプ） -->
  <div>
    <div class="table-area">
      <h3>【12V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN5050RGB-12</td>
          <td rowspan="1">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            <a
              href="../specifications/YN5050RGB-12.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">
            <a href="../cad/YN5050RGB-12.dxf" download="YN5050RGB-12.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>【24V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN5050RGB-24</td>
          <td rowspan="1">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">
            <a
              href="../specifications/YN5050RGB-24.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">
            <a href="../cad/YN5050RGB-24.dxf" download="YN5050RGB-24.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
