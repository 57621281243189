<template>
  <div class="products-detail-content">
    <h2>種類一覧</h2>
    <div class="list-wrap">
      <!-- 2 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '02' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          5050タイプ 60チップ版
        </h3>
        <p>
          SMD型LEDチップ搭載のLEDテープライトの中でも、最も高輝度である5050タイプのLED照明器具です。1mあたり60個の5050タイプのLEDを16.7mmピッチで配置しているため輝度を実現しています。
        </p>
      </div>
      <!-- 3 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '03' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          3528タイプ 60チップ版
        </h3>
        <p>
          5050タイプよりもかなり消費電力を抑えた3528タイプのLED照明器具です。
          5050タイプ同様、様々な白色を揃えており、保護等級もIP68ですが、光量が減少してしまいますので、状況に合わせてご選択ください。
        </p>
      </div>
      <!-- 4 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '04' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          3528タイプ 120チップ版
        </h3>
        <p>
          かなり消費電力を抑えた3528タイプのLED照明器具です。60チップ版よりもLEDチップの間隔が短いので、”ライン照明”というイメージを演出出来ます。明るさのイメージは、5050タイプの60チップ版と3528タイプの60チップ版の中間になります。
        </p>
      </div>
    </div>

    <h2>カラーバリエーション</h2>
    <div class="list-wrap">
      <!-- 5 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '05' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          色温度：2500K（電球色）
        </h3>
        <p>
          最も色温度が低く、イメージとしてはロウソクの明かりのようなアンティークで優しく暖かい演出をすることができます。
        </p>
      </div>
      <!-- 6 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '06' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          色温度：2800K（電球色）
        </h3>
        <p>
          2番目に色温度が低い電球色で、一般白熱ランプと同じ明るさです。日の出、日没のような色合いで落ち着いた雰囲気を演出できます。
        </p>
      </div>
      <!-- 7 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '07' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          色温度：3000K（電球色）
        </h3>
        <p>
          2800K（ケルビン）よりも白みが強く、オレンジ色というよりも黄色に近い電球色です。馴染みのある温かみを演出出来るので使用用途が幅広い人気のLEDテープライトです。
        </p>
      </div>
      <!-- 8 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '08' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          色温度：4000K（温白色）
        </h3>
        <p>
          電球色の赤みは殆ど抜けて、白みを感じられます。昼白色にはないほんのり温かみのある白色なので普段の生活の間接照明としても人気なテープLEDです。
        </p>
      </div>
      <!-- 9 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '09' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          色温度：6000K（昼白色）
        </h3>
        <p>
          青み掛かった白色になります。太陽の光のような色で、白さを際立たせたい時によく使われる展示会などで人気のテープLEDです。
        </p>
      </div>
      <!-- 10 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '10' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          色温度：7500K（昼白色）
        </h3>
        <p>
          6000K（ケルビン）よりもさらに青みが強くなり、ほぼオレンジ色は感じられなくなる昼白色です。涼しげな明かりなのでクールな演出ができます。
        </p>
      </div>
    </div>

    <h2>FLEXIA LEDテープライトの特徴</h2>
    <div class="list-wrap">
      <!-- 11 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '11' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          1. 高性能な防水性と防塵性
        </h3>
        <p>
          シリコン樹脂で保護された基盤全体をチューブで360°覆うことで高寿命、保護等級IP68準拠の防塵性・防水性を実現！また、ハイパワーのLEDチップが等間隔に並んでいるため、従来の白熱灯やLEDに比べとても明るい上に省エネ性も高く、常時点灯していても電気代は安く済みます。
        </p>
      </div>
      <!-- 12 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '12' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          2. 好きな長さにカット可能
        </h3>
        <p>
          等間隔で「カットポイント」が設けられており好きな長さでカットすることができます。間違ってカットしてしまってもはんだ付けで再び繋ぐことも可能ですし、両面テープでは貼り付けにくい出っ張り等は導線で跨がせることにより従来のLEDでは表現できなかった照明効果を発揮できます。
        </p>
      </div>
      <!-- 13 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '13' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          3. 扱いやすい極薄仕様で曲げられる
        </h3>
        <p>
          幅10.5mm、厚さ3.5mmと幅が短く極薄仕様！強力な両面テープで様々な場所に施工可能です。また、FLEXIAは光軸方向に曲げることが可能なLEDです。その名の通りテープのように曲げることが可能なので、直線だけでなく、曲面・曲線施工に最適です。
        </p>
      </div>
    </div>

    <h2>専用オプション品</h2>
    <div class="list-wrap">
      <!-- 14 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '14' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          5050タイプ専用アルミバー
        </h3>
        <p>
          「高輝度」な5050タイプに取り付けが可能なアルミバーです。放熱に優れ、ライン照明にはおすすめです。
        </p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 15 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
      <!-- 15 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '15' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          3528タイプ専用アルミバー
        </h3>
        <p>
          「省エネ」な3528タイプに取り付けが可能なアルミバーです。放熱に優れ、ライン照明にはおすすめです。
        </p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 15 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
      <!-- 16 -->
      <div class="list">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '16' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          角設置専用アルミバー
        </h3>
        <p>
          角に設置するために断面が三角形になっているアルミバーです。どのLEDテープライトにもご使用頂けます。
        </p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 15 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
      <!-- 17 -->
      <div class="list hasRm">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '17' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          ドットレス専用アルミバー
        </h3>
        <p class="hasHeight">
          他のアルミバーよりも深さがあり、より粒感をなくした美しい光を演出できます。どのLEDテープライトにもご使用頂けます。
        </p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 15 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
        <div class="recommended">
          <p>おすすめ</p>
        </div>
      </div>
      <!-- 18 -->
      <div class="list hasRm">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '18' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          記憶型リモコン式調光器
        </h3>
        <p class="hasHeight"></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
      <!-- 19 -->
      <div class="list hasRm">
        <div
          class="img-area"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/pp-01-' + '19' + '.jpg') + ')',
          }"
        ></div>
        <h3>
          リモコン式調光器
        </h3>
        <p class="hasHeight"></p>
        <router-link
          :to="{ name: 'products-detail', params: { id: 14 } }"
          class="link"
        >
          詳細はこちら
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.hasHeight {
  // height: 5.2em;
}
</style>
