var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-detail-content"},[_c('h2',[_vm._v("種類一覧")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '02' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 5050タイプ 60チップ版 ")]),_c('p',[_vm._v(" SMD型LEDチップ搭載のLEDテープライトの中でも、最も高輝度である5050タイプのLED照明器具です。1mあたり60個の5050タイプのLEDを16.7mmピッチで配置しているため輝度を実現しています。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '03' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 3528タイプ 60チップ版 ")]),_c('p',[_vm._v(" 5050タイプよりもかなり消費電力を抑えた3528タイプのLED照明器具です。 5050タイプ同様、様々な白色を揃えており、保護等級もIP68ですが、光量が減少してしまいますので、状況に合わせてご選択ください。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '04' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 3528タイプ 120チップ版 ")]),_c('p',[_vm._v(" かなり消費電力を抑えた3528タイプのLED照明器具です。60チップ版よりもLEDチップの間隔が短いので、”ライン照明”というイメージを演出出来ます。明るさのイメージは、5050タイプの60チップ版と3528タイプの60チップ版の中間になります。 ")])])]),_c('h2',[_vm._v("カラーバリエーション")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '05' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 色温度：2500K（電球色） ")]),_c('p',[_vm._v(" 最も色温度が低く、イメージとしてはロウソクの明かりのようなアンティークで優しく暖かい演出をすることができます。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '06' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 色温度：2800K（電球色） ")]),_c('p',[_vm._v(" 2番目に色温度が低い電球色で、一般白熱ランプと同じ明るさです。日の出、日没のような色合いで落ち着いた雰囲気を演出できます。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '07' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 色温度：3000K（電球色） ")]),_c('p',[_vm._v(" 2800K（ケルビン）よりも白みが強く、オレンジ色というよりも黄色に近い電球色です。馴染みのある温かみを演出出来るので使用用途が幅広い人気のLEDテープライトです。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '08' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 色温度：4000K（温白色） ")]),_c('p',[_vm._v(" 電球色の赤みは殆ど抜けて、白みを感じられます。昼白色にはないほんのり温かみのある白色なので普段の生活の間接照明としても人気なテープLEDです。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '09' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 色温度：6000K（昼白色） ")]),_c('p',[_vm._v(" 青み掛かった白色になります。太陽の光のような色で、白さを際立たせたい時によく使われる展示会などで人気のテープLEDです。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '10' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 色温度：7500K（昼白色） ")]),_c('p',[_vm._v(" 6000K（ケルビン）よりもさらに青みが強くなり、ほぼオレンジ色は感じられなくなる昼白色です。涼しげな明かりなのでクールな演出ができます。 ")])])]),_c('h2',[_vm._v("FLEXIA LEDテープライトの特徴")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '11' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 1. 高性能な防水性と防塵性 ")]),_c('p',[_vm._v(" シリコン樹脂で保護された基盤全体をチューブで360°覆うことで高寿命、保護等級IP68準拠の防塵性・防水性を実現！また、ハイパワーのLEDチップが等間隔に並んでいるため、従来の白熱灯やLEDに比べとても明るい上に省エネ性も高く、常時点灯していても電気代は安く済みます。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '12' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 2. 好きな長さにカット可能 ")]),_c('p',[_vm._v(" 等間隔で「カットポイント」が設けられており好きな長さでカットすることができます。間違ってカットしてしまってもはんだ付けで再び繋ぐことも可能ですし、両面テープでは貼り付けにくい出っ張り等は導線で跨がせることにより従来のLEDでは表現できなかった照明効果を発揮できます。 ")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '13' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 3. 扱いやすい極薄仕様で曲げられる ")]),_c('p',[_vm._v(" 幅10.5mm、厚さ3.5mmと幅が短く極薄仕様！強力な両面テープで様々な場所に施工可能です。また、FLEXIAは光軸方向に曲げることが可能なLEDです。その名の通りテープのように曲げることが可能なので、直線だけでなく、曲面・曲線施工に最適です。 ")])])]),_c('h2',[_vm._v("専用オプション品")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '14' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 5050タイプ専用アルミバー ")]),_c('p',[_vm._v(" 「高輝度」な5050タイプに取り付けが可能なアルミバーです。放熱に優れ、ライン照明にはおすすめです。 ")]),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 15 } }}},[_vm._v(" 詳細はこちら ")])],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '15' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 3528タイプ専用アルミバー ")]),_c('p',[_vm._v(" 「省エネ」な3528タイプに取り付けが可能なアルミバーです。放熱に優れ、ライン照明にはおすすめです。 ")]),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 15 } }}},[_vm._v(" 詳細はこちら ")])],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '16' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 角設置専用アルミバー ")]),_c('p',[_vm._v(" 角に設置するために断面が三角形になっているアルミバーです。どのLEDテープライトにもご使用頂けます。 ")]),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 15 } }}},[_vm._v(" 詳細はこちら ")])],1),_c('div',{staticClass:"list hasRm"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '17' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" ドットレス専用アルミバー ")]),_c('p',{staticClass:"hasHeight"},[_vm._v(" 他のアルミバーよりも深さがあり、より粒感をなくした美しい光を演出できます。どのLEDテープライトにもご使用頂けます。 ")]),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 15 } }}},[_vm._v(" 詳細はこちら ")]),_vm._m(0)],1),_c('div',{staticClass:"list hasRm"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '18' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" 記憶型リモコン式調光器 ")]),_c('p',{staticClass:"hasHeight"}),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1),_c('div',{staticClass:"list hasRm"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-01-' + '19' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" リモコン式調光器 ")]),_c('p',{staticClass:"hasHeight"}),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'products-detail', params: { id: 14 } }}},[_vm._v(" 詳細はこちら ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recommended"},[_c('p',[_vm._v("おすすめ")])])
}]

export { render, staticRenderFns }