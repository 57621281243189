<template>
  <div class="news-page">
    <div id="page-head">
      <div class="bg-img"></div>
      <div class="cover"></div>
      <h1>
        <p>新着情報</p>
        <p>NEWS</p>
      </h1>
    </div>

    <main>
      <div v-for="news in reversenewslist" :key="news">
        <section v-if="news.id != '00'" class="news-link">
          <router-link
            :to="{ name: 'news-detail', params: { id: Number(news.id) } }"
            class="content"
          >
            <div
              class="img-area"
              :style="{
                'background-size': 'contain',
                'background-image':
                  'url(' + require('@/assets/img/np-' + news.id + '.jpg') + ')',
              }"
            ></div>
            <div class="text-area">
              <div class="date">{{ news.date }} | {{ news.type }}</div>
              <h2>{{ news.title }}</h2>
            </div>
          </router-link>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import newslist from "../assets/json/news.json";

export default {
  data: () => {
    return {
      newslist: newslist,
    };
  },
  computed: {
    reversenewslist() {
      return this.newslist.slice().reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

#page-head {
  .bg-img {
    background: url("../assets/img/np-00.jpg") no-repeat center center/cover;
  }
  .cover {
    background: #0b156c28;
  }
}

main {
  margin: 8vw 0;
  margin-left: $lg-mar-lr;
  width: calc(100vw - #{$lg-mar-lr} * 2);
  @include md {
    width: 88vw;
    margin-left: 6vw;
  }
}
.news-link {
  margin-top: 2.4vw;
  @include lg {
    display: flex;
  }
  width: 100%;
  height: calc(40px + 16vw);
  @include md {
    margin-top: 4vw;
    height: unset;
  }
  section {
    width: 100%;
  }
  .content {
    position: relative;
    display: flex;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    @include lg {
      transition: 0.3s;
      &:hover {
        @include transform(scale(1.01));
        box-shadow: 0.2vw 4px 9px rgba(0, 0, 0, 0.15);
      }
    }
    @include md {
      display: block;
      height: 64vw;
    }
  }
  .img-area {
    // width: 38%;
    display: inline-block;
    width: calc(40px + 16vw);
    height: calc(40px + 16vw);
    @include md {
      width: 100%;
      height: 40vw;
    }
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .text-area {
    box-sizing: border-box;
    @include lg {
      width: 62%;
      padding: 3.2vw;
    }
    @include md {
      width: 100%;
      padding: 4vw;
    }
    p,
    h2,
    a {
      font-size: calc(6.4px + 0.64vw);
      font-weight: normal;
      text-align: justify;
      line-height: 1.7;
      @include md {
        font-size: calc(9px + 0.9vw);
      }
    }
    a {
      @include lg {
        font-size: calc(4px + 0.8vw);
      }
    }
    .product-name-area {
      font-size: calc(3px + 0.7vw);
      @include md {
        font-size: calc(9px + 0.9vw);
      }
    }
    .date {
      font-size: calc(7.2px + 0.72vw);
      @include md {
        position: absolute;
        top: 0;
        left: 0;
        background: $color-green;
        padding: 1.6vw 2.4vw;
        color: white;
        font-size: calc(9.6px + 0.96vw);
      }
    }
    h2 {
      margin: 1.6vw 0 0;
      font-size: calc(8.4px + 0.84vw);
      font-weight: 500;
      @include md {
        position: absolute;
        left: 2.4vw;
        bottom: 2.4vw;
        width: calc(100% - 4.8vw);
        height: 16vw;
        font-size: calc(11px + 1.1vw);
      }
    }
    .description {
      letter-spacing: 0.02em;
      @include lg {
        width: 90%;
        font-size: calc(4px + 0.74vw);
      }
      @include md {
        line-height: 1.4;
      }
    }
    a {
      display: inline-block;
      &:last-of-type {
        margin: 0 0 0.8vw;
        padding: 0.2vw 0.08vw 0.8vw;
      }
      &:first-of-type {
        padding-top: 0.32vw;
        margin-top: 0.64vw;
      }
      @include md {
        padding: calc(2px + 1.6vw) 0;
      }
      span {
        opacity: 0.7;
        transition: 0.3s;
      }
      &:hover {
        span {
          color: $color-green;
          // border-bottom: 1px solid $color-green;
          transition: 0.3s;
        }
      }
    }
    .space {
      height: 1.6vw;
    }
  }
}
</style>
