<template>
  <div class="news-content-01 news-content">
    <div class="img-wrapper">
      <div
        class="img-area news-img-10-01"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/npc-10-01.jpg') + ')',
        }"
      ></div>
    </div>
    <p>
      平素は格別のご高配を賜り、誠にありがとうございます。<br />誠に勝手ながら下記の通り年末年始において休業とさせていただきます。<br /><br />休業期間：2021年12月29日（水）～2022年1月5日（水）まで<br /><br />ご不便をおかけいたしますが、何卒ご了承いただきますようお願い申し上げます。<br /><br />1/6（木）から通常通り営業させていただきます。
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoId: "HjhdcGJx9rY",
      widthRate: 0.76,
      heightRate: 0.76 * 0.5625,
      windowWidth: window.innerWidth,
      playerVars: {
        autoplay: 0,
        modestbranding: 1,
        rel: 0,
      },
    };
  },
  computed: {
    videoWidth: function() {
      return this.windowWidth * this.widthRate;
    },
    videoHeight: function() {
      return this.windowWidth * this.heightRate;
    },
  },
  methods: {
    handleResize: function() {
      this.windowWidth = window.innerWidth;
      //   this.height = window.innerHeight;
      if (window.innerWidth > 899) {
        this.widthRate = 0.76;
        this.heightRate = 0.76 * 0.5625;
      } else {
        this.widthRate = 0.92;
        this.heightRate = 0.92 * 0.5625;
      }
    },
  },
  mounted: function() {
    window.addEventListener("resize", this.handleResize);
    if (window.innerWidth > 899) {
      this.widthRate = 0.76;
      this.heightRate = 0.76 * 0.5625;
    } else {
      this.widthRate = 0.92;
      this.heightRate = 0.92 * 0.5625;
    }
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.img-wrapper {
  position: relative;
  width: 100%;
  margin: 0 0 4vw;
}
.img-wrapper:before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 高さを幅の75%に固定 */
}
.news-img-10-01 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.link-url {
  display: inline-block;
  padding: 0.8vw;
  padding-left: 0.2vw;
  font-size: calc(6.4px + 0.64vw);
  color: $color-green;
  .mark {
    margin-right: 0.4vw;
    font-size: calc(5.6px + 0.56vw);
  }
  @include lg {
    transition: 0.2s;
    &:hover {
      color: $color-green;
      opacity: 0.7;
    }
  }
}
.link-url2 {
  color: $color-green;
  font-weight: 500;
  transition: 0.3s;
  &:hover {
    opacity: 0.5;
  }
}
.video {
  @include md {
    margin-left: -2vw;
  }
}
</style>
