var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-detail-content"},[_c('h2',[_vm._v("カラーバリエーション例")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-05-' + '02' + '.jpg') + ')',
        })}),_c('h3'),_c('p',{staticClass:"hasHeight"})]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-05-' + '03' + '.jpg') + ')',
        })}),_c('h3'),_c('p',{staticClass:"hasHeight"})]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-05-' + '04' + '.jpg') + ')',
        })}),_c('h3'),_c('p',{staticClass:"hasHeight"})])]),_c('h2',[_vm._v("専用オプション品")]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"img-area",style:({
          'background-image':
            'url(' + require('@/assets/img/pp-05-' + '05' + '.jpg') + ')',
        })}),_c('h3',[_vm._v(" ART-NET NODE ")]),_c('p',[_vm._v(" ※仕様はお問い合わせ下さい ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }