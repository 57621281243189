<template>
    <div class="products-detail-content">

        <h2>非防水タイプ</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-13-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    ユニット型電源
                </h3>
                <p>
                    大量のLEDを点灯させる場合にはこのユニット型電源が必要です
                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-13-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    アダプター電源
                </h3>
                <p>
                    少量のLEDを点灯させる場合には使いやすいアダプター電源をおすすめします。
                </p>
            </div>
        </div>

        <h2>防水タイプ</h2>
        <div class="list-wrap">
            <!-- 04 -->
            <div class="list hasRm">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-13-'+ '04' + '.jpg') + ')'}"></div>
                <h3>
                    防水タイプ電源
                </h3>
                <p>
                    防水仕様のLED製品をご使用になられる際には必須の電源です。
                </p>
                <div class="recommended">
                    <p>おすすめ</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
