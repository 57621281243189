<template>
    <div class="products-detail-content">

        <h2>制御パターン例</h2>
        <div class="list-wrap">
            <!-- 02 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-11-'+ '02' + '.jpg') + ')'}"></div>
                <h3>
                    制御パターン１
                </h3>
                <p>
                    18球すべてを同じ色で点灯させることができます。
                </p>
            </div>
            <!-- 03 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-11-'+ '03' + '.jpg') + ')'}"></div>
                <h3>
                    制御パターン２
                </h3>
                <p>
                    ３球点灯、３球消灯、且つ違う色を点灯させることも可能です。
                </p>
            </div>
            <!-- 04 -->
            <div class="list">
                <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-11-'+ '04' + '.jpg') + ')'}"></div>
                <h3>
                    制御パターン３
                </h3>
                <p>
                    ６球点灯、６球点灯、且つ違う色を点灯させることも可能です。
                </p>
            </div>
        </div>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

</style>
