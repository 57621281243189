var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"works-detail"},[_c('nav',{staticClass:"topic-path"},[_c('router-link',{staticClass:"link has-link",attrs:{"to":"/"}},[_vm._v("TOP")]),_c('p',[_vm._v(">")]),_c('router-link',{staticClass:"link has-link",attrs:{"to":"/works"}},[_vm._v("納入事例")]),_c('p',[_vm._v(">")]),_c('div',{staticClass:"link"},[_vm._v(_vm._s(_vm.works[_vm.id].name))])],1),_c('main',[_c('article',[_c('section',[_c('h1',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.works[_vm.id].name))]),_c('div',{staticClass:"detail-tag tag"},_vm._l((_vm.works[_vm.id].tags),function(tag){return _c('p',{key:tag.id},[_vm._v(" "+_vm._s(_vm.tags[tag])+" ")])}),0),(_vm.works[_vm.id].length > 1)?_c('div',{staticClass:"slider"},[_c('slider',{attrs:{"id":_vm.works[_vm.id].pid,"length":Number(_vm.works[_vm.id].length)}})],1):_c('div',{staticClass:"slider single-img",style:({
            'background-image':
              'url(' +
              require('@/assets/img/wp-' + _vm.works[_vm.id].pid + '-01.jpg') +
              ')',
          })})]),_c('section',[(_vm.works[_vm.id].about != '')?_c('div',{staticClass:"about-area"},[_c('h3',{staticClass:"title"},[_vm._v("概要")]),_c('div',{staticClass:"product-name-wrap"},[_c('div',{staticClass:"product-name"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.works[_vm.id].about)}})])])]):_vm._e(),(_vm.works[_vm.id].movie_titles[0] != '')?_c('div',{staticClass:"movie-name-area"},[_c('h3',{staticClass:"title"},[_vm._v("動画URL")]),_c('div',{staticClass:"product-name-wrap"},_vm._l((_vm.works[_vm.id].movie_titles.length),function(n){return _c('div',{key:n.id,staticClass:"product-name"},[_c('p',[_vm._v(_vm._s(_vm.works[_vm.id].movie_titles[n]))]),_c('a',{staticClass:"movie-link",attrs:{"href":_vm.works[_vm.id].movie_links[n],"target":"_blank","rel":"noopener"}},[_vm._v(_vm._s(_vm.works[_vm.id].movie_links[n])+" ")])])}),0)]):_vm._e(),(_vm.works[_vm.id].charge != '')?_c('div',{staticClass:"product-name-area"},[_c('h3',{staticClass:"title"},[_vm._v("担当者")]),_c('div',{staticClass:"product-name-wrap"},[_c('div',{staticClass:"product-name"},[_c('p',[_vm._v(_vm._s(_vm.works[_vm.id].charge))])])])]):_vm._e(),(_vm.works[_vm.id].products_name != '')?_c('div',{staticClass:"product-name-area"},[_c('h3',{staticClass:"title"},[_vm._v("使用製品")]),_c('div',{staticClass:"product-name-wrap"},[_c('div',{staticClass:"product-name"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.works[_vm.id].products_name)}})])])]):_vm._e(),(_vm.works[_vm.id].products[0] != '')?_c('div',{staticClass:"link-product-area"},_vm._l((_vm.works[_vm.id].products),function(product){return _c('div',{key:product.id,staticClass:"link-product-wrap"},[_c('router-link',{staticClass:"link-product",attrs:{"to":{ name: 'products-detail', params: { id: product } }}},[_c('div',{staticClass:"img-area-wrap"},[_c('div',{class:[{ 'no-light': Number(product) >= 13 }, 'img-area'],style:({
                    'background-image':
                      'url(' +
                      require('@/assets/img/ppt-' +
                        _vm.products[product].id +
                        '.jpg') +
                      ')',
                  })})]),_c('div',{staticClass:"text-area"},[_c('p',[_vm._v(_vm._s(_vm.products[product].name))]),(_vm.products[product].name_type != '')?_c('p',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.products[product].name_type)+" ")]):_vm._e(),_c('p',{staticClass:"series"},[_vm._v(_vm._s(_vm.products[product].series))])])])],1)}),0):_vm._e()])])]),_c('router-link',{staticClass:"link-back",attrs:{"to":{ name: 'works', hash: '#' + _vm.works[_vm.id].id }}},[_c('p',[_vm._v("納入事例一覧へ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }