var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-page"},[_vm._m(0),_c('main',[_c('h2',[_vm._v("LED製品")]),_c('div',{staticClass:"link-product-area"},_vm._l((12),function(n){return _c('div',{key:n.id},[_c('router-link',{staticClass:"link-product",attrs:{"to":{ name: 'products-detail', params: { id: n } }}},[_c('div',{staticClass:"img-area-wrap"},[_c('div',{staticClass:"img-area",style:({
                'background-image':
                  'url(' +
                  require('@/assets/img/ppt-' + _vm.products[n].id + '.jpg') +
                  ')',
              })})]),_c('div',{staticClass:"text-area"},[_c('p',[_vm._v(_vm._s(_vm.products[n].name))]),(_vm.products[n].name_type != '')?_c('p',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.products[n].name_type)+" ")]):_vm._e(),_c('p',{staticClass:"series"},[_vm._v(_vm._s(_vm.products[n].series))])])])],1)}),0),_c('h2',[_vm._v("オプション品")]),_c('div',{staticClass:"link-product-area"},_vm._l((3),function(n){return _c('div',{key:n.id},[_c('router-link',{staticClass:"link-product",attrs:{"to":{ name: 'products-detail', params: { id: n + 12 } }}},[_c('div',{staticClass:"img-area-wrap"},[_c('div',{staticClass:"img-area no-light",style:({
                'background-image':
                  'url(' +
                  require('@/assets/img/ppt-' +
                    _vm.products[n + 12].id +
                    '.jpg') +
                  ')',
              })})]),_c('div',{staticClass:"text-area"},[_c('p',[_vm._v(_vm._s(_vm.products[n + 12].name))]),(_vm.products[n + 12].name_type != '')?_c('p',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.products[n + 12].name_type)+" ")]):_vm._e(),_c('p',{staticClass:"series"},[_vm._v(_vm._s(_vm.products[n + 12].series))])])])],1)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"page-head"}},[_c('div',{staticClass:"bg-img"}),_c('div',{staticClass:"cover"}),_c('h1',[_c('p',[_vm._v("主な取扱製品")]),_c('p',[_vm._v("PRODUCT")])])])
}]

export { render, staticRenderFns }