<template>
  <div class="product-detail-page">
    <nav class="topic-path">
      <router-link to="/" class="link has-link">TOP</router-link>
      <p>></p>
      <router-link to="/products" class="link has-link">製品</router-link>
      <p>></p>
      <div class="link">
        {{ products[id].name
        }}<span v-if="products[id].name_type != ''">
          ({{ products[id].name_type }})</span
        >
      </div>
    </nav>

    <main>
      <article>
        <section style="margin: 0;">
          <p class="detail-series">{{ products[id].series }}</p>
          <h1 class="detail-title">
            {{ products[id].name
            }}<span v-if="products[id].name_type != ''">
              ({{ products[id].name_type }})</span
            >
          </h1>

          <div :class="[{ notSetWidth: id == 15 }, 'detail-tag', 'tag']">
            <p v-for="type in products[id].types" :key="type.id">
              {{ types[type] }}
            </p>
          </div>

          <div
            class="main-img img"
            :style="{
              'background-image':
                'url(' +
                require('@/assets/img/pp-' + products[id].id + '-01.jpg') +
                ')',
            }"
          ></div>

          <div class="main-info-area">
            <div class="description-area">
              <p>{{ products[id].description_detail01 }}</p>
              <p>{{ products[id].description_detail02 }}</p>
            </div>
            <div class="info-button-area">
              <!-- <p v-if="Number(id)<=13" v-scroll-to="{ element: '#spec', duration: 640, offset: -112 }">仕様はこちら</p> -->
              <p v-if="Number(id) <= 13" @click="showSpec">仕様を表示</p>
              <!-- <router-link
                :to="{ name: 'download', hash: '#product-' + products[id].id }"
                class="hash-link"
              >
                <p>資料ダウンロードページへ</p>
              </router-link> -->

              <p @click="showDownload">資料ダウンロード</p>

              <!-- <p v-if="id<=3" v-scroll-to="{ element: '#questions', duration: 640, offset: -112 }">よくある質問</p> -->
              <p v-if="id <= 3" @click="showFaq">よくある質問を表示</p>
            </div>
          </div>
        </section>

        <section class="detail-area" style="margin: 4vw 0;">
          <div class="download-list">
            <component :is="detailname"></component>
          </div>
        </section>

        <div class="relation-area">
          <section
            class="realtion-works"
            v-if="products[id].relation_works[0] != ''"
          >
            <h1>
              <p>納入事例</p>
              <p>WORKS</p>
            </h1>

            <!-- <div class="works-content-area"> -->
            <div
              class="realtion-works-content-area"
              v-if="products[id].relation_works.length <= 2"
            >
              <div
                v-for="workId in products[id].relation_works"
                :key="workId.id"
                class="content-wrap"
              >
                <!-- <p>{{ index }}</p> -->
                <router-link
                  :to="{ name: 'works-detail', params: { id: Number(workId) } }"
                  class="content"
                  v-if="works[workId].id != '00'"
                >
                  <div class="img-wrap">
                    <div
                      class="img"
                      :style="{
                        'background-image':
                          'url(' +
                          require('@/assets/img/wp-' +
                            works[workId].id +
                            '-01.jpg') +
                          ')',
                      }"
                    ></div>
                  </div>

                  <div class="text-area">
                    <p>{{ works[workId].name }}</p>
                  </div>

                  <!-- <div class="text-area">
                                        <p class="title">{{ works[workId].name }}</p>
                                        <div class="tag">
                                            <p v-for="tag in works[workId].tags" :key="tag.id">
                                                {{ tags[tag] }}
                                            </p>
                                        </div>
                                    </div> -->
                </router-link>
              </div>
            </div>

            <div class="realtion-works-content-area" v-else>
              <slider-relation-works :idList="products[id].relation_works" />
            </div>

            <div class="list-link-router-wrap">
              <router-link
                to="/works"
                active-class="link--active"
                exact
                class="list-link-router"
              >
                <div class="list-link">
                  <p>すべての納入事例をみる</p>
                  <img src="../assets/img/mk-02.svg" alt="" />
                </div>
              </router-link>
            </div>
          </section>

          <section
            class="realtion-products"
            v-if="products[id].relation_products[0] != ''"
          >
            <h1>
              <p>関連製品</p>
              <p>PRODUCTS</p>
            </h1>

            <slider
              :content="products[id].relation_products"
              class="product-slider"
            ></slider>

            <div class="list-link-router-wrap">
              <router-link
                to="/products"
                active-class="link--active"
                exact
                class="list-link-router"
              >
                <div class="list-link">
                  <p>すべての製品をみる</p>
                  <img src="../assets/img/mk-02.svg" alt="" />
                </div>
              </router-link>
            </div>

            <!-- <div class="link-product-area">
                            <div v-for="productId in products[id].relation_products" :key="productId.id">
                                <router-link
                                        :to="{ name: 'products-detail', params: { id: productId }}"
                                        class="link-product"
                                    >
                                        <div class="img-area-wrap">
                                            <div class="img-area" :style="{'background-image': 'url(' + require('@/assets/img/pp-'+ products[productId].id + '-01.jpg') + ')'}"></div>
                                        </div>
                                        <div class="text-area">
                                            <p>{{ products[productId].name }}</p>
                                            <p>{{ products[productId].series }}</p>
                                        </div>
                                </router-link>
                            </div>
                        </div> -->
          </section>
        </div>
      </article>

      <modal
        name="modal-spec"
        :draggable="false"
        :resizable="false"
        scrollable="true"
        :width="modalWidth"
        height="auto"
      >
        <div class="hideModal" @click="hideSpec">
          <img src="../assets/img/mk-06.svg" alt="" />
        </div>
        <section class="spec-area" id="spec" v-if="Number(id) <= 13">
          <div class="spec-inner-wrap">
            <div class="title-area">
              <h1>
                <p>仕様</p>
                <p>SPEC</p>
              </h1>
            </div>
            <h2 class="title-bar-01">
              {{ products[id].name
              }}<span v-if="products[id].name_type != ''">
                ({{ products[id].name_type }})</span
              >
            </h2>
            <component :is="specname"></component>
          </div>
        </section>
      </modal>

      <modal
        name="modal-download"
        :draggable="false"
        :resizable="false"
        scrollable="true"
        :width="modalWidth"
        height="auto"
      >
        <div class="hideModal" @click="hideDownload">
          <img src="../assets/img/mk-06.svg" alt="" />
        </div>
        <section class="pdpage-download-area">
          <div class="title-area">
            <h1>
              <p>ダウンロード</p>
              <p>DOWNLOAD</p>
            </h1>
          </div>
          <div class="pp-dl-list download-list">
            <component :is="downloadname"></component>
          </div>
        </section>
      </modal>

      <modal
        name="modal-faq"
        :draggable="false"
        :resizable="false"
        scrollable="true"
        :width="modalWidth"
        height="auto"
      >
        <div class="hideModal" @click="hideFaq">
          <img src="../assets/img/mk-06.svg" alt="" />
        </div>
        <section class="questions-area" id="questions" v-if="id <= 3">
          <div class="title-area">
            <h1>
              <p>よくある質問</p>
              <p>FAQ</p>
            </h1>
          </div>
          <h2 class="title-bar-01">FLEXIA LEDテープライト関連</h2>
          <div class="q-and-a-area">
            <div class="q-and-a">
              <div class="question">
                <p class="q-mark">Q</p>
                <p>FLEXIAのLEDテープライトってどうやって点灯させるの？</p>
              </div>
              <div class="answer">
                <p class="a-mark">A</p>
                <p>
                  FLEXIAに表記されている電圧と同じ直流電源(DC電源)をご用意して頂き、製品と電源のプラス端子とマイナス端子を正しく接続して頂くと点灯致します。
                </p>
              </div>
            </div>

            <div class="q-and-a">
              <div class="question">
                <p class="q-mark">Q</p>
                <p>FLEXIAのLEDテープライトってどこでカットしてもいいの？</p>
              </div>
              <div class="answer">
                <p class="a-mark">A</p>
                <p>
                  基盤に記載されているハサミのマークがカットラインになっております。カットライン以外でテープLEDをカットしてしまうと回路が正常に働かなくなりますのでご注意ください。
                </p>
              </div>
            </div>

            <div class="q-and-a">
              <div class="question">
                <p class="q-mark">Q</p>
                <p>
                  ライン状に光を演出したいけど普通のテープライトを設置するだけじゃLEDの粒々が見えてしまうのでどうにかならない？
                </p>
              </div>
              <div class="answer">
                <p class="a-mark">A</p>
                <p>
                  粒感が気になってしまう場合は、演出デザイナー様方に大人気のアルミバーをご用意しております。バーの素材はアルミなので放熱にも優れております。
                </p>
              </div>
            </div>
          </div>
        </section>
      </modal>
    </main>
  </div>
</template>

<script>
import products from "../assets/json/products.json";
import types from "../assets/json/typeName.json";
import works from "../assets/json/works.json";
import tags from "../assets/json/tagName.json";

import Slider from "../components/Slider";
import SliderRelationWorks from "../components/SliderRelationWorks";

import Spec01 from "../components/Spec01";
import Spec02 from "../components/Spec02";
import Spec03 from "../components/Spec03";
import Spec04 from "../components/Spec04";
import Spec05 from "../components/Spec05";
import Spec06 from "../components/Spec06";
import Spec07 from "../components/Spec07";
import Spec08 from "../components/Spec08";
import Spec09 from "../components/Spec09";
import Spec10 from "../components/Spec10";
import Spec11 from "../components/Spec11";
import Spec12 from "../components/Spec12";
import Spec13 from "../components/Spec13";

import DlList01 from "../components/DlList01";
import DlList02 from "../components/DlList02";
import DlList03 from "../components/DlList03";
import DlList04 from "../components/DlList04";
import DlList05 from "../components/DlList05";
import DlList06 from "../components/DlList06";
import DlList07 from "../components/DlList07";
import DlList08 from "../components/DlList08";
import DlList09 from "../components/DlList09";
import DlList10 from "../components/DlList10";
import DlList11 from "../components/DlList11";
import DlList12 from "../components/DlList12";
import DlList13 from "../components/DlList13";
import DlList14 from "../components/DlList14";
import DlList15 from "../components/DlList15";

import Detail01 from "../components/PdDetail01";
import Detail02 from "../components/PdDetail02";
import Detail03 from "../components/PdDetail03";
import Detail04 from "../components/PdDetail04";
import Detail05 from "../components/PdDetail05";
import Detail06 from "../components/PdDetail06";
import Detail07 from "../components/PdDetail07";
import Detail08 from "../components/PdDetail08";
import Detail09 from "../components/PdDetail09";
import Detail10 from "../components/PdDetail10";
import Detail11 from "../components/PdDetail11";
import Detail12 from "../components/PdDetail12";
import Detail13 from "../components/PdDetail13";
import Detail14 from "../components/PdDetail14";
import Detail15 from "../components/PdDetail15";

export default {
  data: () => {
    return {
      works: works,
      tags: tags,
      types: types,
      products: products,
      specname: "",
      downloadname: "",
      detailname: "",
      modalWidth: "92%",
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  components: {
    Slider,
    SliderRelationWorks,

    Spec01,
    Spec02,
    Spec03,
    Spec04,
    Spec05,
    Spec06,
    Spec07,
    Spec08,
    Spec09,
    Spec10,
    Spec11,
    Spec12,
    Spec13,

    DlList01,
    DlList02,
    DlList03,
    DlList04,
    DlList05,
    DlList06,
    DlList07,
    DlList08,
    DlList09,
    DlList10,
    DlList11,
    DlList12,
    DlList13,
    DlList14,
    DlList15,

    Detail01,
    Detail02,
    Detail03,
    Detail04,
    Detail05,
    Detail06,
    Detail07,
    Detail08,
    Detail09,
    Detail10,
    Detail11,
    Detail12,
    Detail13,
    Detail14,
    Detail15,
  },
  // computed: {
  //     specname: function () {
  //         return 'spec' + products[this.id].id
  //     }
  // },
  created() {
    this.specname = "spec" + products[this.id].id;
    this.downloadname = "dl-list-" + products[this.id].id;
    this.detailname = "detail" + products[this.id].id;
    // //console.log('created');
    if (window.innerWidth <= 899) {
      this.modalWidth = "96%";
    }
  },
  beforeUpdate() {
    this.specname = "spec" + products[this.id].id;
    this.downloadname = "dl-list-" + products[this.id].id;
    this.detailname = "detail" + products[this.id].id;
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    // //console.log('beforeUpdate');
    const hash = this.$route.hash;
    if (hash && hash.match(/^#.+$/)) {
      this.$scrollTo(hash);
    }
  },
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
    showSpec: function() {
      this.$modal.show("modal-spec");
    },
    hideSpec: function() {
      this.$modal.hide("modal-spec");
    },
    showFaq: function() {
      this.$modal.show("modal-faq");
    },
    hideFaq: function() {
      this.$modal.hide("modal-faq");
    },
    showDownload: function() {
      this.$modal.show("modal-download");
    },
    hideDownload: function() {
      this.$modal.hide("modal-download");
    },
  },
  // ,watch: {
  //     $route(to, from) {

  //     }
  // }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.detail-series {
  margin-top: 2.4em;
  font-size: calc(7.2px + 0.72vw);
  padding-left: 0.1em;
  @include md {
    font-size: calc(8px + 0.8vw);
  }
}

h1.detail-title {
  margin: 0.64em 0 0.96em;
  font-weight: 500;
  @include md {
    margin: 0.28em 0 0.8em;
  }
}

header nav .link-list {
  color: black;
}

.detail-tag p {
  width: calc(42px + 4.2vw);
  padding: 0.64em 0em;
  @include md {
    width: calc(36px + 6.4vw);
    padding: 1.8vw 0vw;
    margin-right: 0.8vw;
  }
}
.notSetWidth p:nth-of-type(3) {
  width: unset;
  padding-left: 0.64vw;
  padding-right: 0.64vw;
}
.product-detail-page {
  margin-left: $lg-mar-lr;
  width: calc(100vw - #{$lg-mar-lr} * 2);
  @include md {
    margin-left: 6vw;
    width: 88vw;
  }
  .img {
    margin: 2.4vw 0;
    width: 100%;
    height: 36vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include md {
      margin-top: 4.4vw;
      margin-left: -6vw;
      width: 100vw;
      height: 56vw;
    }
  }
  .main-info-area {
    margin: 3.2vw 0;
    @include lg {
      margin: 4.8vw 0 2.4vw;
      display: flex;
      justify-content: space-between;
    }
    .description-area {
      width: 60%;
      @include md {
        width: 100%;
        padding: 2vw 0.4vw 2.8vw;
        box-sizing: border-box;
      }
      p {
        font-size: calc(6.8px + 0.68vw);
        line-height: 2.1;
        text-align: left;
        letter-spacing: 0.04em;
        padding-left: 0.04em;
        @include md {
          font-size: 13px;
          letter-spacing: 0em;
        }
        &:first-of-type {
          margin-bottom: 0.64vw;
          font-size: calc(6.8px + 0.68vw);
          font-weight: bold;
          line-height: 1.56;
          @include md {
            font-size: calc(10px + 1vw);
            margin-bottom: 2.4vw;
          }
        }
      }
    }
    .info-button-area {
      margin: 0 0 2.4vw;
      width: 31%;
      @include md {
        margin: 0 0 8vw;
        width: 100%;
      }
      p {
        margin: 0 0 1.4vw;
        width: 100%;
        padding: 1.2vw 0;
        // border: 1px #{$color-green}dd solid;
        font-size: calc(6.8px + 0.68vw);
        // font-weight: 500;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.2s;
        user-select: none;
        background: #{$color-green}dd;
        border-radius: 4px;
        color: white;
        @include md {
          margin: 2.4vw 0;
          padding: 3vw 0;
          font-size: calc(9.2px + 0.92vw);
        }
        &:hover {
          background: #{$color-green}77;
          @include translate(0, -1px); //   border: $color-green 1px solid;
          //   color: $color-green;
          transition: 0.2s;
        }
      }
    }
  }
}
.topic-path {
  margin-top: 80px;
}

section {
  margin: 7.2vw 0;
  @include md {
    margin: 12vw 0;
    h1 {
      margin-left: 6vw;
      margin-bottom: 6vw;
    }
  }
}

.questions-area,
.spec-area {
  @include md {
    margin-bottom: calc(12vw + 32px);
  }
  .spec-table {
    position: relative;
    .table-area {
      position: relative;
      width: 100%;
    }
  }
  .title-area {
    @include translate(-#{$lg-mar-lr}, 0);
    margin-bottom: 3.2vw;
  }
}
.pdpage-download-area {
  .title-area {
    @include lg {
      @include translate(-4vw, 0);
    }
    @include md {
      @include translate(-8.8vw, 0);
    }
    margin-bottom: 3.2vw;
  }
}

.spec-area {
  @include md {
    margin-left: -2vw;
    width: 92vw;
  }
}

.spec-area {
  z-index: 100;
  // position: fixed;
  // top: 0; left: 4vw;
  // width: 92vw; height: auto;
  top: 32px;
  background: white;
  h1 {
    margin-bottom: 4vw;
    @include md {
      margin-left: 8vw;
      margin-bottom: 9.6vw;
    }
  }
  .spec-inner-wrap {
    margin-top: 96px;
    margin-left: calc(#{$lg-mar-lr} - 4vw);
    position: relative;
    width: $lg-width;
    @include md {
      margin-top: 72px;
      margin-left: 4vw;
      width: 92vw;
    }
  }
}

.vm--container {
  // top: 16px;
}

.pp-dl-list {
  width: $lg-width;
  margin-left: calc(#{$lg-mar-lr} - 4vw);
}

.questions-area {
  margin-left: calc(#{$lg-mar-lr} - 4vw);
  width: $lg-width;
  h1 {
    @include md {
      margin-left: 6vw;
      margin-bottom: 9.6vw;
    }
  }
  @include md {
    margin-top: 72px;
    margin-left: 4vw;
    width: 88vw;
  }
}

.questions-area {
  .q-and-a {
    margin: 2.4vw 0;
    @include md {
      margin: 8.8vw 0;
      &:first-of-type {
        margin: 4.8vw 0 8vw;
      }
    }
  }
  $faqHeight: 4vw;
  @include md {
    // $faqHeight: 9.6vw;
  }
  .question,
  .answer {
    position: relative;
    display: flex;
    margin: 0.96vw 0;
    @include md {
      margin: 4vw 0;
    }
    p {
      // padding-top: calc(#{$faqHeight}/2);
      width: calc(100vw - #{$lg-mar-lr} * 2 - 6.4vw);
      height: $faqHeight;
      display: table-cell;
      // line-height: 1.6;
      vertical-align: middle;
      @include md {
        width: 74vw;
        height: 14.4vw;
        text-align: justify;
      }
      &:nth-of-type(2) {
        position: absolute;
        height: unset;
        @include lg {
          top: 50%;
          left: 5.6vw;
          @include translate(0, -50%);
        }
        @include md {
          left: 12.4vw;
        }
      }
    }
    .q-mark,
    .a-mark {
      margin-right: 2.4vw;
      display: inline-block;
      width: $faqHeight;
      height: $faqHeight;
      border-radius: 50%;
      font-size: calc(6.4px + 1.2vw);
      text-align: center;
      line-height: $faqHeight;
      color: white;
      letter-spacing: 0;
      @include md {
        width: 9.6vw;
        height: 9.6vw;
        line-height: 9.6vw;
        font-size: calc(10px + 1vw);
      }
    }
    .q-mark {
      background: $color-green;
    }
    .a-mark {
      background: black;
    }
  }
  .question {
    p {
      color: $color-green;
      font-size: calc(7.2px + 0.72vw);
      font-weight: bold;
      @include md {
        font-size: calc(9px + 0.9vw);
      }
    }
  }
  .answer {
    p {
      font-size: calc(7.2px + 0.72vw);
      color: black;
      @include md {
        font-size: calc(8px + 0.8vw);
      }
    }
  }
}

.relation-area {
  position: relative;
  h1 p {
    margin-left: -#{$lg-mar-lr};
  }
  .realtion-works {
    margin: 9.6vw 0;
  }
  .realtion-products {
    margin-top: 10.4vw;
    .link-product-area {
      margin-top: 2vw;
    }
  }
}

.relation-area {
  .realtion-works-content-area {
    width: 100vw;
    margin: 2.4vw 0 4vw;
    margin-left: -#{$lg-mar-lr};
    @include lg {
      display: flex;
    }
    @include md {
      margin-left: -6vw;
      margin-bottom: 8.8vw;
    }
    .content-wrap {
      position: relative;
      width: 100%;
      .img-wrap {
        overflow: hidden;
        .img {
          margin: 0;
          height: 32vw;
          @include transform(scale(1.61));
          transition: 0.3s;
          @include md {
            height: 36vw;
            @include transform(scale(2));
          }
        }
      }
      .text-area {
        position: absolute;
        display: inline-block;
        // width: 100%;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        color: white;
        font-size: calc(4px + 0.7vw);
        opacity: 0;
        transition: 0.3s;
      }
      &:hover {
        .img {
          filter: brightness(0.3);
          @include transform(scale(1.64));
          transition: 0.3s;
        }
        .text-area {
          opacity: 1;
          transition: 0.3s;
        }
      }
    }
  }
}

.realtion-works,
.realtion-products {
  .list-link-router-wrap {
    filter: none;
    opacity: 1;
  }
  @include lg {
    position: relative;
    width: calc(100vw - #{$lg-mar-lr});
  }
  @include md {
    margin-top: 0;
    padding-top: 8vw;
  }
}

.realtion-products {
  @include md {
    width: 100vw;
    margin-left: -6vw;
    margin-bottom: 0;
    padding: 16vw 6vw 8vw;
    background: #f2f2f2;
    margin-top: 8vw;
    .list-link-router {
      margin-left: calc(50% - 6vw);
    }
  }
}

.list-link-router {
  @include md {
    white-space: nowrap;
    margin-left: 50%;
    @include translate(-50%, 0);
  }
}

.product-slider {
  margin: 2.4vw 0 12vw;
  margin-left: -#{$lg-mar-lr};
  width: 100vw;
  @include md {
    margin: 4.8vw 0 9.6vw;
    margin-left: -6vw;
  }
}
</style>
