import Vue from "vue";
import Router from "vue-router";
// import store from './store';

import HeaderTop from "./components/HeaderTop.vue";
import HeaderList from "./components/HeaderList.vue";
import HeaderDetail from "./components/HeaderDetail.vue";

import Home from "./views/Home.vue";
import Products from "./views/Products.vue";
import Works from "./views/Works.vue";
import Information from "./views/Information.vue";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Download from "./views/Download.vue";
import Contact from "./views/Contact.vue";
// import ContactForm from "./components/ContactForm.vue";
import ContactFinish from "./components/ContactFinish.vue";
import News from "./views/News.vue";

import ProductsDetail from "./views/ProductsDetail.vue";
import WorksDetail from "./views/WorksDetail.vue";
import NewsDetail from "./views/NewsDetail.vue";
// import { resolve } from 'core-js/fn/promise';

// import Users from "./views/Users.vue";
// import UsersPosts from './views/UsersPosts.vue';
// import UsersProfile from './views/UsersProfile.vue';

Vue.use(Router);

export default new Router({
  // store,
  // mode: "history",
  mode: "hash",
  // base: "/dist", //テストアップ用
  base: "/", //本番用
  routes: [
    {
      path: "/",
      components: {
        default: Home,
        header: HeaderTop,
      },
    },
    {
      path: "/products",
      components: {
        default: Products,
        header: HeaderDetail,
      },
    },
    {
      path: "/products-detail/:id",
      components: {
        default: ProductsDetail,
        header: HeaderDetail,
      },
      props: {
        default: true,
      },
      name: "products-detail",
    },
    {
      path: "/works",
      components: {
        default: Works,
        header: HeaderList,
      },
      name: "works",
    },
    {
      path: "/works-detail/:id",
      components: {
        default: WorksDetail,
        header: HeaderDetail,
      },
      props: {
        default: true,
      },
      name: "works-detail",
    },
    {
      path: "/information",
      components: {
        default: Information,
        header: HeaderList,
      },
    },
    {
      path: "/privacypolicy",
      components: {
        default: PrivacyPolicy,
        header: HeaderDetail,
      },
      name: "download",
    },
    {
      path: "/download",
      components: {
        default: Download,
        header: HeaderDetail,
      },
      name: "download",
    },
    {
      path: "/contact",
      components: {
        default: Contact,
        header: HeaderDetail,
      },
      beforeEnter: function() {
        window.location.href = "/contact-form";
        // if (store.state.count) {
        //     window.location.href = '/contact-form';
        //     store.state.count = false;
        // } else {
        //     store.state.num = 0;
        // }
      },
    },
    {
      path: "/contact-form",
      components: {
        default: Contact,
        header: HeaderDetail,
      },
      // beforeEnter: function (to, from, next) {
      //     if (store.state.count) {
      //     //     // window.location.href = '/contact';
      //         next('/works');
      //     //     store.state.count = false
      //     }
      // }
    },
    {
      path: "/contact-finish",
      components: {
        default: ContactFinish,
        header: HeaderDetail,
      },
    },
    {
      path: "/news",
      components: {
        default: News,
        header: HeaderList,
      },
    },
    {
      path: "/news-detail/:id",
      components: {
        default: NewsDetail,
        header: HeaderDetail,
      },
      props: {
        default: true,
      },
      name: "news-detail",
    },
    // {
    //     path: '/users/:id',
    //     components: {
    //         default: Users
    //     },
    //     props: {
    //         default: true,
    //         header: false
    //     },
    //     children: [
    //         { path: 'posts', component: UsersPosts },
    //         {
    //             path: 'profile',
    //             component: UsersProfile,
    //             name: 'users-id-profile'
    //         }
    //     ]
    // },
    {
      path: "*",
      redirect: "/",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      this.app.$root.$once("triggerScroll", () => {
        let position = { x: 0, y: 0 };
        if (savedPosition) {
          position = savedPosition;
        }
        if (to.hash) {
          position = {
            selector: to.hash,
            offset: { x: 0, y: 112 },
          };
        }
        resolve(position);
      });
    });
    // return {x: 0, y: 0};
  },
});
