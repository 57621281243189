<template>
  <!-- 4 VENUS -->
  <div>
    <div class="table-area">
      <!-- <h3></h3> -->
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-VN2835WW25-24V182</td>
          <td rowspan="3">
            <a
              href="../catalog/VENUSSERIES_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="3">
            <a
              href="../specifications/FL-VN2835xxyy-24V182.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="3">
            <a
              href="../description/取扱説明書【VENUS】.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="3">-</td>
          <td rowspan="3">-</td>
        </tr>
        <tr>
          <td>FL-VN2835WW28-24V182</td>
        </tr>
        <tr>
          <td>FL-VN2835CW60-24V182</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>VENUS 付属アルミバー</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>FL-AC1155</td>
          <td rowspan="1">
            -
          </td>
          <td rowspan="1">
            <a
              href="../specifications/FL-AC1155.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
          <td rowspan="1">-</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
