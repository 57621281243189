<template>
    <div class="form-page">
        <div id="page-head">
            <div class="bg-img"></div>
            <div class="cover"></div>
            <h1>
                <p>お問い合わせ</p>
                <p>CONTACT</p>
            </h1>
        </div>

        <main>
            <p>
                お問い合わせありがとうございます。<br>
                確認メールを送信しましたので、<br class="md">ご確認ください。<br>
                ご希望のご連絡方法にて、<br class="md">折り返しご連絡させて頂きます。
            </p>
            <p>
                 <span>【ご注意】</span><br>
                ※お問い合わせの内容によっては、ご連絡に数日を要する場合がございます。<br>
                ※万一、確認メールが届かない場合はお手数ですが、入力したメールアドレスに間違いがないかご確認頂き、再度お問い合わせフォームから送信いただくか、お電話にてご連絡ください。

            </p>
        </main>

         <div class="link-area">
                <router-link
                    to="/"
                    class="link"
                >
                    <p>トップページへ戻る</p>
                </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
    #page-head {
        .cover {
            background: white;
        }
        h1 p {
            color: black;
        }
    }

    main {
        margin: 2.4vw 26vw 8vw;
        width: 48vw;
        // @include lg {
            br.md {
                display: none;
            }
        // }
        @include md {
            margin: 8vw 14vw 8vw;
            width: 72vw;
        }
        p {
            span {
                padding-left: 0.4vw;
            }
            &:nth-of-type(1) {
                font-size: calc(7.2px + 0.72vw);
                text-align: center;
                line-height: 2;
                @include md {
                    padding: 0 4vw;
                    text-align: left;
                    font-size: calc(10px + 1vw);
                }
                }
            &:nth-of-type(2) {
                margin-top: 3.2vw;
                padding: 1.2vw 2.8vw;
                font-size: calc(6.4px + 0.64vw);
                border: 1px black solid;
                text-align: left;
                line-height: 1.8;
                @include md {
                    margin-top: 9.6vw;
                    padding: 3.2vw 4vw;
                    font-size: calc(9px + 0.9vw);
                }
            }
        }
    }

     .link-area {
        display: block;
        margin: 6.4vw auto 9.6vw;
        border: 1px black solid;
        width: 20vw;
        cursor: pointer;
        font-size: calc(6.2px + 0.62vw);
        font-weight: bold;
        text-align: center;
        user-select: none;
        transition: 0.3s;
        box-sizing: border-box;
        white-space: nowrap;
        @include md {
            margin: 12vw auto;
            width: 48vw;
            padding: 2.8vw 0;
            font-size: calc(9px + 0.9vw);
        }
        p {
            padding: 1.04vw 0;
            transition: 0.3s;
        }
        &:hover {
            background: #{$color-green}aa;
            border: #{$color-green}aa 1px solid;
            transition: 0.3s;
            p {
                color: white;
                transition: 0.3s;
            }
        }
    }

</style>
