<template>
  <div class="news-detail-page">
    <nav class="topic-path">
      <router-link to="/" class="link has-link">TOP</router-link>
      <p>></p>
      <router-link to="/news" class="link has-link">ニュース一覧</router-link>
      <p>></p>
      <div class="link">{{ news[id].title }}</div>
    </nav>

    <main>
      <p class="news-date">{{ news[id].date }}</p>
      <p class="news-type">{{ news[id].type }}</p>
      <h1>{{ news[id].title }}</h1>
      <hr />
      <component :is="contentname"></component>
    </main>

    <!-- :to="{ name: 'news', hash: '#' + news[id].id}" -->
    <router-link to="/news" class="link-back">
      <p>ニュース一覧へ</p>
    </router-link>
  </div>
</template>

<script>
import news from "../assets/json/news.json";
import Content01 from "../components/newsContent01";
import Content02 from "../components/newsContent02";
import Content03 from "../components/newsContent03";
import Content04 from "../components/newsContent04";
import Content05 from "../components/newsContent05";
import Content06 from "../components/newsContent06";
import Content07 from "../components/newsContent07";
import Content08 from "../components/newsContent08";
import Content09 from "../components/newsContent09";
import Content10 from "../components/newsContent10";
import Content11 from "../components/newsContent11";
import Content12 from "../components/newsContent12";
import Content13 from "../components/newsContent13";
import Content14 from "../components/newsContent14";
import Content15 from "../components/newsContent15";

export default {
  data: () => {
    return {
      news: news,
      contentname: "",
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  components: {
    Content01,
    Content02,
    Content03,
    Content04,
    Content05,
    Content06,
    Content07,
    Content08,
    Content09,
    Content10,
    Content11,
    Content12,
    Content13,
    Content14,
    Content15,
  },
  created() {
    this.contentname = "content" + news[this.id].id;
  },
  beforeUpdate() {
    this.contentname = "content" + news[this.id].id;
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";
.news-detail-page {
  margin-left: $lg-mar-lr;
  width: calc(100vw - #{$lg-mar-lr} * 2);
  @include md {
    margin-left: 6vw;
    width: 88vw;
  }
}
.topic-path {
  margin-top: 80px;
}

main /deep/ {
  margin: 3.2vw 0 8vw;
  .news-date {
    font-size: calc(7.2px + 0.72vw);
    @include md {
      font-size: 14px;
    }
  }
  .news-type {
    margin: 0.64vw 0;
    padding: 0.32vw 0.4vw 0.24vw;
    width: calc(42px + 4.2vw);
    border-radius: 0.4vw;
    background: $color-green;
    font-size: calc(6.4px + 0.64vw);
    color: white;
    text-align: center;
    @include md {
      display: inline-block;
      margin: 8px 0 4px;
      padding-top: 4px;
      width: 120px;
      height: 28px;
      font-size: 16px;
      vertical-align: middle;
    }
  }
  h1 {
    margin: 1.6vw 0vw 2vw;
    font-size: calc(9.6px + 0.96vw);
    font-weight: 500;
    @include md {
      margin-bottom: 0vw;
      font-size: 24px;
      line-height: 1.2;
    }
  }
  hr {
    @include md {
      opacity: 0;
    }
  }
  .news-content {
    margin-top: 2.4vw;
    .img-wrapper {
      margin-bottom: 4.8vw;
    }
    @include md {
      .img-wrapper {
        position: relative;
        width: 100vw;
        margin: 0 0 6.4vw -#{$lg-mar-lr / 2};
      }
      .img-wrapper:before {
        content: "";
        display: block;
        padding-top: 56.25vw; /* 高さを幅の75%に固定 */
      }
    }
    .img-area {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
  p {
    font-size: calc(7.2px + 0.72vw);
    line-height: 2;
    @include md {
      font-size: 16px;
    }
  }
  .link-url {
    font-size: calc(7.2px + 0.72vw);
    padding: 0.96vw 0.2vw;
    @include md {
      margin-top: 24px;
      font-size: 14px;
    }
    .mark {
      margin-right: 0.8vw;
    }
  }
}

.link-back {
  position: relative;
  display: inline-block;
  text-decoration: none;
  border-radius: 4px;
  @include lg {
    margin: 0vw 0 9.6vw;
    left: calc(50vw - #{$lg-mar-lr});
    @include translate(-50%, 0);
    width: 20vw;
    border: 1px black solid;
  }
  transition: 0.2s;
  @include md {
    left: -2vw;
    width: 92vw;
    background: #{$color-green}bb;
    filter: brightness(1.05);
    margin: 6.4vw 0 5.6vw;
  }
  &:hover {
    @include lg {
      // border: white 1px solid;
      background: #{$color-green}bb;
      border: #{$color-green}bb 1px solid;
      filter: brightness(1.15) saturate(1.05);
      box-shadow: 0px 2px 16px rgba(33, 150, 83, 0.28);
      transition: all 0.3s, box-shadow 0.3s;
      p {
        // background: #{$color-green};
        // filter: brightness(1.15) saturate(1.05);
        opacity: 0.75;
        color: white;
        transition: 0.2s;
      }
    }
  }

  p {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 1em 0;
    font-size: calc(7px + 0.7vw);
    text-align: center;
    @include md {
      font-size: calc(9.6px + 0.96vw);
      // border-bottom: 1px black solid;
      color: white;
      padding: 4vw 0.04em;
    }
    transition: 0.2s;
  }
}
</style>
