<template>
    <Header :class="{active: isChangeBg, hide: isHide}" />
</template>

<script>
import Header from '../views/Header.vue';

export default {
  components: {
    Header
  },
  mounted() {
      window.addEventListener('scroll', this.onScroll)
  },
  data() {
    return {
        scrollY: 0,
        threshold: window.innerWidth * 0.04,
        posBf: 0,
        posAf: 0,
        isHide: false
    }
  },
  computed:{
    isChangeBg(){
      if(this.scrollY > this.threshold) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onScroll () {
        this.posAf = this.scrollY = window.pageYOffset;
        if( this.posAf < this.posBf){
            this.isHide = false;
        }else if(this.scrollY > window.innerWidth * 0.04){
            this.isHide = true;
        }else {
            this.isHide = false;
        }
        this.posBf = this.posAf;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

@include lg {
    header /deep/ {
        nav {
            background: unset;
            box-shadow: unset;

            .link-list {
                .link {
                    color: #4F4F4F;
                    transition: 0.3s;
                    &:hover {
                        color: $color-green;
                        transition: 0.3s;
                    }
                }
                .link--active {
                    color: $color-green;
                    filter: brightness(1.1) saturate(1.05);
                }
            }
        }
    }

    header.active /deep/ {
        nav {
            background: #f2f2f2e7;
        }
    }
}

@include md {
    header /deep/ {
        .header-md-bg {
            // background: #ffffffbb;
            background: unset;
            // width: 166px; height: 48px;
            transition: 0.2s;
        }
        .hamburger_btn {
            // background: #00000060;
            transition: 0.2s;
        }
        .logo {
            margin-top: 10px;
            margin-left: 10px;
            width: 100px;
            transition: 0.2s;
        }
    }
    header.active /deep/ {
        .header-md-bg {
            background: #f2f2f2f2;
            width: 100vw; height: 64px;
            transition: all 0.2s, width 0s 0.2s;
        }
        .hamburger_btn {
            background: $color-green;
            transition: all 0.2s, background 0s 0.2s, transform 0.2s;
        }
        .logo {
            margin-top: 0px;
            margin-left: 0px;
            width: 100px;
            transition: 0s 0.2s;
        }
    }
}

</style>
