<template>
    <div class="spec-table">
        <div class="table-area">
            <table>
                <tr>
                    <th>本体の長さ</th>
                    <th>チップ数/m</th>
                    <th>電圧</th>
                    <th>電流値/m</th>
                    <th>消費電力/m</th>
                    <th>コントロール</th>
                    <th>保護等級</th>
                </tr>
                <tr>
                    <td>500mm</td>
                    <td>32pcs</td>
                    <td>24V</td>
                    <td>0.25A</td>
                    <td>6W</td>
                    <td rowspan="4">DMX</td>
                    <td rowspan="4">IP65</td>
                </tr>
                <tr>
                    <td>1000mm</td>
                    <td>64pcs</td>
                    <td>24V</td>
                    <td>0.5A</td>
                    <td>12W</td>
                </tr>
                <tr>
                    <td>1500mm</td>
                    <td>96pcs</td>
                    <td>24V</td>
                    <td>0.75A</td>
                    <td>18W</td>
                </tr>
                <tr>
                    <td>2000mm</td>
                    <td>128pcs</td>
                    <td>24V</td>
                    <td>1.00A</td>
                    <td>24W</td>
                </tr>
            </table>
        </div>

    </div>
</template>

<script>
import products from '../assets/json/products.json'
export default {
    data: () => {
        return {
            products: products
        }
    },
    props: {
        id: {
            type: Number
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.table-area {
        th {
            &:nth-of-type(1) {
                width: 24%;
            }
            &:nth-of-type(2) {
                width: 24%;
            }
            &:nth-of-type(3) {
                width: 12%;
            }
            &:nth-of-type(4) {
                width: 8%;
            }
            &:nth-of-type(5) {
                width: 10%;
            }
            &:nth-of-type(6) {
                width: 12%;
            }
            &:nth-of-type(7) {
                width: 10%;
            }
        }
}

</style>
