<template>
  <!-- 1 LEDテープライト（白色・電球色タイプ）-->
  <div>
    <div class="table-area">
      <h3>5050タイプ 60チップ版 【12V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN5050WW25</td>
          <td rowspan="6">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">
            <a
              href="../specifications/YN5050XXZZ.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">-</td>
          <td rowspan="6">
            <a href="../cad/YN5050XXZZ.dxf" download="YN5050XXZZ.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">-</td>
        </tr>
        <tr>
          <td>YN5050WW28</td>
        </tr>
        <tr>
          <td>YN5050WW30</td>
        </tr>
        <tr>
          <td>YN5050NW40</td>
        </tr>
        <tr>
          <td>YN5050CW60</td>
        </tr>
        <tr>
          <td>YN5050CW75</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>5050タイプ 60チップ版 【24V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN5050CW-60-24</td>
          <td rowspan="2">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">
            <a
              href="../specifications/YN5050XX-60-24.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">-</td>
          <td rowspan="2">
            <a href="../cad/YN5050XX-60-24.dxf" download="YN5050XX-60-24.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">-</td>
        </tr>
        <tr>
          <td>YN5050WW-28-24</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>3528タイプ 60チップ版 【12V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN3528WW25</td>
          <td rowspan="6">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">
            <a
              href="../specifications/YN3528XXZZ.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">-</td>
          <td rowspan="6">
            <a href="../cad/YN3528XXZZ.dxf" download="YN3528XXZZ.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">-</td>
        </tr>
        <tr>
          <td>YN3528WW28</td>
        </tr>
        <tr>
          <td>YN3528WW30</td>
        </tr>
        <tr>
          <td>YN3528NW40</td>
        </tr>
        <tr>
          <td>YN3528CW60</td>
        </tr>
        <tr>
          <td>YN3528CW75</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>3528タイプ 60チップ版 【24V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN3528WW-60-24</td>
          <td rowspan="2">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">
            <a
              href="../specifications/YN3528XX-60-24.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">-</td>
          <td rowspan="2">
            <a href="../cad/YN3528XX-60-24.dxf" download="YN3528XX-60-24.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">-</td>
        </tr>
        <tr>
          <td>YN3528CW-60-24</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>3528タイプ 120チップ版 【12V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN3528WW25120</td>
          <td rowspan="6">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">
            <a
              href="../specifications/YN3528XXZZ120.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">-</td>
          <td rowspan="6">
            <a href="../cad/YN3528XXZZ120.dxf" download="YN3528XXZZ120.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="6">-</td>
        </tr>
        <tr>
          <td>YN3528WW28120</td>
        </tr>
        <tr>
          <td>YN3528WW30120</td>
        </tr>
        <tr>
          <td>YN3528NW40120</td>
        </tr>
        <tr>
          <td>YN3528CW60120</td>
        </tr>
        <tr>
          <td>YN3528CW75120</td>
        </tr>
      </table>
    </div>

    <div class="table-area">
      <h3>3528タイプ 120チップ版 【24V仕様】</h3>
      <table>
        <tr>
          <th>品番</th>
          <th>カタログ</th>
          <th>仕様図</th>
          <th>取扱説明書</th>
          <th>姿図</th>
          <th>結線図</th>
        </tr>
        <tr>
          <td>YN3528WW-120-24</td>
          <td rowspan="2">
            <a
              href="../catalog/FLEXIAtapelight_catalog.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">
            <a
              href="../specifications/YN3528XX120-24.pdf"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../assets/img/mk-08.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">-</td>
          <td rowspan="2">
            <a href="../cad/YN3528XX-120-24.dxf" download="YN3528XX-120-24.dxf">
              <img
                src="../assets/img/mk-09.svg"
                alt="download-mark"
                class="dl-mark"
              />
            </a>
          </td>
          <td rowspan="2">-</td>
        </tr>
        <tr>
          <td>YN3528CW-120-24</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import products from "../assets/json/products.json";

export default {
  data: () => {
    return {
      products: products,
    };
  },
};
</script>
