<template>
  <div>
    <page-head></page-head>

    <main ref="main">
      <section class="filter-area">
        <div class="select-all-area">
          <h3 class="title">絞り込む</h3>
          <div class="flex-area">
            <!-- <p @click="filterAllTrue" class="button">Select All</p> -->
          </div>
        </div>

        <div class="select-tag-area tag">
          <div class="" :class="{ allselected: allSelected }">
            <div class="flex-area clear-button-area">
              <h4>キーワード</h4>
              <p
                @click="filterAllTrue"
                class="button"
                :class="{ allselected: allSelected }"
              >
                CLEAR
              </p>
            </div>
            <div class="keyword-list">
              <!-- <div> -->
              <div
                @click="changeFilter(0)"
                class="filter-list"
                :class="{ active: filter[0] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[0] }}
                </p>
              </div>
              <div
                @click="changeFilter(1)"
                class="filter-list"
                :class="{ active: filter[1] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[1] }}
                </p>
              </div>
              <div
                @click="changeFilter(2)"
                class="filter-list"
                :class="{ active: filter[2] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[2] }}
                </p>
              </div>
              <div
                @click="changeFilter(3)"
                class="filter-list"
                :class="{ active: filter[3] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[3] }}
                </p>
              </div>
              <!-- </div>
              <div> -->
              <div
                @click="changeFilter(4)"
                class="filter-list"
                :class="{ active: filter[4] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[4] }}
                </p>
              </div>
              <div
                @click="changeFilter(5)"
                class="filter-list"
                :class="{ active: filter[5] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[5] }}
                </p>
              </div>
              <div
                @click="changeFilter(6)"
                class="filter-list"
                :class="{ active: filter[6] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[6] }}
                </p>
              </div>
              <div
                @click="changeFilter(10)"
                class="filter-list"
                :class="{ active: filter[10] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-10.svg" alt="" />
                </div>
                <p>
                  {{ tags[10] }}
                </p>
              </div>
            </div>
            <!-- </div> -->
            <!-- <p @click="changeFilter(1)" :class="{ active: filter[1] }">
              {{ tags[1] }}
            </p>
            <p @click="changeFilter(2)" :class="{ active: filter[2] }">
              {{ tags[2] }}
            </p>
            <p @click="changeFilter(3)" :class="{ active: filter[3] }">
              {{ tags[3] }}
            </p>
            <p @click="changeFilter(4)" :class="{ active: filter[4] }">
              {{ tags[4] }}
            </p>
            <p @click="changeFilter(5)" :class="{ active: filter[5] }">
              {{ tags[5] }}
            </p>
            <p @click="changeFilter(10)" :class="{ active: filter[10] }">
              {{ tags[10] }}
            </p> -->
          </div>

          <div class="ml">
            <h4>使用環境</h4>
            <div class="flex-area">
              <div
                @click="changeFilter(11)"
                class="filter-list"
                :class="{ active: filter[11] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-11.svg" alt="" />
                </div>
                <p>
                  {{ tags[11] }}
                </p>
              </div>
              <div
                @click="changeFilter(12)"
                class="filter-list"
                :class="{ active: filter[12] }"
              >
                <div class="check-box">
                  <img src="../assets/img/mk-11.svg" alt="" />
                </div>
                <p>
                  {{ tags[12] }}
                </p>
              </div>
            </div>
            <!-- <p @click="changeFilter(11)" :class="{ active: filter[11] }">
              {{ tags[11] }}
            </p>
            <p @click="changeFilter(12)" :class="{ active: filter[12] }">
              {{ tags[12] }}
            </p> -->
          </div>
        </div>
      </section>

      <article>
        <section>
          <!-- <div class="works-content-area"> -->
          <!-- <router-link :to="{ name: 'works-detail', params: { id: 1}}">納入事例詳細ページ</router-link> -->
          <transition-group class="works-content-area" tag="div" name="fade">
            <div
              v-for="workId in displayIndex"
              :key="workId"
              class="content-wrap"
            >
              <!-- <p>test {{ works[Number(workId)].id }}</p> -->
              <!-- <transition name="fade" appear mode="out-in"> -->
              <!-- <router-link
                                :to="{ name: 'works-detail', params: { id: Number(work.id) }}"
                                class="content"
                                v-if="work.id != '00' && showContent(work.tags)"
                                :id="work.id"
                            > -->
              <router-link
                :to="{
                  name: 'works-detail',
                  params: { id: Number(works[Number(workId)].id) },
                }"
                class="content"
                v-if="workId != '0'"
                :id="works[Number(workId)].id"
              >
                <div class="img-wrap">
                  <div
                    class="img"
                    :style="{
                      'background-image':
                        'url(' +
                        require('@/assets/img/wpt-' +
                          works[Number(workId)].pid +
                          '.jpg') +
                        ')',
                    }"
                  ></div>
                </div>

                <div class="text-area">
                  <div class="title-area">
                    <h4 class="title">{{ works[Number(workId)].name }}</h4>
                  </div>
                  <div class="gray-area" />
                  <div class="tag">
                    <div
                      v-for="tag in works[Number(workId)].tags"
                      :key="tag.id"
                      class="tag-wrap"
                    >
                      <p>{{ tags[tag] }}</p>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- </transition> -->
            </div>
          </transition-group>
          <!-- </div> -->
        </section>
      </article>

      <section class="page-select-area">
        <!-- <p class="active">1</p>
                <p>2</p>
                <p>3</p> -->
      </section>
    </main>
  </div>
</template>

<script>
import PageHead from "../components/PageHead";
import works from "../assets/json/works.json";
import tags from "../assets/json/tagName.json";
// import { filter } from 'vue/types/umd';

export default {
  data: () => {
    return {
      works: works,
      tags: tags,
      filter: {
        "0": true,
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
        "10": true,
        "11": true,
        "12": true,
      },
      displayIndex: [],
      allSelected: true,
    };
  },
  methods: {
    changeFilter: function(tag_id) {
      // this.filter[tag_id] = !this.filter[tag_id];
      if (tag_id <= 10) {
        for (var i = 0; i <= 10; i++) {
          this.filter[i] = false;
        }
        this.filter[tag_id] = true;
        this.allSelected = false;
      } else {
        this.filter[tag_id] = !this.filter[tag_id];
      }
      this.changeFilterAll();
    },
    filterAllTrue: function() {
      for (var i = 0; i <= 12; i++) {
        this.filter[i] = true;
      }
      this.allSelected = true;
      this.changeFilterAll();
    },
    filterAllFalse: function() {
      for (var i = 0; i <= 10; i++) {
        this.filter[i] = false;
      }
      //console.log(1);
      this.changeFilterAll();
    },
    changeFilterAll: function() {
      // //console.log("test");
      this.displayIndex = [];
      for (var i = this.works.length - 1; i >= 1; i -= 1) {
        if (this.showContent(this.works[i].tags)) {
          this.displayIndex.push(i);
        }
      }
    },
  },
  components: {
    PageHead,
  },
  computed: {
    showContent: function() {
      return function(included_tags) {
        var tf = false;
        var tf2 = false;

        included_tags.forEach((value) => {
          if (Number(value) < 11) {
            if (this.filter[value]) {
              tf = true;
            }
          } else {
            if (this.filter[value]) {
              tf2 = true;
            }
          }
        });

        if (tf && tf2) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  watch: {
    // filter: {
    //   handler: function() {
    //     sessionStorage.setItem("filter", JSON.stringify(this.filter));
    //   },
    //   deep: true,
    //   // immediate: true
    // },
  },
  created() {
    this.filterAllTrue();
    // this.filter = JSON.parse(sessionStorage.getItem("filter")) || this.filter;
    // this.displayIndex = [];
    // for (var i = 1; i <= this.works.length; i++) {
    //   if (this.showContent(this.works[i].tags)) {
    //     this.displayIndex.push(i);
    //   }
    // }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/global.scss";

.filter-area {
  position: relative;
  margin: 5.6vw $lg-mar-lr 2.4vw;
  padding-bottom: 2.4vw;
  border-bottom: 3px solid #ccc;
  @include md {
    background: #f2f2f2;
    padding: 7.2vw 4vw 1.6vw;
    margin: 0;
  }
  .select-all-area,
  .select-tag-area {
    .clear-button-area {
      @include lg {
        // justify-content: space-between;
        .button {
          // margin-right: 4vw;
        }
      }
      @include md {
        position: absolute;
        top: 7.6vw;
        right: 8vw;
        .button {
          margin: 0;
        }
      }
    }
    .flex-area {
      display: flex;
    }
    // @include lg {
    display: flex;
    // }
    font-size: calc(7px + 0.7vw);
  }
  .select-all-area {
    @include md {
      padding-bottom: 1.6vw;
      border-bottom: 1px solid rgb(116, 116, 116);
      .flex-area {
        position: absolute;
        right: 5.6vw;
        // margin-left: 9.6vw;
      }
    }
    p {
      // padding: 0.8vw 0.8vw;
      // line-height: 2.4vw;
      font-weight: 500;
      // user-select: none;
      // font-size: calc(7.2px + 0.72vw);
      // @include md {
      //   font-size: calc(9.4px + 0.94vw);
      //   margin-right: 4vw;
      // }
    }
    .title {
      margin: 0;
      margin-right: 0.8vw;
      margin-bottom: 0.24em;
      // padding-top: 0.78vw;
      padding-left: 1.2vw;
      font-weight: 500;
      font-size: calc(8.4px + 0.84vw);
      letter-spacing: 0.046em;
      line-height: 2.4vw;
      border-left: 3px $color-green solid;
      @include md {
        font-size: 18px;
        margin-bottom: 1.6vw;
        margin-left: 1.2vw;
        padding-left: 4vw;
        line-height: unset;
      }
    }
  }
  .select-tag-area {
    margin-left: 1.2vw;
    @include md {
      margin-left: 2.4vw;
    }
    h4 {
      margin: 1.4em 0;
      line-height: 1.4em;
      font-weight: 500;
    }
    .button {
      width: unset;
      margin: 1.4em 1.2em;
      line-height: 1.4em;
      padding: 0.2em 0.8em;
      // color: $color-gray-2;
      font-weight: normal;
      color: white;
      background: #888;
      cursor: pointer;
      letter-spacing: 0;
      transition: 0.2s;
      &.allselected {
        opacity: 0;
        cursor: unset;
        &:hover {
          opacity: 0;
        }
      }
      &:hover {
        // color: $color-green;
        filter: brightness(1.1);
        transition: 0.2s;
      }
    }
    .keyword-list {
      display: flex;
      flex-wrap: wrap;
      @include lg {
        width: 48vw;
      }
    }
    margin-top: 0.8vw;
    display: flex;
    .ml {
      @include lg {
        // border-top: 2px #ccc solid;
        // border-bottom: 2px #ccc solid;
        // padding: 2vw 0;
        // margin-top: 2vw;
        margin-left: 2vw;
      }
      @include md {
        display: none;
        margin-top: 1.6vw;
      }
      .filter-list .check-box {
        border-radius: 2px;
      }
    }
    @include md {
      margin-top: 2.4vw;
    }
  }
  .tag {
    .filter-list {
      display: flex;
      cursor: pointer;
      margin-bottom: 0.24vw;
      // height: 1.6em;
      .check-box {
        position: relative;
        width: 1em;
        height: 1em;
        border: #aaa 3px solid;
        box-sizing: border-box;
        outline-offset: -3px;
        border-radius: 50%;
        margin-top: 0.68em;
        transition: 0.2s;
        // @include translate(0, -50%);
        @include md {
          width: 2em;
          height: 2em;
        }
        img {
          position: absolute;
          top: -3px;
          left: -3px;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          opacity: 0;
          transition: 0.2s;
        }
      }
      &.active {
        .check-box {
          img {
            opacity: 1;
          }
        }
      }
      &:hover {
        &.active {
          .check-box {
            border: #fff 3px solid;
            img {
              opacity: 0.7;
            }
          }
        }
        .check-box {
          border: #ccc 3px solid;
          // background: #{$color-green}66;
          img {
            opacity: 0;
          }
        }
        p {
          opacity: 0.6;
        }
      }
    }
    @include md {
      margin-top: 4vw;
      .flex-area {
        flex-wrap: wrap;
        h4 {
          display: none;
        }
        &:first-of-type {
          padding-bottom: 1.6vw;
          // border-bottom: 1px solid rgb(116, 116, 116);
        }
        &:nth-of-type(2) {
          padding: 2.4vw 0 2vw;
          // border-bottom: 1px solid rgb(116, 116, 116);
        }
      }
    }
    p {
      margin-right: 0.48vw;
      // padding: 0.8vw 0;
      // background: #{$color-green}e3;
      padding: 0.8em 2em 0.8em 0.6em;
      // background: #00000036;
      // border: 1px white solid;
      border: none;
      // color: white;
      color: black;
      font-weight: 400;
      opacity: 0.86;
      user-select: none;
      // cursor: pointer;
      text-align: left;
      transition: 0.2s;
      width: unset;
      @include lg {
        font-size: calc(6.4px + 0.64vw);
        // width: calc(40px + 4vw);
      }
      @include md {
        font-size: calc(10px + 1vw);
        padding: 2.8vw 0;
        margin-left: 0.4em;
        margin-right: 8vw;
        margin-bottom: 2.4vw;
      }
      &.active {
        background: #{$color-green}e3;
        border: 1px #{$color-green}e3 solid;
        filter: brightness(1.15);
        color: white;
        font-weight: 300;
        @include lg {
          &:hover {
            background: #{$color-green}e3;
            filter: brightness(1.28);
          }
        }
      }
      @include lg {
        &:hover {
          // background: #00000042;
          // transition: 0.2s;
        }
      }
    }
    .allselected {
      .filter-list {
        opacity: 0.5;
        transition: 0.2s;
        .check-box {
          img {
            opacity: 0;
          }
        }
        &:hover {
          .check-box {
            border: solid 3px #ccc;
            img {
              opacity: 0;
            }
          }
        }
      }
      &:hover {
        .filter-list {
          opacity: 1;
        }
      }
    }
  }
}

.works-content-area {
  @include lg {
    margin-left: $lg-mar-lr;
  }
  @include md {
    margin-top: 6.4vw;
    margin-left: 4vw;
  }

  .fade-move {
    transition: transform 0.6s;
  }

  .fade-enter {
    opacity: 0;
    transform: translateY(0.8vw);
  }
  .fade-enter-active {
    transition: opacity 0.6s, transform 0.6s;
  }
  .fade-enter-to {
    opacity: 1;
  }

  .fade-leave {
    opacity: 1;
  }
  .fade-leave-ative {
    transition: opacity 0.6s, transform 0.6s;
    position: absolute;
  }
  .fade-leave-to {
    opacity: 0;
    transform: translateY(0.8vw);
  }
}

.page-select-area {
  position: relative;
  display: inline-block;
  margin: 8vw 0 4vw 40vw;
  @include translate(-50%, 0);
  @include md {
    margin: 4vw 0;
  }
  p {
    display: inline-block;
    margin: 0 0.8vw;
    width: 2.4vw;
    height: 2.4vw;
    text-align: center;
    line-height: 2.4vw;
    border: black 1px solid;
    user-select: none;
    cursor: pointer;
    &.active {
      background: $color-green;
      opacity: 0.86;
      border: $color-green 1px solid;
      color: white;
      cursor: auto;
    }
    &:hover {
      background: #00000004;
      // opacity: 0.86;
      // border: #00000099 1px solid;
      // color: white;
      &.active {
        background: $color-green;
        opacity: 0.86;
        border: $color-green 1px solid;
        color: white;
      }
    }
  }
}
</style>
